import * as yup from 'yup';

const schema = yup.object().shape({
  id: yup.string(),
  empresa: yup
    .object()
    .shape({
      razaoSocial: yup.string(),
      id: yup.string().required('O campo Empresa é obrigatório.'),
    })
    .required(),
  empresaCedente: yup.object().shape({
    razaoSocial: yup.string(),
    id: yup.string(),
  }),
});

export default schema;
