import styled from 'styled-components';

export const Wizard = styled.div`
  margin-top: var(--gap-medium);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .step-wizard {
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    .buttons {
      padding: var(--gap-medium) 0;
      display: flex;
      align-items: center;

      .buttons-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      button {
        margin: 0 var(--gap-smaller);
      }
    }
  }

  /* input */
  div[data-tag='___react-data-table-allow-propagation___'] > div {
    margin: 0;
  }
`;
