import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@components/Button';
import { Icons } from '@components/Dictionaries/Icons';
import Select from '@components/Select';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { ConciliationStatusEnum } from '@enums/conciliationStatus.enum';
import { SendersEnum } from '@enums/senders.enum';
import { masks } from '@helpers/mask';
import { IConciliation } from '@models/domain/IConciliation';
import { IResponse } from '@models/util/IResponse';

import ConciliationTableButtons from './ConciliationTableButtons';

interface Props {
  conciliations: IResponse<IConciliation[]>;
  rowsAmount: number;
  rowsPerPage: number;
  submitting: boolean;
  hasSubheader: boolean;
  isExport: boolean;
  filter: string;
  selectedExports: string[];
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
  handleFilter(value: string | number): void;
  setSelectedExports(conciliation: string[]): void;
  handleOpenDetail?(conciliation: IConciliation): void;
  handleOpenHistory?(conciliation: IConciliation): void;
  handleDeleteConciliation?(id: string): void;
  handleClickExport(tipoArquivo: string): void;
}

const ConciliationsList: React.FC<Props> = ({
  conciliations,
  setTablePage,
  setRowsPerPage,
  rowsAmount,
  hasSubheader,
  rowsPerPage,
  submitting,
  handleOpenDetail,
  handleDeleteConciliation,
  handleFilter,
  filter,
  isExport,
  setSelectedExports,
  selectedExports,
  handleClickExport,
  handleOpenHistory,
}) => {
  const history = useHistory();

  const handleSelected = useCallback(
    ({ selectedRows }) => {
      setSelectedExports(selectedRows.map((item) => item.id));
    },
    [setSelectedExports]
  );

  return (
    <Table
      setTablePage={setTablePage}
      setRowsPerPage={setRowsPerPage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      hasSubheader={hasSubheader}
      isLoading={conciliations === undefined}
      selectableRows={isExport}
      onSelectedRowsChange={handleSelected}
      clearSelectedRows={!isExport}
      subHeaderComponent={
        isExport ? (
          <>
            <Button
              flex
              disabled={
                selectedExports === undefined || selectedExports.length <= 0
              }
              onClick={() => handleClickExport('json')}
            >
              <Icons.Download />
              JSON
            </Button>
            <Button
              flex
              disabled={
                selectedExports === undefined || selectedExports.length <= 0
              }
              onClick={() => handleClickExport('excel')}
            >
              <Icons.Download />
              CSV
            </Button>
          </>
        ) : (
          <Select
            name="filter"
            value={filter}
            onChange={(e) => handleFilter(e)}
            list={[
              { id: 'todos', text: 'Todos' },
              { id: 'andamento', text: 'Em andamento' },
            ]}
            placeholder="Filtrar Status"
            onBlur={() => {}}
          />
        )
      }
      columns={[
        {
          name: 'Ação',
          selector: 'acao',
          omit:
            !(
              conciliations?.acoes?.excluir ||
              conciliations?.acoes?.alterar ||
              conciliations?.acoes?.consultar
            ) || isExport,
          width: '130px',
        },
        {
          name: 'Data',
          selector: 'data',
          minWidth: '50px',
          cell: (row) => <SpanTable id={row.id} text={row.data} />,
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Liquidação',
          selector: 'liquidacao',
          cell: (row) => (
            <SpanTable id={row.id} text={SendersEnum[row.liquidacao]} />
          ),
        },
        {
          name: 'Pagador',
          selector: 'pagador',
          cell: (row) => <SpanTable id={row.id} text={row.pagador} />,
        },
        {
          name: 'Valor',
          selector: 'valor',
          right: true,
          cell: (row) => <SpanTable id={row.id} text={row.valor} />,
        },
        {
          name: 'Títulos',
          selector: 'titulos',
          right: true,
          cell: (row) => <SpanTable id={row.id} text={row.titulos} />,
        },
        {
          name: 'Devolução',
          selector: 'devolucao',
          right: true,
          cell: (row) => <SpanTable id={row.id} text={row.devolucao} />,
        },
        {
          name: 'Status',
          selector: 'status',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={ConciliationStatusEnum[row.status]} />
          ),
        },
      ]}
      content={conciliations?.data?.map((conciliation) => {
        return {
          id: conciliation.id,
          data: new Date(conciliation.data).toLocaleDateString(),
          empresa: conciliation.empresa.razaoSocial,
          liquidacao: conciliation.liquidacao?.tipo || '-',
          pagador: conciliation.liquidacao.pagadorRazaoSocialLiquidacao || '-',
          valor: masks.currency.string(conciliation.valor),
          titulos: masks.currency.string(conciliation.somaTitulos),
          devolucao: masks.currency.string(conciliation.devolucao.valor),
          status: conciliation.status,
          acao: (
            <ConciliationTableButtons
              id={conciliation.id}
              acoes={conciliations.acoes}
              tableName="Conciliação"
              confirmMessage="Tem certeza que deseja deletar a Conciliação?"
              submitting={submitting}
              handleDelete={() => handleDeleteConciliation(conciliation.id)}
              handleEdit={() =>
                history.push(`/conciliacoes/editar/${conciliation.id}`)
              }
              handleShow={() => handleOpenDetail(conciliation)}
              handleHistory={() => handleOpenHistory(conciliation)}
              status={conciliation.status}
            />
          ),
        };
      })}
    />
  );
};

export default ConciliationsList;
