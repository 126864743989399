import styled from 'styled-components';

export const Container = styled.div`
  .tables {
    margin: 30px 0;

    h2 {
      font-weight: bold;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    & > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;
