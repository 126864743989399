import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuth } from '@hooks/useAuth';
import { ICheckPermission } from '@models/util/ICheckPermission';

interface Props extends RouteProps {
  permission: ICheckPermission;
}

const PermissionRoute: React.FC<Props> = ({ permission, ...rest }) => {
  const { hasPermission } = useAuth();

  if (hasPermission(permission)) return <Route {...rest} />;

  return <Redirect to={process.env.REACT_APP_BASE_URL} />;
};

export default PermissionRoute;
