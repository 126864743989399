import React, { useContext } from 'react';
import { BarLoader } from 'react-spinners';

import { ThemeContext } from 'styled-components';

import { Global } from './styles';

const GlobalLoading: React.FC = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <Global>
      <BarLoader
        width={window.innerWidth}
        color={themeContext.colors.primary}
        loading
      />
    </Global>
  );
};

export default GlobalLoading;
