import styled from 'styled-components';

type Props = {
  retract?: boolean;
};

export const Logo = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.primary};
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 21px;
  padding: 5px 0;
  margin: 0;

  & > svg {
    transform: ${(props) => (props.retract ? 'rotate(0)' : 'rotate(180deg)')};
    transition: transform 0.3s;
  }
`;
