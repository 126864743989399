import { AxiosInstance, CancelToken } from 'axios';

import { sortByPosition } from '@helpers/sortByPosition';
import { IDomainType } from '@models/domain/IDomainType';
import { IProfileGroup } from '@models/domain/IProfile';

import { IDomainsService } from './interfaces/IDomainsService';

const ENDPOINT = '/api/v1/dominios';

export class DomainsService implements IDomainsService {
  constructor(private api: AxiosInstance) {}

  async transactionType(cancelToken?: CancelToken) {
    const { data } = await this.api.get<IDomainType[]>(
      `${ENDPOINT}/tipo-transacao`,
      {
        cancelToken,
      }
    );

    return data;
  }

  private getSenderId(sender: IDomainType) {
    return Number(sender.id);
  }

  async senderType(cancelToken?: CancelToken) {
    const { data } = await this.api.get<IDomainType[]>(
      `${ENDPOINT}/tipo-remetente`,
      {
        cancelToken,
      }
    );
    const sendersOrdered = sortByPosition<IDomainType>(data, this.getSenderId);

    return sendersOrdered;
  }

  async permissions(cancelToken?: CancelToken) {
    const { data } = await this.api.get<IProfileGroup[]>(
      `${ENDPOINT}/permissoes`,
      {
        cancelToken,
      }
    );

    return data;
  }
}
