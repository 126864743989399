import styled from 'styled-components';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const Container = styled.div`
  .confirm-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    h1 {
      font-size: var(--fsz-big);
      font-weight: 500;
    }

    p {
      margin: 15px 0;
    }

    .buttons {
      align-self: flex-end;
      display: flex;
    }

    .confirm {
      background-color: #eb5757;
    }

    button {
      border: 0;
      outline: 0;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: bold;
      padding: 0 16px;
      transition: all 0.3s;
      height: 34px;
      font-size: 12px;
      margin: 0 5px;

      color: white;
      background: #2495da;

      &:disabled {
        background: #c4c4c4;
      }

      &:hover:not(:disabled) {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }
  }
`;
