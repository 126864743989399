import LogoKoperarRetract from '../../assets/imgs/logo-koperar-retract.png';
import LogoKoperarFull from '../../assets/imgs/logo-koperar.png';
import LogoRgb from '../../assets/imgs/logo-rgb.png';
import LogoDefault from '../../assets/imgs/logo.png';

export function handleLogo() {
  const logo = process.env.REACT_APP_CURRENT_LOGO;
  let full = LogoDefault;
  let retract = LogoDefault;

  if (logo === 'koperar') {
    full = LogoKoperarFull;
    retract = LogoKoperarRetract;
  }

  if (logo === 'rgb') {
    full = LogoRgb;
    retract = LogoRgb;
  }

  return {
    full,
    retract,
  };
}
