import React, { useCallback, useEffect, useState } from 'react';

import Table from '@components/Table';

import { ConciliationStatusEnum } from '@enums/conciliationStatus.enum';
import { SendersEnum } from '@enums/senders.enum';
import { TransactionsEnum } from '@enums/transactions.enum';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IConciliation } from '@models/domain/IConciliation';
import { IVI } from '@models/domain/IVI';
import { conciliationService } from '@services/index';

import Detail from './Detail';
import { Container, List } from './styles';

interface Props {
  vi: IVI;
}

const VIDetails: React.FC<Props> = ({ vi }) => {
  const [conciliations, setConciliations] = useState<IConciliation[]>();
  const [tablePage, setTablePage] = useState(1);
  const [tableRowsAmount, setTableRowsAmount] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);

  const listConciliations = useCallback(async () => {
    try {
      const { count, rows } = await conciliationService.listConciliationsVi(
        vi.id,
        tablePage,
        tableRowsPerPage
      );

      setConciliations(rows.data);
      setTableRowsAmount(count);
    } catch (error) {
      setConciliations([]);
      handleRequestError(error, 'Erro ao carregar conciliações.');
    }
  }, [tablePage, tableRowsPerPage, vi.id]);

  useEffect(() => {
    listConciliations();
  }, [listConciliations]);

  return (
    <Container>
      <List>
        <Detail
          label="Conta bancária"
          value={masks.bankAccount(vi.contaBancaria)}
        />
        <Detail label="Complemento" value={vi.complemento} />
        <Detail label="ESCROW" checkbox value={vi.contaEscrow} />
        <Detail
          label="Tipo Transação"
          value={TransactionsEnum[vi.tipoTransacao]}
        />
        <Detail
          label="Valor"
          currency
          value={masks.currency.format(vi.valor)}
        />
        <Detail
          label="Tipo do Remetente"
          value={SendersEnum[vi.remetente.tipo]}
        />
        <Detail label="Nome do Remetente" value={vi.remetente.razaoSocial} />
        <Detail
          label="Documento do Remetente"
          value={masks.handleDocument(vi.remetente.cnpj)}
        />
        <Detail label="Nome da Empresa" value={vi.empresa.razaoSocial} />
        <Detail label="CNPJ da Empresa" value={masks.cnpj(vi.empresa.cnpj)} />
        <Detail
          label="Empresa Cedente"
          value={vi.empresaCedente?.razaoSocial ?? '-'}
        />
        <Detail
          label="CNPJ Empresa Cedente"
          value={masks.cnpj(vi.empresaCedente?.cnpj ?? '-')}
        />
        <Detail label="Data" value={new Date(vi.data).toLocaleDateString()} />
        <Detail
          label="Conciliado"
          currency
          value={(vi?.valorConciliado ?? 0).toFixed(2).replace('.', ',')}
        />
        <Detail
          label="Saldo a Conciliar"
          currency
          value={(vi?.saldo ?? 0).toFixed(2).replace('.', ',')}
        />
      </List>
      <div>
        <h2>Lista de Conciliações</h2>
        <Table
          setTablePage={setTablePage}
          setRowsPerPage={setTableRowsPerPage}
          rowsAmount={tableRowsAmount}
          rowsPerPage={tableRowsPerPage}
          isLoading={conciliations === undefined}
          columns={[
            {
              name: 'Data',
              selector: 'data',

              minWidth: '85px',
            },
            {
              name: 'Empresa',
              selector: 'empresa',

              wrap: true,
            },
            {
              name: 'Liquidação',
              selector: 'liquidacao',

              minWidth: '75px',
              cell: (row: IConciliation) => SendersEnum[row.status],
            },
            {
              name: 'Pagador',
              selector: 'pagador',

              wrap: true,
              minWidth: '50px',
            },
            {
              name: 'Valor',
              selector: 'valor',
              right: true,
            },
            {
              name: 'Títulos',
              selector: 'titulos',
              right: true,
            },
            {
              name: 'Devolução',
              selector: 'devolucao',
              right: true,
            },
            {
              name: 'Status',
              selector: 'status',
              right: true,
              cell: (row: IConciliation) => ConciliationStatusEnum[row.status],
            },
          ]}
          content={conciliations?.map((conciliation) => {
            return {
              data: new Date(conciliation.data).toLocaleDateString(),
              empresa: conciliation.empresa.razaoSocial,
              liquidacao: conciliation.liquidacao.tipo || '-',
              pagador:
                conciliation.liquidacao.pagadorRazaoSocialLiquidacao || '-',
              valor: masks.currency.string(conciliation.valor),
              titulos: masks.currency.string(conciliation.somaTitulos),
              devolucao: masks.currency.string(conciliation.devolucao.valor),
              status: conciliation.status,
            };
          })}
        />
      </div>
    </Container>
  );
};

export default VIDetails;
