import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Modal from '@components/Modal';
import Textarea from '@components/Textarea';

import { handleRequestError } from '@helpers/handleRequestError';
import { useConciliations } from '@hooks/useConciliations';
import { IConciliation } from '@models/domain/IConciliation';
import { conciliationService } from '@services/index';

interface Props {
  conciliation: IConciliation;
  setOpenModal: (state: boolean) => void;
}

const ModalForm: React.FC<Props> = ({ conciliation, setOpenModal }) => {
  const history = useHistory();
  const { clearConciliationToSubmit } = useConciliations();

  async function submitConciliation(
    status: number,
    observacao: string,
    submitMessage: string,
    action: string
  ) {
    const submit = {
      ...conciliation,
      status,
      observacao,
    };

    try {
      await conciliationService.edit(clearConciliationToSubmit(submit));

      if (action === 'reprovar') {
        toast.info(submitMessage);
      } else {
        toast.success(submitMessage);
      }

      history.push('/conciliacoes');
    } catch (error) {
      handleRequestError(error, `Erro ao ${action} conciliação`);
    }
  }

  async function handleSubmit(data) {
    await submitConciliation(
      3,
      data?.observacao,
      'Conciliação aprovada com sucesso',
      'aprovar'
    );
  }

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      observacao: '',
    },
  });

  async function handleReproveClick() {
    await submitConciliation(
      4,
      formik.values?.observacao,
      'Conciliação reprovada com sucesso',
      'reprovar'
    );
  }

  async function handleReviewClick() {
    await submitConciliation(
      1,
      formik.values?.observacao,
      `Conciliação voltou ao status de "Em análise" para ser revisada`,
      'revisar'
    );
  }

  return (
    <Modal title="Ações para Aprovação" setOpenModal={setOpenModal}>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-wrapper">
          <Textarea
            label="Observações"
            name="observacao"
            value={formik.values.observacao}
            onChange={formik.handleChange}
          />
        </div>

        <div className="buttons">
          <Button color="warning" onClick={handleReproveClick}>
            Reprovar
          </Button>
          <Button onClick={handleReviewClick}>Revisar</Button>
          <Button type="submit">Aprovar</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalForm;
