import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 10px;

  & > div {
    margin: 8px 0;
  }
`;
