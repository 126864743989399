import React, { useCallback, useEffect, useState } from 'react';

import Button from '@components/Button';
import Card from '@components/Card';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { useAuth } from '@hooks/useAuth';
import { useUsers } from '@hooks/useUsers';
import { IUser } from '@models/domain/IUser';
import { IResponse } from '@models/util/IResponse';

import UserForm from './Form';
import UsersList from './List';

const Users = () => {
  const { permissions } = useAuth();
  const { listUsers, deleteUser } = useUsers();
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [users, setUsers] = useState<IResponse<IUser[]>>();
  const [usersLoading, setUsersLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchUsers = useCallback(
    async (cancelToken?) => {
      setUsersLoading(true);

      try {
        const response = await listUsers(page, rowsPerPage, cancelToken);

        setUsers(response?.rows);
        setRowsAmount(response?.count);
      } catch {
        setUsers((prevState) => ({ ...prevState, data: [] }));
        setRowsAmount(0);
      } finally {
        setUsersLoading(false);
      }
    },
    [listUsers, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    fetchUsers(source.token);

    return () => {
      source.cancel();
    };
  }, [fetchUsers]);

  function handleEditUser(user: IUser) {
    setOpenEdit(true);
    setSelectedUser(user);
  }

  async function handleDeleteUser(user: IUser) {
    await deleteUser(user);

    await fetchUsers();
  }

  return (
    <Card>
      <header>
        <h1>Lista de Usuários</h1>
        {permissions?.users?.createAndUpdate && (
          <Button onClick={() => setOpenNew(true)} disabled={usersLoading}>
            Novo
          </Button>
        )}
      </header>
      <UsersList
        users={users}
        usersLoading={usersLoading}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
        handleDeleteUser={handleDeleteUser}
        handleEditUser={handleEditUser}
      />
      {openNew && (
        <Modal setOpenModal={setOpenNew} title="Novo Usuário">
          <UserForm
            setOpenModal={() => setOpenNew(false)}
            refreshUsers={fetchUsers}
          />
        </Modal>
      )}
      {openEdit && (
        <Modal setOpenModal={setOpenEdit} title="Editar Usuário">
          <UserForm
            userSelected={selectedUser}
            setOpenModal={() => setOpenEdit(false)}
            refreshUsers={fetchUsers}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Users;
