import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { saveAs } from 'file-saver';

import Button from '@components/Button';
import Card from '@components/Card';
import { Icons } from '@components/Dictionaries/Icons';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { useAuth } from '@hooks/useAuth';
import {
  IConciliation,
  IConciliationFilter,
} from '@models/domain/IConciliation';
import { IResponse } from '@models/util/IResponse';
import { conciliationService } from '@services/index';

import ConciliationDetails from './Details';
import ConciliationFilter from './Filter';
import History from './History';
import ConciliationsList from './List';

interface Props {
  headerButtons?: boolean;
  tableRowsPerPageDefault?: number;
}

const Conciliations: React.FC<Props> = ({
  headerButtons = true,
  tableRowsPerPageDefault = 10,
}) => {
  const { permissions } = useAuth();
  const [conciliations, setConciliations] = useState<
    IResponse<IConciliation[]>
  >();
  const [tablePage, setTablePage] = useState(1);
  const [tableRowsAmount, setTableRowsAmount] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(
    tableRowsPerPageDefault
  );
  const [submitting, setSubmitting] = useState(false);
  const [
    selectedConciliation,
    setSelectedConciliation,
  ] = useState<IConciliation>();
  const [filter, setFilter] = useState<string>('andamento');
  const [filters, setFilters] = useState<IConciliationFilter>();
  const [openFilter, setOpenFilter] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [selectedExports, setSelectedExports] = useState<string[]>();

  function handleFilter(value: string) {
    setFilter(value);
    setFilters(null);
  }

  async function handleClickExport(tipoArquivo: string) {
    try {
      const selectedConciliations = selectedExports.join('&ids=');
      const { data, filename } = await conciliationService.export(
        selectedConciliations,
        tipoArquivo
      );

      saveAs(new Blob([data], { type: 'application/octet-stream' }), filename);
    } catch (error) {
      handleRequestError(error, 'Erro na tentativa de exportação');
    }
  }

  const listConciliations = useCallback(
    async (cancelToken?) => {
      try {
        const { rows, count } = await conciliationService.list(
          tablePage,
          tableRowsPerPage,
          filter === 'andamento',
          filters,
          cancelToken
        );

        setConciliations(rows);
        setTableRowsAmount(count);
      } catch (error) {
        setConciliations((prevState) => ({ ...prevState, data: [] }));
        handleRequestError(error, 'Erro ao carregar as conciliações.');
      }
    },
    [filter, filters, tablePage, tableRowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listConciliations(source.token);

    return () => {
      source.cancel();
    };
  }, [listConciliations]);

  function handleOpenDetail(conciliation: IConciliation) {
    setOpenDetail(true);
    setSelectedConciliation(conciliation);
  }

  function handleOpenHistory(conciliation: IConciliation) {
    setOpenHistory(true);
    setSelectedConciliation(conciliation);
  }

  async function handleDeleteConciliation(id: string) {
    try {
      await conciliationService.delete(id);

      setSubmitting(true);
      toast.info('Conciliação deletada com sucesso.');
      listConciliations();
    } catch (error) {
      handleRequestError(error, 'Erro ao deletar Conciliação.');
    } finally {
      setSubmitting(false);
    }
  }

  function handleExport() {
    if (isExport) {
      setIsExport(false);
      setSelectedExports([]);
    } else {
      setIsExport(true);
    }
  }

  return (
    <Card>
      <header>
        <h1>Conciliações</h1>
        {headerButtons && (
          <div className="menu">
            {conciliations?.acoes?.consultar && (
              <>
                <Button onClick={handleExport} flex>
                  {isExport ? 'Cancelar Exportação' : 'Exportar'}
                </Button>
                {!isExport && (
                  <Button onClick={() => setOpenFilter(true)} flex>
                    <Icons.Filter />
                    Filtrar
                  </Button>
                )}
              </>
            )}
            {!isExport && permissions?.conciliation?.create && (
              <Link to="/conciliacoes/criar">
                <Button>Nova Conciliação</Button>
              </Link>
            )}
          </div>
        )}
      </header>
      <ConciliationsList
        conciliations={conciliations}
        setTablePage={setTablePage}
        setRowsPerPage={setTableRowsPerPage}
        rowsAmount={tableRowsAmount}
        rowsPerPage={tableRowsPerPageDefault}
        submitting={submitting}
        handleOpenDetail={handleOpenDetail}
        handleDeleteConciliation={handleDeleteConciliation}
        filter={filter}
        handleFilter={handleFilter}
        hasSubheader={headerButtons}
        isExport={isExport}
        setSelectedExports={setSelectedExports}
        selectedExports={selectedExports}
        handleClickExport={handleClickExport}
        handleOpenHistory={handleOpenHistory}
      />
      {openDetail && (
        <Modal title="Detalhes da Conciliação" setOpenModal={setOpenDetail}>
          <ConciliationDetails conciliation={selectedConciliation?.id} />
        </Modal>
      )}
      {openFilter && (
        <Modal title="Filtrar Conciliações" setOpenModal={setOpenFilter}>
          <ConciliationFilter
            filters={filters}
            setOpenModal={setOpenFilter}
            setFilters={setFilters}
            setFilter={setFilter}
          />
        </Modal>
      )}
      {openHistory && (
        <History
          conciliation={selectedConciliation?.id}
          setOpenHistory={setOpenHistory}
        />
      )}
    </Card>
  );
};

export default Conciliations;
