import styled from 'styled-components';

export const Container = styled.div``;

export const PermissionDenied = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-top: 15px;
    align-self: flex-start;
  }
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.red};
  padding: 25px;
`;
