import React, { useCallback, useEffect, useState } from 'react';

import Button from '@components/Button';
import Card from '@components/Card';
import { Icons } from '@components/Dictionaries/Icons';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { useAuth } from '@hooks/useAuth';
import { useVIs } from '@hooks/useVIs';
import { IDomainType } from '@models/domain/IDomainType';
import { IVI, IVIFilter } from '@models/domain/IVI';
import { IResponse } from '@models/util/IResponse';
import { domainsService } from '@services/index';

import VIDetails from './Details';
import VIFilter from './Filter';
import VIForm from './Form';
import VIImport from './Import';
import VIList from './List';

interface Props {
  headerButtons: boolean;
  tableRowsPerPageDefault: number;
}

const IdentifyValues: React.FC<Props> = ({
  headerButtons = true,
  tableRowsPerPageDefault = 10,
}) => {
  const { permissions } = useAuth();
  const { fetchVis/*, deleteVi*/ } = useVIs();
  const [vis, setVIS] = useState<IResponse<IVI[]>>();
  const [visLoading, setVisLoading] = useState(true);
  const [selectedVI, setSelectedVI] = useState<IVI>();
  const [transactions, setTransactions] = useState<IDomainType[]>();
  const [senders, setSenders] = useState<IDomainType[]>();
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(tableRowsPerPageDefault);
  const [filter, setFilter] = useState('saldoAConciliar');
  const [filters, setFilters] = useState<IVIFilter>();
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const listVis = useCallback(
    async (cancelToken?) => {
      setVisLoading(true);

      try {
        const response = await fetchVis(
          page,
          rowsPerPage,
          filters,
          filter,
          cancelToken
        );

        setVIS(response?.rows);
        setRowsAmount(response?.count);
      } catch {
        setVIS((prevState) => ({ ...prevState, data: [] }));
        setRowsAmount(0);
      } finally {
        setVisLoading(false);
      }
    },
    [filter, filters, fetchVis, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listVis(source.token);

    return () => {
      source.cancel();
    };
  }, [listVis]);

  const listTypes = useCallback(async (cancelToken?) => {
    try {
      const typeTransactions = await domainsService.transactionType(
        cancelToken
      );
      const typeSenders = await domainsService.senderType(cancelToken);

      setTransactions(typeTransactions);
      setSenders(typeSenders);
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar Tipos de Transação.');
    }
  }, []);

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listTypes(source.token);

    return () => {
      source.cancel();
    };
  }, [listTypes]);

  function handleOpenDetail(vi: IVI) {
    setOpenDetail(true);
    setSelectedVI(vi);
  }

  /*async function handleDeleteVI(vi: IVI) {
    await deleteVi(vi);
    await listVis();
  }

  function handleEditVI(vi: IVI) {
    setOpenEdit(true);
    setSelectedVI(vi);
  }*/

  function handleFilter(value: string) {
    setFilter(value);
    setFilters(null);
  }

  return (
    <Card>
      <header>
        <h1>Valores a Identificar</h1>
        {headerButtons && (
          <div className="menu">
            {vis?.acoes?.consultar && (
              <Button onClick={() => setOpenFilter(true)} flex>
                <Icons.Filter />
                Filtrar
              </Button>
            )}
            {permissions?.vis?.create && (
              <>
                <Button
                  onClick={() => setOpenImport(true)}
                  disabled={visLoading}
                >
                  Importar
                </Button>
                {/*<Button onClick={() => setOpenNew(true)} disabled={visLoading}>
                  Novo
            </Button>*/}
              </>
            )}
          </div>
        )}
      </header>
      <VIList
        setTablePage={setPage}
        setRowsPerPage={setRowsPerPage}
        //handleDeleteVI={handleDeleteVI}
        //handleEditVI={handleEditVI}
        handleOpenDetail={handleOpenDetail}
        vis={vis}
        rowsPerPage={rowsPerPage}
        rowsAmount={rowsAmount}
        filter={filter}
        handleFilter={handleFilter}
        headerButtons={headerButtons}
        visLoading={visLoading}
      />
      {openNew && (
        <Modal title="Novo VI" setOpenModal={setOpenNew}>
          <VIForm
            refreshVIS={listVis}
            transactions={transactions}
            setOpenModal={setOpenNew}
            senders={senders}
          />
        </Modal>
      )}
      {openEdit && (
        <Modal title="Editar VI" setOpenModal={setOpenEdit}>
          <VIForm
            refreshVIS={listVis}
            transactions={transactions}
            senders={senders}
            selectedVI={selectedVI}
            setOpenModal={setOpenEdit}
          />
        </Modal>
      )}
      {openDetail && (
        <Modal title="Detalhes" setOpenModal={setOpenDetail}>
          <VIDetails vi={selectedVI} />
        </Modal>
      )}
      {openImport && (
        <Modal title="Importar VI" setOpenModal={setOpenImport}>
          <VIImport setOpenModal={setOpenImport} refreshVIS={listVis} />
        </Modal>
      )}
      {openFilter && (
        <Modal title="Filtrar lista de VIs" setOpenModal={setOpenFilter}>
          <VIFilter
            transactions={transactions}
            senders={senders}
            filters={filters}
            setOpenModal={setOpenFilter}
            setFilters={setFilters}
            setSelectFilter={setFilter}
          />
        </Modal>
      )}
    </Card>
  );
};

export default IdentifyValues;
