import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from 'styled-components';

import GlobalStyles from '@assets/styles/GlobalStyles';
import { theme } from '@assets/styles/theme';
import { ErrorBoundaryFallback } from '@components/ErrorBoundary';
import Routes from '@routes/routes';

import { AuthProvider } from '@hooks/useAuth';
import { LoadingProvider } from '@hooks/useLoading';
import { MenuProvider } from '@hooks/useMenu';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <ToastContainer />
    <GlobalStyles />
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <AuthProvider>
        <LoadingProvider>
          <MenuProvider>
            <Routes />
          </MenuProvider>
        </LoadingProvider>
      </AuthProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
