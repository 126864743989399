import React, { useState } from 'react';

import { Icons } from '../Dictionaries/Icons';
import Input from '../Input';
import Tooltip from '../Tooltip';
import { Button } from './styles';

interface Props {
  label?: string;
  name?: string;
  params?: boolean;
  value?: string;
  hasError?: boolean;
  errorText?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const PasswordInput: React.FC<Props> = ({
  params = true,
  name,
  label,
  placeholder,
  value,
  hasError,
  errorText,
  onChange,
  onBlur,
}) => {
  const [show, setShow] = useState(false);

  function handleShowPasswordClick() {
    setShow((prevState) => !prevState);
  }

  function handleType() {
    return params && !show ? 'password' : 'text';
  }

  return (
    <Input
      label={label}
      name={name}
      placeholder={placeholder}
      type={handleType()}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      hasError={hasError}
      errorText={errorText}
      propend={
        params ? (
          <Button type="button" onClick={handleShowPasswordClick}>
            {show ? (
              <Icons.ClosedEye title="Esconder Senha" titleFor="password" />
            ) : (
              <Icons.OpenedEye title="Mostrar Senha" titleFor="password" />
            )}
            <Tooltip id="password" />
          </Button>
        ) : (
          ''
        )
      }
    />
  );
};
