import styled from 'styled-components';

type PropType = {
  retract?: boolean;
};

export const Container = styled.div<PropType>`
  height: 100vh;
  width: 100%;
  white-space: nowrap;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 65px auto;
  grid-template-areas:
    'aside header'
    'aside content';

  h1 {
    font-size: var(--fsz-bigger);
    font-weight: bold;
  }

  h2 {
    font-size: var(--fsz-big);
  }
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 25px;
  background-color: ${(props) => props.theme.colors.background};
  overflow: auto;
  font-size: var(--fsz-medium);

  @media (max-width: 1280px) {
    padding: 20px;
  }
`;
