import React from 'react';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Input from '@components/Input';
import Radio from '@components/Radio';

import { masks } from '@helpers/mask';
import { IDevolution } from '@models/domain/IConciliation';

import schema from './schema';
import { BankAccount } from './styled';

interface Props {
  benefited?: IDevolution;
  setFieldValue(name: string, value: unknown): void;
  setOpenModal(state: boolean): void;
}

const BenefitedForm: React.FC<Props> = ({
  benefited,
  setOpenModal,
  setFieldValue,
}) => {
  function handleSubmit(data: IDevolution) {
    const devolucao = { ...data };
    const beneficiado = `BAN: ${data.bancoDestino} - AG: ${
      data.agenciaDestino
    } - C/C: ${masks.bankAccount(data.contaDestino)}`;

    setFieldValue('devolucao', devolucao);
    setFieldValue('beneficiado', beneficiado);

    setOpenModal(false);
    toast.success('Beneficiado criado');
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      valor: benefited?.valor ?? 0,
      nomeDestino: benefited?.nomeDestino ?? '',
      bancoDestino: benefited?.bancoDestino ?? '',
      agenciaDestino: benefited?.agenciaDestino ?? '',
      contaDestino: benefited?.contaDestino ?? '',
      tipoDocumento: masks.handleDocumentType(benefited.cpfCnpjDestino),
      cpfCnpjDestino: masks.handleDocument(benefited?.cpfCnpjDestino),
    },
  });

  function handleDocumentTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    formik.setFieldValue('tipoDocumento', e.target.id);
    formik.setFieldValue('cpfCnpjDestino', '');
  }

  function handleDocumentChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;
    const regex = /([a-zA-Z!@#$%^& *()_+=\\[\]{};':"\\|,<>\\?~])/;

    if (formik.values.tipoDocumento === 'cpf') {
      value = masks.cpf(value);

      if (value.length === 15 || regex.test(value)) return;

      formik.setFieldValue('cpfCnpjDestino', masks.cpf(e.target.value));
    } else if (formik.values.tipoDocumento === 'cnpj') {
      value = masks.cpf(value);

      if (value.length === 19 || regex.test(value)) return;

      formik.setFieldValue('cpfCnpjDestino', masks.cnpj(e.target.value));
    }
  }

  function handleBank(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    if (
      (name === 'bancoDestino' && value.length > 3) ||
      (name === 'agenciaDestino' && value.length > 4) ||
      (name === 'contaDestino' && value.length > 12)
    ) {
      return;
    }

    formik.setFieldValue(name, value);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Input
          label="Nome *"
          placeholder="ex: Nome Sobrenome"
          name="nomeDestino"
          value={formik.values.nomeDestino}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.nomeDestino && formik.errors.nomeDestino)}
          errorText={formik.touched.nomeDestino && formik.errors.nomeDestino}
        />
        <BankAccount>
          <Input
            label="Banco *"
            placeholder="ex: 000"
            name="bancoDestino"
            value={formik.values.bancoDestino}
            onChange={handleBank}
            onBlur={formik.handleBlur}
            hasError={
              !!(formik.touched.bancoDestino && formik.errors.bancoDestino)
            }
            errorText={
              formik.touched.bancoDestino && formik.errors.bancoDestino
            }
          />
          <Input
            label="Agência *"
            placeholder="ex: 0000"
            name="agenciaDestino"
            value={formik.values.agenciaDestino}
            onChange={handleBank}
            onBlur={formik.handleBlur}
            hasError={
              !!(formik.touched.agenciaDestino && formik.errors.agenciaDestino)
            }
            errorText={
              formik.touched.agenciaDestino && formik.errors.agenciaDestino
            }
          />
        </BankAccount>
        <Input
          label="Conta Corrente *"
          placeholder="ex: 0000000000 (Obs: Incluir dígito da conta, porém sem traço)"
          name="contaDestino"
          value={formik.values.contaDestino}
          onChange={handleBank}
          onBlur={formik.handleBlur}
          hasError={
            !!(formik.touched.contaDestino && formik.errors.contaDestino)
          }
          errorText={formik.touched.contaDestino && formik.errors.contaDestino}
        />
        <Radio
          title="Selecione o tipo de documento *"
          hasError={
            !!(formik.touched.tipoDocumento && formik.errors.tipoDocumento)
          }
          errorText={
            formik.touched.tipoDocumento && formik.errors.tipoDocumento
          }
        >
          <label htmlFor="cpf">
            <input
              type="radio"
              name="radio-benefited"
              id="cpf"
              checked={formik.values.tipoDocumento === 'cpf'}
              onChange={handleDocumentTypeChange}
            />
            <span>CPF</span>
          </label>
          <label htmlFor="cnpj">
            <input
              type="radio"
              name="radio-benefited"
              id="cnpj"
              checked={formik.values.tipoDocumento === 'cnpj'}
              onChange={handleDocumentTypeChange}
            />
            <span>CNPJ</span>
          </label>
        </Radio>
        <Input
          label="Documento *"
          placeholder={`ex: ${
            formik.values.tipoDocumento === 'cnpj'
              ? '00.000.000/0000-00'
              : '000.000.000-00'
          }`}
          name="cpfCnpjDestino"
          disabled={!formik.values.tipoDocumento}
          value={formik.values.cpfCnpjDestino}
          onChange={handleDocumentChange}
          onBlur={formik.handleBlur}
          hasError={
            !!(formik.touched.cpfCnpjDestino && formik.errors.cpfCnpjDestino)
          }
          errorText={
            formik.touched.cpfCnpjDestino && formik.errors.cpfCnpjDestino
          }
        />
      </div>
      <div className="buttons">
        <Button
          disabled={formik.isSubmitting}
          color="warning"
          onClick={() => setOpenModal(false)}
        >
          Cancelar
        </Button>
        <Button type="submit" disabled={formik.isSubmitting || !formik.dirty}>
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default BenefitedForm;
