import { useCallback } from 'react';

import { handleRequestError } from '@helpers/handleRequestError';
import { IAudit, IAuditFilter } from '@models/domain/IAudit';
import { IExport } from '@models/util/IExport';
import IPaginate from '@models/util/IPaginate';
import { auditService } from '@services/index';

interface Hook {
  listAudit: (
    page: number,
    amount: number,
    filters?: IAuditFilter,
    cancelToken?
  ) => Promise<IPaginate<IAudit[]>>;
  exportAudit: (ids: string) => Promise<IExport>;
}

export function useAudit(): Hook {
  const listAudit = useCallback(
    async (
      page: number,
      amount: number,
      filters?: IAuditFilter,
      cancelToken?
    ) => {
      try {
        if (!filters?.metadataId) delete filters?.metadataId;

        const { rows, count } = await auditService.list(
          page,
          amount,
          filters,
          cancelToken
        );

        return { rows, count };
      } catch (error) {
        handleRequestError(
          error,
          'Erro ao procurar auditorias',
          'Nenhuma auditoria encontrada'
        );
      }
    },
    []
  );

  const exportAudit = useCallback(async (ids: string) => {
    try {
      const { data, filename } = await auditService.export(ids);

      return { data, filename };
    } catch (error) {
      handleRequestError(error, 'Erro ao exportar auditorias');
    }
  }, []);

  return {
    listAudit,
    exportAudit,
  };
}
