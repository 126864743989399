import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Span = styled.span``;
