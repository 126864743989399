import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { ConciliationStatusEnum } from '@enums/conciliationStatus.enum';
import { SendersEnum } from '@enums/senders.enum';
import { clearObject } from '@helpers/clearObject';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IConciliation } from '@models/domain/IConciliation';
import { ITitle } from '@models/domain/ITitle';
import { conciliationService } from '@services/index';

interface Hook {
  clearConciliationToSubmit: (data: IConciliation) => IConciliation;
  saveConciliation: (
    data: IConciliation,
    isEdit: boolean
  ) => Promise<void | ITitle>;
  // saveConciliation: (data: IConciliation, isEdit: boolean) => Promise<ITitle>;
  effectuateConciliation: (
    conciliation?: IConciliation,
    conta?: string,
    push?: (to: string) => void
  ) => Promise<void>;
}

export function useConciliations(): Hook {
  const clearConciliationToSubmit = (data: IConciliation) => {
    const submit: IConciliation = {
      id: data.id || null,
      data: data.data,
      valor: data.valor,
      empresaId: data?.empresa?.id,
      empresaCedenteId: data?.empresaCedente?.id || null,
      status:
        typeof data.status === 'string'
          ? ConciliationStatusEnum[data.status]
          : data.status,
      somaTitulos: data.somaTitulos,
      liquidacao: {
        pagadorCpfCnpjLiquidacao: data.liquidacao.pagadorCpfCnpjLiquidacao || null,
        pagadorRazaoSocialLiquidacao:
          data.liquidacao.pagadorRazaoSocialLiquidacao || null,
        tipo: data.liquidacao.tipo ? SendersEnum[data.liquidacao.tipo] : null,
      },
      contaEscrow: data.contaEscrow,
      recompraSimuladaId: data.recompraSimuladaId || null,
      contaTransferencia: data.contaTransferencia || null,
      contaPropria: data.contaPropria || null,
      criadoPor: data.criadoPor,
      observacao: data.observacao ?? null,
      devolucao: {
        nomeDestino: data.devolucao.nomeDestino || null,
        agenciaDestino: data.devolucao.agenciaDestino || null,
        bancoDestino: data.devolucao.bancoDestino || null,
        contaDestino: data.devolucao.contaDestino || null,
        cpfCnpjDestino:
          data.devolucao.cpfCnpjDestino !== undefined
            ? masks.sanitize.format(data.devolucao.cpfCnpjDestino) || null
            : null,
        valor: data.devolucao.valor || null,
      },
      valoresAIdentificarConciliacao: data.valoresAIdentificarConciliacao,
      titulos: data.titulos.map((title) => {
        return {
          id: title.id === title.tituloId ? null : title.id,
          conciliacaoId: title.conciliacaoId || null,
          tipo: title.tipo || null,
          valor: title.valor || 0,
          numeroTitulo: title.numeroTitulo || null,
          vencimento: title.vencimento || null,
          vencimentoUtil: title.vencimentoUtil || null,
          deducoes: title.deducoes || 0,
          valorResidual: title.valorResidual || 0,
          diferenca: title.diferenca || 0,
          valorFinal: title.valorFinal || 0,
          cedente: title.cedente || null,
          sacado: title.sacado || null,
          status: String(title.status) || null,
          tituloId: String(title.tituloId) || null,
          diferencaInput: title.diferencaInput || 0,
          valorQuitado: title.valorQuitado || 0,
          deduzidoFace: title.deduzidoFace || 0,
          quitacaoParcial: title.quitacaoParcial || false,
          moraDescricao: title.moraDescricao || null,
          tipoOperacao: title.tipoOperacao || null,
        };
      }),
    };

    return clearObject(submit);
  };

  const saveConciliation = useCallback(
    async (data: IConciliation, isEdit: boolean) => {
      if (!isEdit) {
        return conciliationService.register(clearConciliationToSubmit(data));
      }

      return conciliationService.edit(clearConciliationToSubmit(data));
    },
    []
  );

  const effectuateConciliation = useCallback(
    async (conciliation?: IConciliation, conta?: string, push?) => {
      let id = conciliation?.id;
      const submit = conciliation;

      if (conta) submit.contaPropria = conta;

      try {
        if (!id) {
          const response = await conciliationService.register(
            clearConciliationToSubmit(submit)
          );

          id = response?.id;
        } else {
          await conciliationService.edit(clearConciliationToSubmit(submit));
        }

        await conciliationService.effectuate(id);

        if (push) push('/conciliacoes');

        toast.success('Conciliação efetivada com sucesso!');
      } catch (error) {
        handleRequestError(error, `Erro ao efetivar conciliação`);
      }
    },
    []
  );

  return {
    clearConciliationToSubmit,
    saveConciliation,
    effectuateConciliation,
  };
}
