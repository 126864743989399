import React from 'react';

import Input from '@components/Input';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { TitleOperationTypeEnum } from '@enums/titleOperationType.enum';
import { TitleStatusEnum } from '@enums/titleStatus.enum';
import { masks } from '@helpers/mask';
import { IConciliation } from '@models/domain/IConciliation';
import { ITitle } from '@models/domain/ITitle';

interface Props {
  titles: ITitle[];
  rowsAmount: number;
  rowsPerPage: number;
  conciliation: IConciliation;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
}

const TitlesList: React.FC<Props> = ({
  titles,
  setTablePage,
  rowsAmount,
  rowsPerPage,
  setRowsPerPage,
  conciliation,
}) => {
  function findConciliation(tituloId: string) {
    const titulo = conciliation?.titulos?.find(
      (x) => String(x?.tituloId) === String(tituloId)
    );

    return titulo;
  }

  function checkRowId(row: ITitle) {
    return !!findConciliation(row?.tituloId)?.tituloId;
  }

  return (
    <Table
      selectableRows
      setTablePage={setTablePage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      selectableRowDisabled={() => true}
      isLoading={titles === undefined}
      selectableRowSelected={(row: ITitle) =>
        !!conciliation?.recompraSimuladaId || checkRowId(row)
      }
      columns={[
        {
          name: 'N° Título',
          selector: 'numeroTitulo',
          cell: (row) => <SpanTable id={row.id} text={row.numeroTitulo} />,
        },
        {
          name: 'Vencimento',
          selector: 'vencimento',
          minWidth: '85px',
          cell: (row) => (
            <SpanTable id={row.id} text={masks.date.string(row.vencimento)} />
          ),
        },
        {
          name: 'Valor de Face',
          selector: 'valorFace',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorFace)}
            />
          ),
        },
        {
          name: 'Deduções',
          selector: 'deducoes',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={masks.currency.string(row.deducoes)} />
          ),
        },
        {
          name: 'Valor Residual',
          selector: 'valorResidual',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorResidual)}
            />
          ),
        },
        {
          name: 'Diferença',
          selector: 'diferenca',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.diferenca)}
            />
          ),
        },
        {
          name: 'Valor Final',
          selector: 'valorFinal',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorFinal)}
            />
          ),
        },
        {
          name: 'Cedente',
          selector: 'empresaCedente',
          minWidth: '100px',
          cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          minWidth: '100px',
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Sacado',
          selector: 'sacado',
          cell: (row) => <SpanTable id={row.id} text={row.sacado} />,
        },
        {
          name: 'Status',
          selector: 'status',
          cell: (row) => (
            <SpanTable id={row.id} text={TitleStatusEnum[row.status]} />
          ),
        },
        {
          name: 'ID Título',
          selector: 'tituloId',
          width: '75px',
          cell: (row) => <SpanTable id={row.id} text={row.tituloId} />,
        },
        {
          name: 'Diferença',
          selector: 'diferencaField',
          width: '150px',
          center: true,
          cell: (row) => (
            <Input
              type="text"
              className="diferencaInput"
              defaultValue={masks.currency.string(
                findConciliation(row.tituloId)?.diferencaInput
              )}
              disabled
            />
          ),
        },
        {
          name: 'Valor Quitado',
          selector: 'valorQuitadoField',
          width: '150px',
          center: true,
          cell: (row) => (
            <Input
              type="text"
              className="valorQuitadoField"
              defaultValue={masks.currency.string(
                findConciliation(row.tituloId)?.valorQuitado
              )}
              disabled
            />
          ),
        },
        {
          name: 'Valor Deduzido',
          selector: 'valorDeduzidoField',
          width: '150px',
          center: true,
          cell: (row) => (
            <Input
              type="text"
              className="diferencaInput"
              defaultValue={masks.currency.string(
                findConciliation(row.tituloId)?.deduzidoFace
              )}
              disabled
            />
          ),
        },
        {
          name: 'Parcial',
          selector: 'quitacaoParticialField',
          width: '75px',
          center: true,
          cell: (row) => (
            <input
              type="checkbox"
              className="quitacaoParcial"
              defaultChecked={findConciliation(row.tituloId)?.quitacaoParcial}
              disabled
            />
          ),
        },
      ]}
      content={titles?.map((title) => {
        return {
          id: title.id,
          tipo: title.tipo,
          numeroTitulo: title.numero,
          vencimento: title.vencimento,
          valorFace: title.valor,
          deducoes: title.deducoes,
          valorResidual: title.valorResidual,
          diferenca: title.diferenca,
          valorFinal: title.valorFinal,
          empresaCedente: title.apelidoCedente,
          tipoOperacao: TitleOperationTypeEnum[title.tipoOperacao],
          empresa: conciliation?.empresa?.razaoSocial,
          sacado: conciliation?.liquidacao?.pagadorRazaoSocialLiquidacao,
          status: title.status,
          tituloId: title.id || '-',
          cedente: title.apelidoCedente,
          cnpjSacado: title.cnpjSacado,
        };
      })}
    />
  );
};

export default TitlesList;
