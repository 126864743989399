import styled from 'styled-components';

export const Container = styled.div`
  & > div {
    margin-top: 8px;
  }

  p {
    margin: 50px 0;
    padding: 50px;
  }

  .buttons {
    justify-content: flex-end;
  }
`;
