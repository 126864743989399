import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Modal from '@components/Modal';

import { ConciliationStatusEnum } from '@enums/conciliationStatus.enum';
import { SendersEnum } from '@enums/senders.enum';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { useConciliations } from '@hooks/useConciliations';
import { IConciliation, IDevolution } from '@models/domain/IConciliation';
import { IVI } from '@models/domain/IVI';
import { IRouteWithId } from '@models/util/IRouteParams';
import { viService } from '@services/index';

import ConciliationErrorText from '../../ErrorText';
import History from '../../History';
import BenefitedForm from '../BenefitedForm';
import { ConciliationForm } from '../Form';
import Repurchase from './Repurchase';
import schema from './schema';
import { Container, PermissionDenied } from './styles';
import VIConciliations from './VIConciliations';

interface Props {
  conciliation: IConciliation;
  setCurrentStep?(step: number): void;
  setConciliation(obj: unknown): void;
}

const SecondStep: React.FC<Props> = ({
  conciliation,
  setConciliation,
  setCurrentStep,
}) => {
  const history = useHistory();
  const { saveConciliation } = useConciliations();
  const { id } = useParams<IRouteWithId>();
  const [visTablePage, setVisTablePage] = useState(1);
  const [visTableRowsPerPage, setVisTableRowsPerPage] = useState(10);
  const [visTableRowsAmount, setVisTableRowsAmount] = useState(1);
  const [vis, setVIS] = useState<IVI[]>();
  const [openBenefited, setOpenBenefited] = useState(false);
  const [openRepurchase, setOpenRepurchase] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [senderExternalId, setSenderExternalId] = useState('');

  const isEdit = useCallback(() => {
    return !!id;
  }, [id]);

  const listVIS = useCallback(
    async (empresaId: string, empresaCedenteId: string, cancelToken?) => {
      try {
        const { rows, count } = await viService.conciliationVIs(
          visTablePage,
          visTableRowsPerPage,
          empresaId,
          empresaCedenteId ?? '',
          id ?? '',
          cancelToken
        );

        setVIS(rows);
        setVisTableRowsAmount(count);
        if (rows.length > 0 && rows[0].remetente)
          setSenderExternalId(rows[0].remetente.idExterno);
      } catch (error) {
        setVIS([]);
        setSenderExternalId('');
        handleRequestError(
          error,
          'Erro ao carregar VIs.',
          'VIs não encontrados para as empresas selecionadas'
        );
      }
    },
    [id, visTablePage, visTableRowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (conciliation?.empresa?.id !== undefined) {
      listVIS(
        conciliation.empresa.id,
        conciliation?.empresaCedente?.id,
        source.token
      );
    }

    return () => {
      source.cancel();
    };
  }, [conciliation, listVIS]);

  function handleSubmit(data: IConciliation) {
    setConciliation(data);
    setCurrentStep(3);
  }

  const { setFieldValue, ...formik } = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      id: conciliation?.id ?? '',
      data: conciliation?.data ?? masks.date.format(String(new Date())),
      valor: conciliation?.valor ?? 0,
      empresa: conciliation?.empresa,
      empresaCedente: conciliation?.empresaCedente ?? {},
      status:
        typeof conciliation?.status === 'number'
          ? ConciliationStatusEnum[conciliation?.status]
          : conciliation?.status ??
            ConciliationStatusEnum[conciliation?.status ?? 1],
      somaTitulos: isEdit() ? conciliation?.somaTitulos : 0,
      recompraSimuladaId: conciliation?.recompraSimuladaId ?? '',
      criadoPor: conciliation?.criadoPor ?? '',
      beneficiado: conciliation?.devolucao?.bancoDestino
        ? `BAN: ${conciliation?.devolucao?.bancoDestino ?? ''} - AG: ${
            conciliation?.devolucao?.agenciaDestino ?? ''
          } - C/C: ${masks.bankAccount(conciliation?.devolucao?.contaDestino)}`
        : '',
      contaPropria: conciliation.contaPropria ?? '',
      liquidacao: {
        tipo: conciliation?.liquidacao?.tipo ?? '',
        pagadorRazaoSocialLiquidacao:
          conciliation?.liquidacao?.pagadorRazaoSocialLiquidacao ?? '',
        pagadorCpfCnpjLiquidacao:
          conciliation?.liquidacao?.pagadorCpfCnpjLiquidacao ?? '',
      },
      contaEscrow:
        conciliation?.contaEscrow ??
        !!conciliation?.empresaCedente?.id ??
        false,
      contaTransferencia: conciliation?.contaTransferencia ?? '',
      devolucao: {
        valor: conciliation?.devolucao?.valor ?? 0,
        nomeDestino: conciliation?.devolucao?.nomeDestino ?? '',
        bancoDestino: conciliation?.devolucao?.bancoDestino ?? '',
        agenciaDestino: conciliation?.devolucao?.agenciaDestino ?? '',
        contaDestino: conciliation?.devolucao?.contaDestino ?? '',
        cpfCnpjDestino: conciliation?.devolucao?.cpfCnpjDestino ?? '',
      },
      valoresAIdentificarConciliacao:
        conciliation?.valoresAIdentificarConciliacao || [],
      titulos: isEdit() ? conciliation?.titulos : [],
    },
  });

  const refreshViFormValues = useCallback(() => {
    if (
      isEdit() &&
      (!formik.values.valoresAIdentificarConciliacao ||
        formik.values.valoresAIdentificarConciliacao.length <= 0)
    )
      return;

    const visConciliation = formik.values.valoresAIdentificarConciliacao;
    const visConciliationHasItems = visConciliation.length > 0;
    const devolutionTotal = visConciliationHasItems
      ? visConciliation
          ?.map((item) => item.valorADevolver)
          ?.reduce((acc, cur) => (acc += cur))
      : 0;
    let viTotal = visConciliationHasItems
      ? visConciliation
          ?.map((item) => item.valor)
          ?.reduce((acc, cur) => (acc += cur)) + formik.values.devolucao.valor
      : formik.values.valor ?? 0;
    let devolucao: IDevolution = {
      ...formik.values.devolucao,
      valor: devolutionTotal,
    };

    if (devolutionTotal > 0) {
      devolucao = {
        ...devolucao,
        agenciaDestino: formik.values.devolucao?.agenciaDestino ?? '',
        bancoDestino: formik.values.devolucao?.bancoDestino ?? '',
        contaDestino: formik.values.devolucao?.contaDestino ?? '',
        cpfCnpjDestino: formik.values.devolucao?.cpfCnpjDestino ?? '',
        nomeDestino: formik.values.devolucao?.nomeDestino ?? '',
        tipoDocumento: formik.values.devolucao?.tipoDocumento ?? '',
      };
    } else {
      devolucao = {
        valor: 0,
        agenciaDestino: '',
        bancoDestino: '',
        contaDestino: '',
        cpfCnpjDestino: '',
        nomeDestino: '',
        tipoDocumento: '',
      };
    }

    if (!visConciliationHasItems && viTotal) {
      setFieldValue('valor', 0);
    }
    if (viTotal !== formik.values.valor) {
      /* const valorAux = handleDecimalChange(formik.values.valor);

      if (valorAux >= devolutionTotal + visConciliation[0].valor)
        viTotal = valorAux; */
      setFieldValue('valor', viTotal);
    }
    if (devolutionTotal !== formik.values.devolucao.valor) {
      const devolutionAux = handleDecimalChange(formik.values.devolucao.valor);

      if (devolutionAux >= devolutionTotal + visConciliation[0].valor)
        devolucao.valor = devolutionAux;

      setFieldValue('devolucao', devolucao);
    }
  }, [
    formik.values.devolucao,
    formik.values.valor,
    formik.values.valoresAIdentificarConciliacao,
    isEdit,
    setFieldValue,
  ]);

  function handleDecimalChange(inputValue) {
    const valorAux = inputValue?.toString();
    let valor = '';

    for (let i = 0; i < valorAux?.length; i++) {
      if (
        valorAux[i] !== ' ' &&
        valorAux[i] !== 'R' &&
        valorAux[i] !== '$' &&
        valorAux[i] !== '.' &&
        valorAux[i] !== ','
      )
        valor += valorAux[i];
      else if (valorAux[i] === ',') valor += '.';
    }
    inputValue = Number.parseFloat(valor);

    return inputValue;
  }

  useEffect(() => {
    refreshViFormValues();
  }, [refreshViFormValues]);

  useEffect(() => {
    if (formik.values.devolucao.valor <= 0) {
      setFieldValue('beneficiado', '');
    }
  }, [formik.values.beneficiado, formik.values.devolucao, setFieldValue]);

  function checkStatusIsApprove() {
    return conciliation?.status === 2;
  }

  function historyGoBack() {
    history.goBack();
  }

  function handleLiquidationType() {
    return typeof formik.values.liquidacao.tipo === 'number'
      ? SendersEnum[formik.values.liquidacao.tipo]
      : formik.values.liquidacao.tipo;
  }

  function isSubmitDisabled() {
    return (
      formik.isSubmitting ||
      formik.values.valoresAIdentificarConciliacao.length < 1 ||
      formik.values.liquidacao.tipo === 'Cedente' ||
      formik.values.valor <= 0
    );
  }

  function hasVisFieldsEmpty() {
    return (
      formik.values.valoresAIdentificarConciliacao.filter(
        (item) => item.valor <= 0 && item.valorADevolver <= 0
      ).length > 0
    );
  }

  async function handleSave() {
    formik.values.valor = handleDecimalChange(formik.values.valor);
    formik.values.devolucao.valor = handleDecimalChange(
      formik.values.devolucao.valor
    );
    formik.values.somaTitulos = handleDecimalChange(formik.values.somaTitulos);
    if (isSaving) return;

    setIsSaving(true);

    try {
      const response = await saveConciliation(
        formik.values,
        isEdit() || !!formik.values.id
      );

      toast.success('Conciliação salva com sucesso');
      if (response) {
        setFieldValue('id', response?.id);
      }
    } catch (error) {
      handleRequestError(error, 'Erro na criação da conciliação');
    } finally {
      setIsSaving(false);
    }
  }

  if (checkStatusIsApprove()) {
    return (
      <PermissionDenied>
        <ConciliationErrorText>
          Esta conciliação não pode ser mais editada, pois o status dela é
          {ConciliationStatusEnum[conciliation?.status]}.
        </ConciliationErrorText>
        <Button onClick={historyGoBack}>Voltar</Button>
      </PermissionDenied>
    );
  }

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <ConciliationForm
          formik={formik}
          setOpenBenefited={setOpenBenefited}
          liquidationType={handleLiquidationType()}
        />
        <div className="vi">
          <VIConciliations
            vis={vis}
            rowsAmount={visTableRowsAmount}
            rowsPerPage={visTableRowsPerPage}
            setTablePage={setVisTablePage}
            setRowsPerPage={setVisTableRowsPerPage}
            isEdit={isEdit()}
            formikVis={formik.values.valoresAIdentificarConciliacao}
            setFieldValue={setFieldValue}
            formik={formik}
          />
        </div>
        {hasVisFieldsEmpty() && (
          <ConciliationErrorText>
            Os campos de Valor a Conciliar e Valor Devolução nas VIs
            selecionadas devem somar mais que zero.
          </ConciliationErrorText>
        )}
        <div className="buttons">
          <Button onClick={() => setCurrentStep(1)}>Anterior</Button>
          <div className="buttons-wrapper">
            <Button
              onClick={handleSave}
              disabled={
                formik.isSubmitting ||
                isSaving ||
                (formik.values.liquidacao.tipo === 'Cedente' &&
                  (formik.values.recompraSimuladaId === '' ||
                    formik.values.recompraSimuladaId === null)) ||
                formik.values.valor <= 0
              }
            >
              Salvar
            </Button>
            {isEdit() && (
              <Button onClick={() => setOpenHistory(true)}>Histórico</Button>
            )}
            <Button
              onClick={() => setOpenRepurchase(true)}
              disabled={
                !(formik.values.liquidacao.tipo === 'Cedente') ||
                (formik.values.devolucao?.valor > 0 &&
                  !formik.values.beneficiado)
              }
            >
              Abrir Recompra Simulada
            </Button>
            <Button type="submit" disabled={isSubmitDisabled()}>
              Próximo
            </Button>
          </div>
        </div>
        {openRepurchase && (
          <Modal
            title="Selecionar Recompra Simulada"
            setOpenModal={setOpenRepurchase}
          >
            <Repurchase
              company={conciliation.empresa?.idExterno}
              assignorCompany={senderExternalId}
              formikValues={formik.values}
              setFieldValue={setFieldValue}
              setConciliation={setConciliation}
              setCurrentStep={setCurrentStep}
              setOpenRepurchase={setOpenRepurchase}
            />
          </Modal>
        )}
      </form>
      {openBenefited && (
        <Modal title="Beneficiado" setOpenModal={setOpenBenefited}>
          <BenefitedForm
            benefited={formik.values.devolucao}
            setFieldValue={setFieldValue}
            setOpenModal={setOpenBenefited}
          />
        </Modal>
      )}
      {openHistory && (
        <History conciliation={id} setOpenHistory={setOpenHistory} />
      )}
    </Container>
  );
};

export default SecondStep;
