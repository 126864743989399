import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { handleRequestError } from '@helpers/handleRequestError';
import { IConfigurationEdit } from '@models/domain/IConfiguration';
import { configurationsService } from '@services/index';
import { IConfigurationsService } from '@services/interfaces/IConfigurationsService';

interface Hook extends IConfigurationsService {}

export function useConfigurations(): Hook {
  const list = useCallback(
    async (page: number, amount: number, cancelToken?) => {
      try {
        const { rows, count } = await configurationsService.list(
          page,
          amount,
          cancelToken
        );

        return {
          rows,
          count,
        };
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar Configurações');
      }

      return null;
    },
    []
  );

  const edit = useCallback(async (configuration: IConfigurationEdit) => {
    try {
      await configurationsService.edit(configuration);

      toast.success('Configuração editada com sucesso');
    } catch (error) {
      handleRequestError(error, 'Erro ao editar Configuração');
    }
  }, []);

  return {
    list,
    edit,
  };
}
