import React, { useCallback, useEffect, useState } from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { TransactionsEnum } from '@enums/transactions.enum';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IConciliationVis } from '@models/domain/IVI';
import { conciliationService } from '@services/index';

import { Container } from '../styles';

const ListVis = ({ conciliationId }) => {
  const [vis, setVis] = useState<IConciliationVis[]>();
  const [page, setPage] = useState(1);
  const [amount, setAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const listVis = useCallback(
    async (cancelToken?) => {
      try {
        const { rows, count } = await conciliationService.listVis(
          conciliationId,
          page,
          rowsPerPage,
          cancelToken
        );

        setVis(rows);
        setAmount(count);
      } catch (error) {
        setVis([]);
        handleRequestError(
          error,
          'Erro ao listar VIs',
          'Nenhum VI associado com esta conciliação'
        );
      }
    },
    [conciliationId, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listVis(source.token);

    return () => {
      source.cancel();
    };
  }, [listVis]);

  return (
    <Container>
      <h2>VIs Associados</h2>
      <Table
        pagination
        setTablePage={setPage}
        rowsAmount={amount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isLoading={vis === undefined}
        columns={[
          {
            name: 'Data',
            selector: 'data',
            width: '125px',
            cell: (row) => <SpanTable id={row.id} text={row.data} />,
          },
          {
            name: 'Tipo',
            selector: 'tipo',
            cell: (row) => <SpanTable id={row.id} text={row.tipo} />,
          },
          {
            name: 'Complemento',
            selector: 'complemento',
            cell: (row) => <SpanTable id={row.id} text={row.complemento} />,
          },
          {
            name: 'Valor',
            selector: 'valor',
            right: true,
            cell: (row) => <SpanTable id={row.id} text={row.valor} />,
          },
          {
            name: 'Saldo',
            selector: 'saldo',
            right: true,
            cell: (row) => <SpanTable id={row.id} text={row.saldo} />,
          },
          {
            name: 'Valor Conciliado',
            selector: 'valorConciliado',
            minWidth: '125px',
            right: true,
            cell: (row) => <SpanTable id={row.id} text={row.valorConciliado} />,
          },
          {
            name: 'Valor a Conciliar',
            selector: 'valorAConciliar',
            minWidth: '125px',
            right: true,
            cell: (row) => <SpanTable id={row.id} text={row.valorAConciliar} />,
          },
          {
            name: 'Valor a Devolver',
            selector: 'valorADevolver',
            minWidth: '125px',
            right: true,
            cell: (row) => <SpanTable id={row.id} text={row.valorADevolver} />,
          },
        ]}
        content={vis?.map((vi) => {
          return {
            id: vi.id,
            data: masks.date.string(vi.data),
            tipo: TransactionsEnum[vi.tipoTransacao],
            complemento: vi.complemento || '-',
            valor: masks.currency.string(vi.valor),
            valorConciliado: masks.currency.string(vi.valorConciliado),
            saldo: masks.currency.string(vi.saldoAConciliar),
            valorAConciliar: masks.currency.string(vi.valorAConciliar),
            valorADevolver: masks.currency.string(vi.valorADevolver),
          };
        })}
      />
    </Container>
  );
};

export default ListVis;
