import { isObjectEmpty } from './isObjectEmpty';

export function clearObject<T>(obj: T): T {
  if (!obj) return;

  const toClear = obj;

  Object.keys(toClear).forEach(
    (key) =>
      (toClear[key] === undefined ||
        toClear[key] === null ||
        toClear[key] === '' ||
        isObjectEmpty(toClear[key]) ||
        !toClear[key]) &&
      delete toClear[key]
  );

  const newObj = toClear;

  return newObj;
}
