import * as yup from 'yup';

import { cpfValidator } from '@helpers/validators';

const schema = yup.object().shape({
  id: yup.string(),
  perfilId: yup.string().required('Perfil é um campo obrigatório.'),
  nome: yup
    .string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres.')
    .max(80, 'Nome deve ter no máximo 80 caractéres.')
    .required('Nome é um campo obrigatório.'),
  email: yup.string().email().required('Email é um campo obrigatório.'),
  cpf: yup
    .string()
    .matches(
      RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/),
      'CPF precisa ter 11 digitos.'
    )
    .required('CPF é um campo obrigatório.')
    .test({
      name: 'cpf',
      exclusive: true,
      message: 'CPF Inválido.',
      test: (value) => value && cpfValidator(value),
    }),
  nascimento: yup
    .string()
    .required('Data de nascimento é um campo obrigatório.'),
  telefone: yup
    .string()
    .matches(
      RegExp(
        /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/
      ),
      'Número de telefone inválido.'
    ),
  ramal: yup
    .string()
    .max(5, 'Ramal deve ter no máximo 5 dígitos.')
    .matches(RegExp(/^[0-9]*$/), 'Ramal inválido.'),
  celular: yup
    .string()
    .matches(
      RegExp(
        /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/
      ),
      'Número de celular inválido.'
    ),
});

export default schema;
