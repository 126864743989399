import { AxiosInstance, CancelToken } from 'axios';

import {
  IAccount,
  IConciliation,
  IConciliationFilter,
  IConciliationTotalBalance,
} from '@models/domain/IConciliation';
import { ITitle } from '@models/domain/ITitle';
import { IConciliationVis } from '@models/domain/IVI';
import IList from '@models/util/IList';
import IPaginate from '@models/util/IPaginate';
import { IResponse } from '@models/util/IResponse';

import { IConciliationService } from './interfaces/IConciliationService';

const ENDPOINT = '/api/v1/conciliacoes';

export class ConciliationService implements IConciliationService {
  constructor(private api: AxiosInstance) {}

  async list(
    page: number,
    amount = 10,
    inProgress = false,
    filters?: IConciliationFilter,
    cancelToken?: CancelToken
  ) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
      ...filters,
    };

    if (filters?.empresa?.razaoSocial)
      params['empresa.razaoSocial'] = filters?.empresa?.razaoSocial;

    delete params.empresa;

    const query = inProgress ? `${ENDPOINT}/andamento` : ENDPOINT;

    const { data, headers } = await this.api.get<IResponse<IConciliation[]>>(
      query,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<IConciliation[]>;
  }

  async get(id: string, cancelToken?: CancelToken) {
    const { data } = await this.api.get<IResponse<IConciliation>>(
      `${ENDPOINT}/${id}`,
      {
        cancelToken,
      }
    );

    return data;
  }

  async listConciliationsVi(
    viId: string,
    page: number,
    amount = 10,
    cancelToken?: CancelToken
  ) {
    const params = {
      id: viId,
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IResponse<IConciliation[]>>(
      `${ENDPOINT}/conciliacoes-vi`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<IConciliation[]>;
  }

  async listVis(
    conciliationId: string,
    page?: number,
    amount?: number,
    cancelToken?: CancelToken
  ) {
    const params = {
      id: conciliationId,
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { headers, data } = await this.api.get<IConciliationVis[]>(
      `/api/v1/vis/conciliacao-vis`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IPaginate<IConciliationVis[]>;
  }

  async listTitles(
    conciliationId: string,
    page: number,
    amount: number,
    cancelToken?: CancelToken
  ) {
    const params = {
      id: conciliationId,
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { headers, data } = await this.api.get<IResponse<ITitle[]>>(
      `/api/v1/titulos/titulos-conciliacao`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<ITitle[]>;
  }

  async totalBalance(cancelToken: CancelToken) {
    const { data } = await this.api.get<IConciliationTotalBalance>(
      `${ENDPOINT}/somatorio`,
      {
        cancelToken,
      }
    );

    return data;
  }

  async export(ids: string, tipoArquivo: string) {
    const { data, headers } = await this.api.get(
      `${ENDPOINT}/exportar-conciliacoes?ids=${ids}&tipoArquivo=${tipoArquivo}`,
      {
        responseType: 'blob',
      }
    );
    const filename = decodeURIComponent(
      headers['content-disposition'].split("''")[1]
    );

    return {
      data,
      filename,
    };
  }

  async listAccounts(company: string, cancelToken?) {
    const { data } = await this.api.get<IAccount[]>(
      `api/v1/contas/contas?empresa=${company}`,
      {
        cancelToken,
      }
    );

    return data;
  }

  async register(conciliation: IConciliation) {
    const { data } = await this.api.post(ENDPOINT, conciliation);

    return data;
  }

  async edit(data: IConciliation) {
    await this.api.put(ENDPOINT, data);
  }

  async delete(id: string) {
    await this.api.delete(`${ENDPOINT}/${id}`);
  }

  async effectuate(conciliacaoId: string) {
    await this.api.put(`${ENDPOINT}/efetivar/${conciliacaoId}`);
  }
}
