import * as yup from 'yup';

const schema = yup.object().shape({
  data: yup.string(),
  valor: yup.string(),
  empresaId: yup.string(),
  empresaCedenteId: yup.string(),
  status: yup.string(),
  liquidacao: yup.object().shape({
    tipo: yup.string(),
    pagadorRazaoSocialLiquidacao: yup.string(),
    pagadorCpfCnpjLiquidacao: yup.string(),
  }),
  contaEscrow: yup.boolean(),
  contaTransferencia: yup.string(),
  devolucao: yup.object().shape({
    valor: yup.string(),
    bancoDestino: yup.string(),
    agenciaDestino: yup.string(),
    contaDestino: yup.string(),
    cpfCnpjDestino: yup.string(),
  }),
  criadoPor: yup.string(),
  valoresAIdentificarConciliacao: yup.array().of(
    yup.object().shape({
      valoresAIdentificarId: yup.string(),
      valor: yup.string(),
      valorADevolver: yup.string(),
    })
  ),
  beneficiado: yup.string().when('devolucao.valor', {
    is: (val) => Number(val) > 0,
    then: yup.string().required('Beneficiado é um campo obrigatório'),
    otherwise: yup.string(),
  }),
});

export default schema;
