import styled from 'styled-components';

export const Form = styled.form`
  .radios {
    display: flex;
    align-items: center;
    justify-content: space-around;

    label {
      display: flex;
      align-items: center;
    }
  }

  .file {
    display: flex;
    flex-direction: column;

    .file-button {
      align-self: flex-end;
    }
  }
`;

export const ErrorsContainer = styled.ul`
  h2 {
    font-weight: 500;
    margin-bottom: 10px;
  }

  li {
    margin: 10px 0;

    span {
      color: ${(props) => props.theme.colors.red};
    }
  }
`;

export const InputButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a,
  button {
    margin-left: 10px;
  }
`;
