import React, { useCallback, useEffect, useState } from 'react';

import Button from '@components/Button';
import SpinnerLoading from '@components/Loading/SpinnerLoading';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IConciliation } from '@models/domain/IConciliation';
import { IRepurchase } from '@models/domain/IRepurchase';
import { gerService } from '@services/index';

import { Container } from './styles';

interface Props {
  company: string;
  assignorCompany: string;
  formikValues: IConciliation;
  setFieldValue(name: string, value: unknown): void;
  setConciliation(data: IConciliation): void;
  setOpenRepurchase(value: boolean): void;
  setCurrentStep(step: number): void;
}

const Repurchase: React.FC<Props> = ({
  company,
  assignorCompany,
  setOpenRepurchase,
  formikValues,
  setFieldValue,
  setCurrentStep,
  setConciliation,
}) => {
  const [tablePage, setTablePage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [repurchases, setRepurchases] = useState<IRepurchase[]>();
  const [formikDefaultRepurchase] = useState(
    formikValues.recompraSimuladaId ?? ''
  );
  const [formikDefaultDate] = useState(formikValues.data ?? '');

  const listRepurchases = useCallback(
    async (companyId, assignorCompanyId, cancelToken?) => {
      try {
        const { rows, count } = await gerService.listRepurchases(
          tablePage,
          rowsPerPage,
          companyId,
          assignorCompanyId,
          cancelToken
        );

        setRowsAmount(count);
        setRepurchases(rows);
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar Recompras');
      }
    },
    [rowsPerPage, tablePage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (company) listRepurchases(company, assignorCompany ?? '', source.token);

    return () => {
      source.cancel();
    };
  }, [assignorCompany, company, listRepurchases]);

  const handleSelected = useCallback(
    ({ selectedRows }) => {
      setFieldValue('recompraSimuladaId', selectedRows[0]?.id ?? '');
      setFieldValue(
        'data',
        masks.date.format(
          selectedRows[0]?.data ?? masks.date.format(String(new Date()))
        )
      );
    },
    [setFieldValue]
  );

  if (repurchases === undefined) {
    return <SpinnerLoading />;
  }

  function handleSubmit() {
    setOpenRepurchase(false);
    setConciliation({
      ...formikValues,
    });
    setCurrentStep(3);
  }

  function handleClose() {
    setOpenRepurchase(false);
    setFieldValue('recompraSimuladaId', formikDefaultRepurchase);
    setFieldValue('data', formikDefaultDate);
  }

  return (
    <Container>
      <Table
        selectableRows
        onSelectedRowsChange={handleSelected}
        setTablePage={setTablePage}
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        selectableRowDisabled={(row: IRepurchase) =>
          formikValues.recompraSimuladaId &&
          row?.id !== formikValues.recompraSimuladaId
        }
        selectableRowSelected={(row: IRepurchase) =>
          row.id === formikValues.recompraSimuladaId
        }
        pagination={false}
        columns={[
          {
            name: 'ID Externo',
            selector: 'id',
            cell: (row) => <SpanTable id={row.id} text={row.id} />,
          },
          {
            name: 'Data',
            selector: 'dataColumn',
            cell: (row) => <SpanTable id={row.id} text={row.dataColumn} />,
          },
          {
            name: 'Empresa',
            selector: 'empresa',
            cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
          },
          {
            name: 'Cedente',
            selector: 'empresaCedente',
            cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
          },
          {
            name: 'Valor',
            selector: 'valorRecompra',
            cell: (row) => <SpanTable id={row.id} text={row.valorRecompra} />,
          },
        ]}
        content={repurchases?.map((repurchase) => ({
          id: repurchase.numero,
          data: repurchase.data,
          dataColumn: masks.date.string(repurchase.data),
          empresa: repurchase.empresa,
          empresaCedente: repurchase.cedente,
          valorRecompra: masks.currency.string(repurchase.valorRecompra),
        }))}
      />
      <div className="buttons">
        <Button onClick={handleClose} outline color="warning">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!formikValues.recompraSimuladaId}
        >
          Avançar
        </Button>
      </div>
    </Container>
  );
};

export default Repurchase;
