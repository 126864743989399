import { useCallback } from 'react';

import { CancelToken } from 'axios';

import { handleRequestError } from '@helpers/handleRequestError';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { ICompany } from '@models/domain/ICompany';
import { IListResponse } from '@models/util/IListResponse';
import { assignorCompanyService, companyService } from '@services/index';

interface Hook {
  listCompanies(
    page: number,
    rowsPerPage: number,
    cancelToken?: CancelToken
  ): Promise<IListResponse<ICompany[]>>;
  listAllCompanies(): Promise<ICompany[]>;
  listAssignorCompanies(
    page: number,
    rowsPerPage: number,
    cancelToken?: CancelToken
  ): Promise<IListResponse<IAssignorCompany[]>>;
  listAllAssignorCompanies(): Promise<IAssignorCompany[]>;
  getCompanyName(item: ICompany): string;
  getCompanyId(item: ICompany): string;
}

export function useCompanies(): Hook {
  const listCompanies = useCallback(
    async (page: number, rowsPerPage: number, cancelToken?) => {
      try {
        const { rows, count } = await companyService.list(
          page,
          rowsPerPage,
          cancelToken
        );

        return {
          rows,
          count,
        };
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar empresas.');
      }

      return null;
    },
    []
  );

  const listAllCompanies = useCallback(async () => {
    try {
      const { rows } = await companyService.list();

      return rows.data;
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar empresas.');
    }

    return null;
  }, []);

  const listAssignorCompanies = useCallback(
    async (page: number, rowsPerPage: number, cancelToken?) => {
      try {
        const { rows, count } = await assignorCompanyService.list(
          page,
          rowsPerPage,
          cancelToken
        );

        return {
          rows,
          count,
        };
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar empresas.');
      }
      return null;
    },
    []
  );

  const listAllAssignorCompanies = useCallback(async () => {
    try {
      const { rows } = await assignorCompanyService.list();

      return rows.data;
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar empresas.');
    }

    return null;
  }, []);

  const getCompanyName = (item: ICompany) => `${item?.razaoSocial}`;

  const getCompanyId = (item: ICompany) => `${item?.id}`;

  return {
    listCompanies,
    listAllCompanies,
    listAssignorCompanies,
    listAllAssignorCompanies,
    getCompanyName,
    getCompanyId,
  };
}
