import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 32px 0 0 0;
    border-radius: 5px;
  }

  .logo-full {
    height: 64px;
    /* height: 90px; */
  }

  .logo-small {
    height: 50px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  min-height: 300px;
  min-width: 50vw;
  padding: 40px;
  border-radius: 5px;

  h1 {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.red};
    font-weight: bold;
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 32px 16px;
  border-radius: 5px;
  width: 100%;

  p {
    color: #555;
    font-size: 1.1rem;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 48px;
  color: #fff;
  background: #006699;
  border: none;
  font-weight: 400;
  border-radius: 3px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  border-top: solid 1px rgba(255, 255, 255, 0.03);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #2288bb;
  }

  &:disabled {
    background-color: #555;
  }
`;
