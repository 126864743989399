import React from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';
import TableButtons from '@components/TableButtons';

import { IProfile } from '@models/domain/IProfile';
import { IResponse } from '@models/util/IResponse';

interface Props {
  rowsAmount: number;
  rowsPerPage: number;
  profiles: IResponse<IProfile[]>;
  loadingProfiles?: boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  handleEditProfile(profile: IProfile): void;
  handleDeleteProfile: (profile: IProfile) => void;
}

const ProfilesList: React.FC<Props> = ({
  rowsAmount,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  profiles,
  handleEditProfile,
  handleDeleteProfile,
  loadingProfiles,
}) => {
  return (
    <Table
      setTablePage={setPage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      isLoading={loadingProfiles && !profiles}
      columns={[
        {
          name: 'Ação',
          selector: 'acao',
          width: '85px',
          omit: !(profiles?.acoes.excluir || profiles?.acoes.alterar),
        },
        {
          name: 'Nome',
          selector: 'nome',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.nome} />,
        },
      ]}
      content={profiles?.data.map((profile) => {
        return {
          id: profile.id,
          nome: profile.nome,
          acao: (
            <TableButtons
              id={profile.id}
              acoes={profiles.acoes}
              tableName="Perfil"
              confirmMessage="Tem certeza que deseja deletar o Perfil?"
              submitting={profiles === undefined}
              handleDelete={() => handleDeleteProfile(profile)}
              handleEdit={() => handleEditProfile(profile)}
            />
          ),
        };
      })}
    />
  );
};

export default ProfilesList;
