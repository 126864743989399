import styled from 'styled-components';

import { ButtonSolid } from '@components/Button/styles';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  background: ${(props) => props.theme.colors.white};
  width: 410px;
  border-radius: var(--gap-smaller);
  box-shadow: 0 2px 10px ${(props) => props.theme.colors.boxShadow};
  box-sizing: border-box;
  position: relative;

  form {
    display: flex;
    flex-direction: column;
    padding: var(--gap-bigger);
  }

  ${ButtonSolid} {
    margin-top: var(--gap-bigger);
  }

  .forgot-password {
    background-color: #fff;
    border: none;
    outline: none;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    font-size: var(--gap-medium);
    text-align: right;
    margin-top: var(--gap-big);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Gradient = styled.div`
  width: 100%;
  height: var(--gap-smaller);
  border-radius: var(--gap-smaller) var(--gap-smaller) 0 0;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.gradientPink},
    ${(props) => props.theme.colors.gradientBlue}
  );
`;

export const Logo = styled.img`
  margin: 0 auto;
  margin-bottom: var(--gap-medium);
  width: 150px;
`;
