import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  min-width: 200px;
  width: 100%;
  top: 50px;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: var(--gap-smaller);
  display: flex;
  flex-direction: column;
  padding: var(--gap-small) 0;
  text-decoration: none;
  cursor: default;

  ul {
    li {
      color: ${(props) => props.theme.colors.text};
      transition: background 200ms;
      cursor: pointer;
      display: flex;

      span,
      a {
        padding: var(--gap-medium);
        margin: var(--gap-smaller) 0;
        width: 100%;
      }

      a {
        text-decoration: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .logout {
        color: ${(props) => props.theme.colors.red};
      }
    }
  }
`;
