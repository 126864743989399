import React, { useCallback, useEffect, useState } from 'react';

import Card from '@components/Card';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { useCompanies } from '@hooks/useCompanies';
import { ICompany } from '@models/domain/ICompany';
import { IResponse } from '@models/util/IResponse';

import CompaniesList from './List';

const Companies = () => {
  const { listCompanies } = useCompanies();
  const [companies, setCompanies] = useState<IResponse<ICompany[]>>();
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchCompanies = useCallback(
    async (cancelToken?) => {
      setCompaniesLoading(true);

      try {
        const response = await listCompanies(page, rowsPerPage, cancelToken);

        setCompanies(response?.rows);
        setRowsAmount(response?.count);
      } catch {
        setCompanies((prevState) => ({ ...prevState, data: [] }));
        setRowsAmount(0);
      } finally {
        setCompaniesLoading(false);
      }
    },
    [listCompanies, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    fetchCompanies(source.token);

    return () => {
      source.cancel();
    };
  }, [fetchCompanies]);

  return (
    <Card>
      <header>
        <h1>Lista de Empresas</h1>
      </header>
      <CompaniesList
        companies={companies}
        companiesLoading={companiesLoading}
        setPage={setPage}
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Card>
  );
};

export default Companies;
