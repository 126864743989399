import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Input from '@components/Input';
import SpinnerLoading from '@components/Loading/SpinnerLoading';
import Select from '@components/Select';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { clearObject } from '@helpers/clearObject';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IProfile } from '@models/domain/IProfile';
import { IUser } from '@models/domain/IUser';
import { IResponse } from '@models/util/IResponse';
import { profileService, userService } from '@services/index';

import schema from './schema';

interface Props {
  userSelected?: IUser;
  refreshUsers: () => void;
  setOpenModal(): void;
}

const UserForm: React.FC<Props> = ({
  userSelected,
  setOpenModal,
  refreshUsers,
}) => {
  const [profiles, setProfiles] = useState<IResponse<IProfile[]>>();

  const listProfiles = useCallback(async (cancelToken?) => {
    try {
      const { rows } = await profileService.list(0, 0, cancelToken);

      setProfiles(rows);
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar perfis.');
    }
  }, []);

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listProfiles(source.token);

    return () => {
      source.cancel();
    };
  }, [listProfiles]);

  async function handleSubmit(data) {
    const submitUser = {
      ...data,
      cpf: masks.sanitize.format(data.cpf),
      telefone: masks.sanitize.format(data.telefone),
      celular: masks.sanitize.format(data.celular),
      perfil: {
        id: data.perfilId,
      },
    };

    delete submitUser.perfilId;

    try {
      if (userSelected) {
        await userService.edit(clearObject(submitUser));
      } else {
        await userService.register(clearObject(submitUser));
      }

      toast.success(`Usuário ${userSelected ? 'editado' : 'incluído'}.`);
      refreshUsers();
      setOpenModal();
    } catch (error) {
      handleRequestError(
        error,
        `Erro ao ${userSelected ? 'adicionar' : 'editar'} usuário.`
      );
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      id: userSelected?.id || '',
      perfilId: userSelected?.perfil.id || '',
      nome: userSelected?.nome || '',
      email: userSelected?.email || '',
      cpf: masks.cpf(userSelected?.cpf) || '',
      nascimento: userSelected?.nascimento || '',
      telefone: masks.telephone(userSelected?.telefone) || '',
      ramal: userSelected?.ramal || '',
      celular: masks.cellphone(userSelected?.celular) || '',
    },
  });

  function handleCpfChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;
    const regex = /([a-zA-Z!@#$%^&*()_+=\\[\]{};':"\\|,<>\\/?~])/;
    value = masks.cpf(value);

    if (value.length === 15 || regex.test(value)) return;

    formik.setFieldValue('cpf', masks.cpf(value));
  }

  function handleTelephoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    const regex = /([a-zA-Z!@#$%^&*_=\\[\]{};':"\\|,.<>\\/?~])/;
    let { value } = e.target;

    if (regex.test(value) || value.length > 18) {
      e.preventDefault();

      return;
    }

    value = value.replace(/[^\d]/g, '');

    if (value.length === 12) {
      value = masks.telephone(value);
    }

    formik.setFieldValue('telefone', masks.telephone(value));
  }

  function handleCellphoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    const regex = /([a-zA-Z!@#$%^&*_=\\[\]{};':"\\|,.<>\\/?~])/;
    let { value } = e.target;

    if (regex.test(value) || value.length > 19) {
      e.preventDefault();

      return;
    }

    value = value.replace(/[^\d]/g, '');

    if (value.length === 13) {
      value = masks.cellphone(value);
    }

    formik.setFieldValue('celular', masks.cellphone(value));
  }

  if (profiles === undefined) return <SpinnerLoading />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Select
          label="Perfil *"
          name="perfilId"
          onBlur={formik.handleBlur}
          value={formik.values.perfilId}
          onChange={(e) => formik.setFieldValue('perfilId', e)}
          hasError={!!(formik.touched.perfilId && formik.errors.perfilId)}
          errorText={formik.touched.perfilId && formik.errors.perfilId}
          list={profiles?.data.map((profile) => ({
            text: profile.nome,
            id: profile.id,
          }))}
        />

        <Input
          name="nome"
          label="Nome *"
          type="text"
          placeholder="ex: Fulano da Silva"
          value={formik.values.nome}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.nome && formik.errors.nome)}
          errorText={formik.touched.nome && formik.errors.nome}
        />
        <Input
          label="Email *"
          type="text"
          name="email"
          placeholder="ex: email@exemplo.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.email && formik.errors.email)}
          errorText={formik.touched.email && formik.errors.email}
        />
        <Input
          label="CPF *"
          type="text"
          name="cpf"
          placeholder="ex: 000.000.000-00"
          value={formik.values.cpf}
          onChange={handleCpfChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.cpf && formik.errors.cpf)}
          errorText={formik.touched.cpf && formik.errors.cpf}
        />
        <Input
          label="Data de Nascimento *"
          name="nascimento"
          type="date"
          value={masks.date.format(formik.values.nascimento)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.nascimento && formik.errors.nascimento)}
          errorText={formik.touched.nascimento && formik.errors.nascimento}
        />
        <Input
          label="Telefone"
          type="text"
          name="telefone"
          placeholder="ex: +00 00 0000-0000"
          value={formik.values.telefone}
          onChange={handleTelephoneChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.telefone && formik.errors.telefone)}
          errorText={formik.touched.telefone && formik.errors.telefone}
        />
        <Input
          label="Ramal"
          type="text"
          name="ramal"
          placeholder="ex: 00000"
          value={formik.values.ramal}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.ramal && formik.errors.ramal)}
          errorText={formik.touched.ramal && formik.errors.ramal}
        />
        <Input
          label="Celular"
          type="text"
          name="celular"
          placeholder="ex: +00 00 00000-0000"
          value={formik.values.celular}
          onChange={handleCellphoneChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.celular && formik.errors.celular)}
          errorText={formik.touched.celular && formik.errors.celular}
        />
      </div>
      <div className="buttons">
        <Button
          disabled={formik.isSubmitting || !formik.dirty}
          color="warning"
          onClick={() => formik.resetForm()}
        >
          Limpar
        </Button>
        <Button disabled={formik.isSubmitting || !formik.dirty} type="submit">
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
