import React from 'react';

import Footer from './Footer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Container, Content } from './styles';

const MainLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <Navbar />
      <Sidebar />
      <Content className="main-content">
        {children}
        <Footer />
      </Content>
    </Container>
  );
};

export default MainLayout;
