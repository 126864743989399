import React from 'react';

import { Container } from './styles';

interface Props {
  title: string;
  confirmButton: string;
  confirmText: string;
  close(): void;
  action(): void;
}

const ConfirmAlert: React.FC<Props> = ({
  title,
  close,
  action,
  confirmButton,
  confirmText,
}) => {
  function handleConfirm() {
    close();
    action();
  }

  return (
    <Container>
      <div className="confirm-wrapper">
        <h1>{title}</h1>
        <p>{confirmText}</p>
        <div className="buttons">
          <button onClick={close} type="button">
            Cancelar
          </button>

          <button onClick={handleConfirm} type="button" className="confirm">
            {confirmButton}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default ConfirmAlert;
