import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@components/Button';
import { Icons } from '@components/Dictionaries/Icons';
import Input from '@components/Input';

import { viService } from '@services/index';

import { ErrorsContainer, Form, InputButtons } from './styles';

interface Props {
  setOpenModal(state: boolean): void;
  refreshVIS(): void;
}

function Errors(errors) {
  function handleMessage(message: string) {
    if (message.includes('-- ') && message.includes(':')) {
      const newMessage = message.split('-- ');
      newMessage.shift();

      const finalMessage = newMessage.map((newM) => newM.split(': ')[1]);

      return finalMessage;
    }
    return message;
  }

  function handleError(error) {
    return (
      <>
        {error.map((message: string, index: number) => {
          return (
            <span key={`${message + index * 5}`}>
              {handleMessage(message)}

              {error.length - 1 !== index && !message.includes('.') && ', '}
            </span>
          );
        })}
      </>
    );
  }

  return (
    <ErrorsContainer>
      <h2>Erros na importação</h2>
      {errors?.errors?.map((error) => {
        return (
          <li key={error.linha}>
            Linha {error.linha}: {handleError(error.erros)}
          </li>
        );
      })}
    </ErrorsContainer>
  );
}

const VIImport: React.FC<Props> = ({ setOpenModal, refreshVIS }) => {
  const inputFileRef = useRef<HTMLInputElement>();
  const [formData, setFormData] = useState<File>(null);
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  async function handleSubmit() {
    if (!formData) return;

    try {
      await viService.importFromFile(formData);

      toast.success('VI Importado com sucesso.');
      refreshVIS();
      setOpenModal(false);
    } catch (error) {
      refreshVIS();

      if (error.response?.data.mensagens || error.response?.data.dados) {
        toast.error(
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => setShowErrors(true)}
          >
            Erro na importação de VIs. Clique aqui para mais detalhes
          </div>
        );
        setErrors(error.response.data.dados);
      } else if (typeof error.response?.data === 'string') {
        toast.error(error.response.data);
      } else {
        toast.error('Erro na importação de VIs.');
      }
    }
  }

  function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData(e.target.files[0]);
  }

  function resetErrors() {
    setShowErrors(false);
    setErrors(null);
    setFormData(null);
  }

  return (
    <Form>
      <div className="file form-wrapper">
        {showErrors ? (
          <Errors errors={errors} />
        ) : (
          <>
            <Input
              label="Escolher arquivo"
              value={formData?.name || ''}
              placeholder="Apenas arquivos no formato JSON ou XLSX"
              readOnly
              hasError={errors}
            />
            <InputButtons>
              <Button
                link
                linkTo="/import-models/vi-modelo-importacao.json"
                linkIsDownload
              >
                <Icons.Download />
                Modelo JSON
              </Button>
              <Button
                link
                linkTo="/import-models/vi-modelo-importacao.xlsx"
                linkIsDownload
              >
                <Icons.Download />
                Modelo XLSX
              </Button>
              <label className="file-button" htmlFor="select-import">
                <Button onClick={() => inputFileRef.current.click()} flex>
                  <Icons.Search />
                  Procurar
                </Button>
                <input
                  type="file"
                  id="select-import"
                  name="select-import"
                  style={{ display: 'none' }}
                  ref={inputFileRef}
                  onChange={handleFileSelect}
                />
              </label>
            </InputButtons>
          </>
        )}
      </div>
      <div className="buttons">
        {!showErrors ? (
          <>
            <Button color="warning" outline onClick={() => setOpenModal(false)}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit} disabled={!formData}>
              Importar
            </Button>
          </>
        ) : (
          <Button onClick={resetErrors}>Voltar</Button>
        )}
      </div>
    </Form>
  );
};

export default VIImport;
