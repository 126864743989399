import { AxiosInstance, CancelToken } from 'axios';

import { IVI, IVIFilter, IVITotalBalance } from '@models/domain/IVI';
import IList from '@models/util/IList';
import { IResponse } from '@models/util/IResponse';

import { IVIService } from './interfaces/IVIService';

const ENDPOINT = '/api/v1/vis';

export class VIService implements IVIService {
  constructor(private api: AxiosInstance) {}

  async list(
    page: number,
    amount = 10,
    filters?: IVIFilter,
    selectFilter?: string,
    cancelToken?: CancelToken
  ) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
      ...filters,
    };

    if (filters?.empresa?.razaoSocial) {
      params['empresa.razaoSocial'] = filters?.empresa?.razaoSocial;
    }

    if (filters?.empresaCedente?.razaoSocial) {
      params['empresaCedente.razaoSocial'] =
        filters?.empresaCedente?.razaoSocial;
    }

    if (selectFilter === 'saldoAConciliar') {
      params.saldoInicial = String(0.1);
    }

    if (selectFilter === 'totalmenteConciliados') {
      params.saldoInicial = String(0);
      params.saldoFinal = String(0);
    }

    delete params.empresa;
    delete params.empresaCedente;

    const { data, headers } = await this.api.get<IResponse<IVI[]>>(ENDPOINT, {
      params,
      cancelToken,
    });

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<IVI[]>;
  }

  async totalBalance(cancelToken: CancelToken) {
    const { data } = await this.api.get<IVITotalBalance>(
      `${ENDPOINT}/saldo-total`,
      {
        cancelToken,
      }
    );

    return data;
  }

  async get(id: string) {
    const { data } = await this.api.get<IResponse<IVI>>(`${ENDPOINT}/${id}`);

    return data;
  }

  async importFromFile(file: File) {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await this.api.post(
      `${ENDPOINT}/importa-arquivo`,
      formData
    );

    return data;
  }

  async conciliationVIs(
    page: number,
    amount = 10,
    companyId: string,
    assignorCompanyId?: string,
    conciliacaoId?: string,
    cancelToken?: CancelToken
  ) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
      empresaId: companyId || undefined,
      empresaCedenteId: assignorCompanyId || undefined,
      conciliacaoId: conciliacaoId || undefined,
    };

    const { data, headers } = await this.api.get(`${ENDPOINT}/conciliacoes`, {
      params,
      cancelToken,
    });

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    };
  }

  async register(vi: IVI) {
    const { data } = await this.api.post<IVI>(`${ENDPOINT}/`, vi);

    return data;
  }

  async update(vi: IVI) {
    const { data } = await this.api.put<IVI>(`${ENDPOINT}/`, vi);

    return data;
  }

  async delete(id: string) {
    await this.api.delete(`${ENDPOINT}/${id}`);
  }
}
