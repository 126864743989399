import * as yup from 'yup';

import { cnpjValidator, cpfValidator } from '@helpers/validators';

const schema = yup.object().shape({
  nomeDestino: yup.string().required('Nome é um campo obrigatório'),
  bancoDestino: yup.string().required('Banco é um campo obrigatório'),
  agenciaDestino: yup.string().required('Agência é um campo obrigatório'),
  contaDestino: yup.string().required('Conta Corrente é um campo obrigatório'),
  tipoDocumento: yup.string().required('Tipo Documento é um campo obrigatório'),
  cpfCnpjDestino: yup
    .string()
    .required('Documento é um campo obrigatório')
    .when('tipoDocumento', {
      is: 'cpf',
      then: yup.string().test({
        name: 'cpf',
        exclusive: true,
        message: 'CPF Inválido.',
        test: (value) => value && cpfValidator(value),
      }),
      otherwise: yup.string().test({
        name: 'cnpj',
        exclusive: true,
        message: 'CNPJ Inválido.',
        test: (value) => value && cnpjValidator(value),
      }),
    }),
});

export default schema;
