import { decimal } from './intl';

const isEmpty = (value: string) =>
  !value || !value.trim() || value === undefined;

export const masks = {
  telephone(number: string) {
    if (isEmpty(number)) return '';
    return number.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
  },

  cellphone(number: string) {
    if (isEmpty(number)) return '';
    return number.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
  },

  password(value: string) {
    if (isEmpty(value)) return '';

    return value.replace(/./g, '*');
  },

  decimal(value: string) {
    if (!value) return '';
    const numbers = +value.replace(/\D/g, '') / 100;
    return decimal.format(numbers);
  },

  cpf(value: string) {
    let maskedValue = value;

    if (isEmpty(maskedValue)) {
      return maskedValue;
    }

    if (maskedValue.length === 11 || maskedValue.length === 14) {
      maskedValue = maskedValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
    } else if (maskedValue.length > 10) {
      maskedValue = maskedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      maskedValue = maskedValue.replace(/(\d{3})(\d)/, '$1.$2');
    }

    return maskedValue;
  },

  cnpj(value: string) {
    let maskedValue = value;

    if (isEmpty(maskedValue)) {
      return maskedValue;
    }

    if (maskedValue.length === 14) {
      // Length === 14 caso a pessoa copie e cole algum cnpj
      maskedValue = maskedValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    } else if (maskedValue.length > 12) {
      maskedValue = maskedValue.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    } else if (maskedValue.length > 10) {
      maskedValue = maskedValue.replace(/(\d{3})(\d{1,2})$/, '$1/$2');
    } else if (maskedValue.length > 3) {
      maskedValue = maskedValue.replace(/(\d{3})(\d)/, '$1.$2');
    } else {
      maskedValue = maskedValue.replace(/(\d{2})(\d)/, '$1.$2');
    }

    return maskedValue;
  },

  hour(value: string) {
    if (isEmpty(value)) {
      return value;
    }

    let v = value;

    if (Number.isNaN(Number(v[v.length - 1]))) return v.slice(0, v.length - 1);

    if (v.length === 4) {
      v = v.replace(/(\d{2})(\d{2})/, '$1:$2');
    }

    return v;
  },

  currency: {
    format(value: number) {
      return decimal.format(value);
    },

    string(value: number | string) {
      let currency = '';

      if (typeof value === 'string') currency = `R$ ${value}`;
      else currency = `R$ ${this.format(value || 0)}`;

      return currency;
    },
  },

  integer(value: string) {
    const maskedValue = value.replace(/\D/g, '');

    return +maskedValue;
  },

  bankAccount(value: string) {
    if (isEmpty(value)) return '';

    if (value.length < 2) return value;

    const digito = value.slice(-1);
    const conta = value.slice(0, -1);

    return `${conta}-${digito}`;
  },

  keyControl(
    key: string,
    list: unknown[],
    query: string,
    getDisplayName: (item: unknown) => string,
    setOpenList: (item: boolean) => void,
    onChange: (item: unknown) => void,
    setItemSelected: (item: unknown) => void
  ) {
    const findItem = list?.find((item) => getDisplayName(item) === query);
    let index = key === 'ArrowUp' ? list.length - 1 : key === 'ArrowDown' && 0;

    if (findItem !== undefined) {
      index =
        key === 'ArrowUp'
          ? list.indexOf(findItem) - 1
          : list.indexOf(findItem) + 1;

      if (
        (key === 'ArrowUp' && index < 0) ||
        (key === 'ArrowDown' && index === list.length)
      ) {
        index = key === 'ArrowUp' ? list.length - 1 : 0;
      }
    }

    if (key === 'Enter') {
      setOpenList(false);

      return;
    }

    setItemSelected(getDisplayName(list[index]));
    onChange(list[index]);
  },

  date: {
    format(date: string) {
      if (isEmpty(date)) return '';
      return new Date(date).toISOString().split('T')[0];
    },

    string(date: string) {
      return new Date(date).toLocaleDateString();
    },

    formatWithHours(date: string) {
      const newDate = new Date(date);

      return `${newDate.toLocaleDateString()} - ${newDate.toLocaleTimeString()}`;
    },
  },

  sanitize: {
    format(value: string) {
      return value?.replace(/[^\d]/g, '');
    },

    number(value: string) {
      return +value
        .replace(/[^0-9.,]/, '')
        .replaceAll('.', '')
        .replace(',', '.');
    },
  },

  filterNumber(value: string) {
    return value.replace('.', '').replace(',', '.');
  },

  handleDocumentType(document: string) {
    const documentLength = masks.sanitize.format(document)?.length;

    if (documentLength === 11) return 'cpf';
    if (documentLength === 14) return 'cnpj';

    return '';
  },

  handleDocument(document: string) {
    const type = this.handleDocumentType(document);

    if (type === 'cpf') {
      return masks.cpf(document);
    }
    if (type === 'cnpj') {
      return masks.cnpj(document);
    }

    return '';
  },
};
