import * as yup from 'yup';

import { cnpjValidator, cpfValidator } from '@helpers/validators';

const schema = yup.object().shape({
  id: yup.string(),
  tipoTransacao: yup
    .number()
    .min(1, 'Selecione um Tipo de Transação')
    .required('Tipo de transação é obrigatório.'),
  data: yup.string().required('O campo Data é obrigatório.'),
  tipo: yup
    .number()
    .min(1, 'Selecione um Tipo de Remetente')
    .required('O campo Tipo do Remetente é obrigatório.'),
  razaoSocial: yup.string().when('tipo', {
    is: 3,
    then: yup.string().notRequired(),
    otherwise: yup.string().required('O campo Razão Social é obrigatório.'),
  }),
  tipoDocumento: yup.string().required('Tipo Documento é um campo obrigatório'),
  cnpj: yup.string().when('tipo', {
    is: 3,
    then: yup.string().notRequired(),
    otherwise: yup
      .string()
      .required('Documento é um campo obrigatório')
      .when('tipoDocumento', {
        is: 'cpf',
        then: yup.string().test({
          name: 'cpf',
          exclusive: true,
          message: 'CPF Inválido.',
          test: (value) => value && cpfValidator(value),
        }),
        otherwise: yup.string().test({
          name: 'cnpj',
          exclusive: true,
          message: 'CNPJ Inválido.',
          test: (value) => value && cnpjValidator(value),
        }),
      }),
  }),
  contaBancaria: yup.string().required('O campo Conta Bancária é obrigatório.'),
  valor: yup
    .string()
    .required('O campo Valor é obrigatório.')
    .matches(RegExp(/^\$?\d+(.\d{3})*(,\d*)?$/), 'Valor inválido.'),
  observacao: yup.string(),
  complemento: yup.string(),
  contaEscrow: yup.boolean(),
  empresa: yup
    .object()
    .shape({
      razaoSocial: yup.string(),
      id: yup.string().required('O campo Empresa é obrigatório.'),
    })
    .required(),
  empresaCedente: yup
    .object()
    .shape({
      razaoSocial: yup.string(),
      id: yup.string(),
    })
    .when('contaEscrow', {
      is: true,
      then: yup.object().shape({
        razaoSocial: yup.string(),
        id: yup.string().required('O campo Empresa Cedente é obrigatório.'),
      }),
      otherwise: yup.object().shape({
        razaoSocial: yup.string(),
        id: yup.string(),
      }),
    }),
});

export default schema;
