import styled from 'styled-components';

interface Props {
  hasError?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: var(--gap-small) 0;

  div {
    display: flex;
    align-items: center;

    label {
      font-size: var(--fsz-small);
      font-weight: bold;
      display: flex;
      align-items: center;

      ${(props) => props.hasError && `color: ${props.theme.colors.red}`};
    }

    input {
      margin-left: var(--fsz-small);
    }
  }

  .input-error {
    font-size: var(--fsz-small);
    color: ${(props) => props.theme.colors.red};
    margin-top: 5px;
    max-width: 500px;
    word-wrap: break-word;
    white-space: normal;
  }
`;
