import React, { useCallback, useEffect, useState } from 'react';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { IConciliationTotalBalance } from '@models/domain/IConciliation';
import { IVITotalBalance } from '@models/domain/IVI';
import { conciliationService, viService } from '@services/index';

import Conciliations from '../Conciliations';
import IdentifyValues from '../IdentifyValues';
import Resume from './Resume';
import { Container, Totals } from './styles';

const Dashboard: React.FC = () => {
  const [viTotalBalance, setViTotalBalance] = useState<IVITotalBalance>();
  const [
    conciliationTotalBalance,
    setConciliationTotalBalance,
  ] = useState<IConciliationTotalBalance>();

  const loadVisTotalBalance = useCallback(async (cancelToken?) => {
    try {
      const response = await viService.totalBalance(cancelToken);

      setViTotalBalance(response);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  const loadConciliationsTotalBalance = useCallback(async (cancelToken?) => {
    try {
      const response = await conciliationService.totalBalance(cancelToken);

      setConciliationTotalBalance(response);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    const source = axiosCancelRequestSource();

    loadVisTotalBalance(source.token);

    return () => {
      source.cancel();
    };
  }, [loadVisTotalBalance]);

  useEffect(() => {
    const source = axiosCancelRequestSource();

    loadConciliationsTotalBalance(source.token);

    return () => {
      source.cancel();
    };
  }, [loadConciliationsTotalBalance]);

  return (
    <Container>
      <Totals>
        <Resume
          number={viTotalBalance?.quantidade || 0}
          title="Valores a Identificar"
          value={viTotalBalance?.saldoTotal ?? 0}
          to="/vi"
          loading={viTotalBalance === undefined}
        />

        <Resume
          number={conciliationTotalBalance?.quantidade || 0}
          title="Conciliações em andamento"
          value={conciliationTotalBalance?.somatorio ?? 0}
          to="/conciliacoes"
          loading={conciliationTotalBalance === undefined}
        />
      </Totals>
      <IdentifyValues headerButtons={false} tableRowsPerPageDefault={5} />
      <Conciliations headerButtons={false} tableRowsPerPageDefault={5} />
    </Container>
  );
};

export default Dashboard;
