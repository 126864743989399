import React, { useCallback, useEffect, useState } from 'react';

import Button from '@components/Button';
import Card from '@components/Card';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { useAuth } from '@hooks/useAuth';
import { useProfiles } from '@hooks/useProfiles';
import { IProfile } from '@models/domain/IProfile';
import { IResponse } from '@models/util/IResponse';

import ProfileForm from './Form';
import ProfilesList from './List';

const Profiles = () => {
  const { permissions } = useAuth();
  const { listProfiles, deleteProfile } = useProfiles();
  const [profiles, setProfiles] = useState<IResponse<IProfile[]>>();
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<IProfile>();

  const fetchProfiles = useCallback(
    async (cancelToken?) => {
      setLoadingProfiles(true);

      try {
        const response = await listProfiles(page, rowsPerPage, cancelToken);

        setProfiles(response?.rows);
        setRowsAmount(response?.count);
      } catch {
        setProfiles((prevState) => ({ ...prevState, data: [] }));
        setRowsAmount(0);
      } finally {
        setLoadingProfiles(false);
      }
    },
    [listProfiles, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    fetchProfiles(source.token);

    return () => {
      source.cancel();
    };
  }, [fetchProfiles]);

  function handleEditProfile(profile: IProfile) {
    setOpenEdit(true);
    setSelectedProfile(profile);
  }

  async function handleDeleteProfile(profile: IProfile) {
    await deleteProfile(profile);

    await fetchProfiles();
  }

  return (
    <Card>
      <header>
        <h1>Lista de Perfis</h1>
        {permissions?.profiles?.createAndUpdate && (
          <Button onClick={() => setOpenNew(true)} disabled={loadingProfiles}>
            Novo
          </Button>
        )}
      </header>
      <ProfilesList
        handleEditProfile={handleEditProfile}
        handleDeleteProfile={handleDeleteProfile}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        profiles={profiles}
        loadingProfiles={loadingProfiles}
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
      />
      {openNew && (
        <Modal title="Novo Perfil" setOpenModal={setOpenNew}>
          <ProfileForm
            closeModal={() => setOpenNew(false)}
            refreshProfiles={fetchProfiles}
          />
        </Modal>
      )}
      {openEdit && (
        <Modal title="Editar Perfil" setOpenModal={setOpenEdit}>
          <ProfileForm
            selectedProfile={selectedProfile}
            closeModal={() => setOpenEdit(false)}
            refreshProfiles={fetchProfiles}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Profiles;
