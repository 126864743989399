import React from 'react';

import { Container } from './styles';

interface Props {
  label: string;
  name: string;
  onChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void;
  value?: string;
  defaultValue?: string;
  onBlur?(e: React.FocusEvent<HTMLTextAreaElement>): void;
}

const Textarea: React.FC<Props> = ({
  label,
  name,
  onChange,
  value,
  defaultValue,
  onBlur,
}) => {
  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <textarea
        name={name}
        id={name}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        onBlur={onBlur}
      />
    </Container>
  );
};

export default Textarea;
