import React from 'react';

import { generateTooltipId } from '@helpers/generateTooltipId';

import Tooltip from '../Tooltip';
import { Container, Span } from './styles';

interface Props {
  id: string;
  text: string;
}

const SpanTable: React.FC<Props> = ({ id, text }) => {
  return (
    <Container>
      <Span data-tip={text} data-for={generateTooltipId(id, text)}>
        {text}
      </Span>
      <Tooltip id={generateTooltipId(id, text)} />
    </Container>
  );
};

export default SpanTable;
