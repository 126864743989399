import styled from 'styled-components';

type Props = {
  retract?: boolean;
};

export const List = styled.li<Props>`
  padding: 10px 0;
  display: flex;
  overflow: hidden;

  .active {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    span,
    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 10px;
  }

  span {
    font-size: 16px;
    margin-left: 15px;
    ${(props) => props.retract && 'display: none;'}
  }
`;

export const Children = styled.div<Props>`
  margin-left: ${(props) => (props.retract ? '0' : '20px')};

  span {
    color: ${(props) => props.theme.colors.grey};
  }

  .active span {
    color: ${(props) => props.theme.colors.primary};
  }

  svg {
    display: ${(props) => (props.retract ? 'inline-block' : 'none')};
  }
`;
