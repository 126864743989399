import React from 'react';

import Input from '@components/Input';

import { masks } from '@helpers/mask';

import { Container } from './styles';

export const ConciliationForm = ({
  formik,
  setOpenBenefited,
  liquidationType,
  disableDate = false,
}) => {
  function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = masks.decimal(e.target.value);

    formik.handleChange(e);
  }

  return (
    <Container>
      <Input
        label="Tipo Liquidação:"
        name="liquidacao.tipo"
        placeholder="ex: Sacado"
        value={liquidationType}
        onChange={formik.handleChange}
        disabled
      />
      <Input
        label="Pagador:"
        name="liquidacao.pagadorRazaoSocialLiquidacao"
        placeholder="ex: Remetente 1"
        value={formik.values.liquidacao.pagadorRazaoSocialLiquidacao}
        onChange={formik.handleChange}
        disabled
      />
      <Input
        label="Valor Total VIs:"
        name="valor"
        value={masks.currency.string(formik.values.valor)}
        onChange={handleDecimalChange}
        disabled
      />
      <Input
        label="Valor Total dos Títulos:"
        name="somaTitulos"
        value={masks.currency.string(formik.values.somaTitulos)}
        onChange={handleDecimalChange}
        disabled
      />
      <Input defaultValue={formik.values.status} label="Status:" disabled />
      <Input
        label="Devolução TED:"
        name="devolucao.valor"
        value={masks.currency.string(formik.values.devolucao.valor)}
        onChange={handleDecimalChange}
        disabled={formik.values.devolucao.valor <= 0}
      />
      <Input
        label="Beneficiado:"
        name="beneficiado"
        readOnly
        onClick={() => setOpenBenefited(true)}
        value={formik.values.beneficiado}
        onChange={() => {}}
        placeholder="ex: BAN: xxx AG: xxx C/C:xxxxxx-x "
        disabled={formik.values.devolucao.valor <= 0}
        onBlur={formik.handleBlur}
        hasError={!!(formik.touched.beneficiado && formik.errors.beneficiado)}
        errorText={formik.touched.beneficiado && formik.errors.beneficiado}
      />
      <Input
        label="Data Referência:"
        type="date"
        name="data"
        value={masks.date.format(formik.values.data)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        hasError={!!(formik.touched.data && formik.errors.data)}
        errorText={formik.touched.data && formik.errors.data}
        disabled={disableDate}
      />
    </Container>
  );
};
