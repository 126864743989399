import React, { useCallback, useEffect, useState } from 'react';

import Input from '@components/Input';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { SendersEnum } from '@enums/senders.enum';
import { TransactionsEnum } from '@enums/transactions.enum';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IConciliation } from '@models/domain/IConciliation';
import { IConciliationVis, IVI } from '@models/domain/IVI';
import { conciliationService } from '@services/index';

interface Props {
  vis: IVI[];
  conciliation?: IConciliation;
  rowsAmount: number;
  rowsPerPage: number;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
}

const VIConciliations: React.FC<Props> = ({
  vis,
  conciliation,
  setTablePage,
  rowsAmount,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const [loading] = useState(false);
  const [conciliationVis, setConciliationVis] = useState<IConciliationVis[]>();

  const getConciliationVis = useCallback(async (id: string, cancelToken?) => {
    try {
      const { rows } = await conciliationService.listVis(
        id,
        null,
        null,
        cancelToken
      );

      setConciliationVis(rows);
    } catch (error) {
      handleRequestError(
        error,
        'Erro ao carregar VIs',
        'VIs não encontrados para esta conciliação'
      );
    }
  }, []);

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (conciliation?.id) {
      getConciliationVis(conciliation?.id);
    }

    return () => {
      source.cancel();
    };
  }, [getConciliationVis, conciliation]);

  function checkRowId(row: IVI) {
    const vi = !!conciliationVis?.find((item) => item.id === row?.id);

    return vi;
  }

  function findViRow(id: string) {
    const vi = conciliationVis?.find((item) => item.id === id);

    return vi;
  }

  return (
    <Table
      selectableRows
      setTablePage={setTablePage}
      isLoading={vis === undefined || loading}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      selectableRowSelected={(row: IVI) => checkRowId(row)}
      selectableRowDisabled={() => true}
      columns={[
        { name: 'Data', selector: 'data', sortable: true, minWidth: '85px' },
        {
          name: 'Tipo Remetente',
          selector: 'remetente.tipo',
          cell: (row) => <SpanTable id={row.id} text={row.remetente.tipo} />,
        },
        {
          name: 'Remetente',
          selector: 'remetente.razaoSocial',
          cell: (row) => (
            <SpanTable id={row.id} text={row.remetente.razaoSocial} />
          ),
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Empresa Cedente',
          selector: 'empresaCedente',
          sortable: true,
          minWidth: '150px',
          cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
        },
        {
          name: 'Tipo Transação',
          selector: 'tipoTransacao',
          cell: (row) => <SpanTable id={row.id} text={row.tipoTransacao} />,
        },
        {
          name: 'Conta Bancária',
          selector: 'contaBancaria',
          cell: (row) => <SpanTable id={row.id} text={row.contaBancaria} />,
        },
        {
          name: 'Complemento',
          selector: 'complemento',
          cell: (row) => <SpanTable id={row.id} text={row.complemento} />,
        },
        {
          name: 'Valor',
          selector: 'valor',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={masks.currency.string(row.valor)} />
          ),
        },
        {
          name: 'Valor Conciliado',
          selector: 'valorConciliado',
          right: true,
          width: '125px',
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorConciliado)}
            />
          ),
        },
        {
          name: 'Saldo',
          selector: 'saldo',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={masks.currency.string(row.saldo)} />
          ),
        },
        {
          name: 'Valor a Conciliar *',
          selector: 'valorConciliarInput',
          width: '150px',
          allowOverflow: true,
          cell: (row) => (
            <Input
              type="text"
              className="diferencaInput"
              value={masks.currency.string(findViRow(row.id)?.valorAConciliar)}
              onChange={() => {}}
              disabled
            />
          ),
        },
        {
          name: 'Valor Devolução *',
          selector: 'valorADevolverInput',
          width: '150px',
          cell: (row) => (
            <Input
              type="text"
              className="valorADevolverInput"
              value={masks.currency.string(findViRow(row.id)?.valorADevolver)}
              onChange={() => {}}
              disabled
            />
          ),
        },
      ]}
      content={vis?.map((vi) => {
        return {
          id: vi.id,
          data: masks.date.string(vi.data),
          remetente: {
            razaoSocial: vi.remetente.razaoSocial,
            tipo: SendersEnum[vi.remetente.tipo],
            cnpj: vi.remetente.cnpj,
          },
          empresa: vi.empresa.razaoSocial,
          tipoTransacao: TransactionsEnum[vi.tipoTransacao],
          contaBancaria: vi.contaBancaria,
          complemento: vi.complemento || '-',
          empresaCedente: vi.empresaCedente?.razaoSocial ?? '-',
          valor: vi.valor,
          saldo: vi.saldo,
          valorConciliado: vi.valorConciliado,
          valoresAIdentificarId: '',
          valorConciliar: vi.saldo,
          devolucao: vi?.devolucao?.valor ?? 0,
          valorADevolver: vi.valorADevolver,
        };
      })}
    />
  );
};

export default VIConciliations;
