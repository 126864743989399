import React from 'react';

import { Container } from './styles';

interface Props {
  label: string;
  value: string | number | boolean;
  currency?: boolean;
  checkbox?: boolean;
}

const Detail: React.FC<Props> = ({ label, value, currency, checkbox }) => {
  return (
    <Container>
      <h2>{label}:</h2>
      {!checkbox ? (
        <p>
          {currency && <span>R$</span>}
          {value || '-'}
        </p>
      ) : (
        <input type="checkbox" checked={!!value} readOnly />
      )}
    </Container>
  );
};

export default Detail;
