export function sortByPosition<T>(
  items: T[],
  getPositionValue: (item: T) => number
): T[] {
  const sortedItem = items?.sort((a: T, b: T) => {
    const positionA = getPositionValue(a);
    const positionB = getPositionValue(b);

    if (positionA === 0) return -1;

    if (positionA > positionB) return 1;

    if (positionA < positionB) return -1;

    return 0;
  });

  return sortedItem?.length > 0 ? sortedItem : [];
}
