import React, { useCallback, useEffect, useState } from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { TitleStatusEnum } from '@enums/titleStatus.enum';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { ITitle } from '@models/domain/ITitle';
import { conciliationService } from '@services/index';

import { Container } from '../styles';

const ListTitles = ({ conciliationId }) => {
  const [titles, setTitles] = useState<ITitle[]>();
  const [page, setPage] = useState(1);
  const [amount, setAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const listTitles = useCallback(
    async (cancelToken?) => {
      try {
        const { rows, count } = await conciliationService.listTitles(
          conciliationId,
          page,
          rowsPerPage,
          cancelToken
        );

        setTitles(rows.data);
        setAmount(count);
      } catch (error) {
        setTitles([]);
        handleRequestError(
          error,
          'Erro ao listar títulos',
          'Nenhum título associado com esta conciliação'
        );
      }
    },
    [conciliationId, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listTitles(source.token);

    return () => {
      source.cancel();
    };
  }, [listTitles]);

  if (titles === undefined || titles.length <= 0) return null;

  return (
    <Container>
      <h2>Títulos Associados</h2>
      <Table
        pagination
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        rowsAmount={amount}
        setTablePage={setPage}
        columns={[
          {
            name: 'Título ID',
            selector: 'tituloId',
            width: '75px',
            cell: (row) => <SpanTable id={row.id} text={row.tituloId} />,
          },
          {
            name: 'Número Título',
            selector: 'numeroTitulo',
            width: '115px',
            cell: (row) => <SpanTable id={row.id} text={row.numeroTitulo} />,
          },
          {
            name: 'Vencimento',
            selector: 'vencimento',
            cell: (row) => (
              <SpanTable id={row.id} text={masks.date.string(row.vencimento)} />
            ),
          },
          {
            name: 'Cedente',
            selector: 'cedente',
            cell: (row) => <SpanTable id={row.id} text={row.cedente} />,
          },
          {
            name: 'Sacado',
            selector: 'sacado',
            cell: (row) => <SpanTable id={row.id} text={row.sacado} />,
          },
          {
            name: 'Status',
            selector: 'status',
            minWidth: '25px',
            cell: (row) => (
              <SpanTable id={row.id} text={TitleStatusEnum[row.status]} />
            ),
          },
          {
            name: 'Valor',
            selector: 'valor',
            right: true,
            cell: (row) => (
              <SpanTable id={row.id} text={masks.currency.string(row.valor)} />
            ),
          },
          {
            name: 'Deduções',
            selector: 'deducoes',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.deducoes)}
              />
            ),
          },
          {
            name: 'Valor Residual',
            selector: 'valorResidual',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.valorResidual)}
              />
            ),
          },
          {
            name: 'Diferença Inicial',
            selector: 'diferenca',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.diferenca)}
              />
            ),
          },
          {
            name: 'Valor Final',
            selector: 'valorFinal',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.valorFinal)}
              />
            ),
          },
          {
            name: 'Diferença',
            selector: 'diferencaInput',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.diferencaInput)}
              />
            ),
          },
          {
            name: 'Valor Quitado',
            selector: 'valorQuitado',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.valorQuitado)}
              />
            ),
          },
          {
            name: 'Deduzido Face',
            selector: 'deduzidoFace',
            minWidth: '115px',
            right: true,
            cell: (row) => (
              <SpanTable
                id={row.id}
                text={masks.currency.string(row.deduzidoFace)}
              />
            ),
          },
          {
            name: 'Quitação Parcial',
            selector: 'quitacaoParcial',
            center: true,
            cell: (row: ITitle) => (
              <input type="checkbox" checked={row.quitacaoParcial} disabled />
            ),
          },
        ]}
        content={titles?.map((title) => {
          return {
            id: title.id,
            tituloId: title.tituloId,
            numeroTitulo: title.numeroTitulo,
            vencimento: title.vencimento,
            cedente: title.cedente,
            sacado: title.sacado,
            status: title.status || '-',
            valor: title.valor,
            deducoes: title.deducoes,
            valorResidual: title.valorResidual,
            diferenca: title.diferenca,
            valorFinal: title.valorFinal,
            diferencaInput: title.diferencaInput,
            valorQuitado: title.valorQuitado,
            deduzidoFace: title.deduzidoFace,
            quitacaoParcial: title.quitacaoParcial,
          };
        })}
      />
    </Container>
  );
};

export default ListTitles;
