import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Totals = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-big);
  overflow: auto;

  @media (max-width: 1080px) {
    gap: var(--gap-small);
  }
`;
