import React, { useCallback } from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { masks } from '@helpers/mask';
import { IAudit } from '@models/domain/IAudit';

interface Props {
  rowsAmount: number;
  rowsPerPage: number;
  audits: IAudit[];
  selected: string[];
  cancelExport: boolean;
  auditsLoading: boolean;
  setSelected(data: string[]): void;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
}

const AuditList: React.FC<Props> = ({
  rowsAmount,
  rowsPerPage,
  setSelected,
  setRowsPerPage,
  setTablePage,
  audits,
  selected,
  cancelExport,
  auditsLoading,
}) => {
  const handleSelected = useCallback(
    ({ selectedRows }) => {
      setSelected(selectedRows.map((item) => item.id));
    },
    [setSelected]
  );

  return (
    <Table
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setTablePage={setTablePage}
      onSelectedRowsChange={handleSelected}
      isLoading={auditsLoading}
      selectableRows
      selectableRowSelected={(row: IAudit) =>
        selected?.find((item) => item === row.id) === row.id
      }
      clearSelectedRows={cancelExport}
      columns={[
        {
          name: 'Usuário',
          selector: 'usuario',
          cell: (row) => <SpanTable id={row.id} text={row.usuario} />,
        },
        {
          name: 'Data/Hora',
          selector: 'dataHora',
          minWidth: '160px',
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.date.formatWithHours(row.dataHora)}
            />
          ),
        },
        {
          name: 'Operação',
          selector: 'operacao',
          cell: (row) => <SpanTable id={row.id} text={row.operacao} />,
        },
        {
          name: 'Campo',
          selector: 'campo',
          cell: (row) => <SpanTable id={row.id} text={row.campo} />,
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Empresa Cedente',
          selector: 'empresaCedente',
          cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
        },
        {
          name: 'Valor',
          selector: 'valor',
          cell: (row) => <SpanTable id={row.id} text={row.valor} />,
        },
      ]}
      content={audits?.map((item: IAudit) => {
        return {
          id: item.id,
          metadataId: item?.metadataId,
          usuario: item?.usuario,
          dataHora: item?.dataHora,
          operacao: item?.operacao,
          campo: item?.campo,
          empresa: item?.empresa ?? '-',
          empresaCedente: item?.empresaCedente ?? '-',
          valor: item?.valor ?? '-',
        };
      })}
    />
  );
};

export default AuditList;
