import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icons } from '@components/Dictionaries/Icons';
import Modal from '@components/Modal';
import Select from '@components/Select';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { masks } from '@helpers/mask';
import { useAudit } from '@hooks/useAudit';
import { IAudit } from '@models/domain/IAudit';

import { Container, LinkToLog } from './styles';

const History = ({ conciliation, setOpenHistory }) => {
  const { listAudit } = useAudit();
  const [audit, setAudit] = useState<IAudit[]>();
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');

  const fetchAudit = useCallback(async () => {
    try {
      const { rows, count } = await listAudit(page, rowsPerPage, {
        metadataId: conciliation,
        campo: filter,
      });

      setAudit(rows);
      setRowsAmount(count);
    } catch {
      return null;
    }

    return null;
  }, [conciliation, filter, listAudit, page, rowsPerPage]);

  useEffect(() => {
    fetchAudit();
  }, [fetchAudit]);

  return (
    <Modal title="Histórico da Conciliação" setOpenModal={setOpenHistory}>
      <Container>
        <LinkToLog>
          <Link to={`/configuracoes/auditoria/${conciliation}`}>
            Ver na página de auditoria <Icons.ArrowRight />
          </Link>
        </LinkToLog>
        <Table
          rowsAmount={rowsAmount}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setTablePage={setPage}
          isLoading={audit === undefined}
          hasSubheader
          subHeaderComponent={
            <Select
              name="filter"
              value={filter}
              onChange={(e) => setFilter(String(e))}
              list={[
                { id: '', text: 'Todos' },
                { id: 'status', text: 'Campo Status' },
              ]}
              placeholder="Filtrar Status"
              onBlur={() => {}}
            />
          }
          columns={[
            {
              name: 'Usuário',
              selector: 'usuario',
              cell: (row) => <SpanTable id={row.id} text={row.usuario} />,
            },
            {
              name: 'Data/Hora',
              selector: 'dataHora',
              minWidth: '160px',
              cell: (row) => (
                <SpanTable
                  id={row.id}
                  text={masks.date.formatWithHours(row.dataHora)}
                />
              ),
            },
            {
              name: 'Operação',
              selector: 'operacao',
              cell: (row) => <SpanTable id={row.id} text={row.operacao} />,
            },
            {
              name: 'Campo',
              selector: 'campo',
              cell: (row) => <SpanTable id={row.id} text={row.campo} />,
            },
            {
              name: 'Empresa',
              selector: 'empresa',
              cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
            },
            {
              name: 'Empresa Cedente',
              selector: 'empresaCedente',
              minWidth: '150px',
              cell: (row) => (
                <SpanTable id={row.id} text={row.empresaCedente} />
              ),
            },
            {
              name: 'Valor',
              selector: 'valor',
              minWidth: '100px',
              cell: (row) => <SpanTable id={row.id} text={row.valor} />,
            },
          ]}
          content={audit?.map((item: IAudit) => {
            return {
              id: item.id,
              metadataId: item?.metadataId,
              usuario: item?.usuario,
              dataHora: item?.dataHora,
              operacao: item?.operacao,
              campo: item?.campo,
              empresa: item?.empresa ?? '-',
              empresaCedente: item?.empresaCedente ?? '-',
              valor: item?.valor ?? '-',
            };
          })}
        />
      </Container>
    </Modal>
  );
};

export default History;
