import styled from 'styled-components';

interface Props {
  hasError: boolean;
}

interface InputProps extends Props {
  disabled: boolean;
  hasFocus: boolean;
  size?: 'large';
  clickable?: boolean;
  currency?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: var(--gap-medium) 0;
  width: 100%;
  max-width: 600px;

  label {
    font-size: var(--fsz-small);
    margin-bottom: var(--gap-small);
    font-weight: bold;

    ${(props) => props.hasError && `color: ${props.theme.colors.red}`};
  }

  .input-error {
    font-size: var(--fsz-small);
    color: ${(props) => props.theme.colors.red};
    margin-top: var(--gap-small);
    max-width: 500px;
    word-wrap: break-word;
    white-space: normal;
  }
`;

export const InputWrapper = styled.div<InputProps>`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 var(--gap-smaller);
  height: ${(props) => (props.size === 'large' ? 49 : 34)}px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey};
  ${(props) => props.clickable && 'cursor: pointer;'}

  ${(props) =>
    props.hasFocus && `border-color:  ${props.theme.colors.primary}`};
  ${(props) => props.hasError && `border-color:  ${props.theme.colors.red};`}
  ${(props) =>
    props.disabled &&
    `
    border-color:  ${props.theme.colors.grey};
    background:  ${props.theme.colors.background};
    `}

  input {
    ${(props) => props.clickable && 'cursor: pointer;'}
    flex: 1;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: var(--fsz-small);
    font-weight: 500;
    line-height: 18px;
    height: 100%;
    padding: 0 var(--gap-small);
    width: 100%;
    text-align: ${(props) => props.currency && 'right'};
  }
`;
