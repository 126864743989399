import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { CancelToken } from 'axios';

import { handleRequestError } from '@helpers/handleRequestError';
import { IProfile } from '@models/domain/IProfile';
import { IListResponse } from '@models/util/IListResponse';
import { profileService } from '@services/index';

interface Hook {
  listProfiles(
    page: number,
    rowsPerPage: number,
    cancelToken?: CancelToken
  ): Promise<IListResponse<IProfile[]>>;
  deleteProfile(profile: IProfile): Promise<void>;
}

export function useProfiles(): Hook {
  const listProfiles = useCallback(
    async (page: number, rowsPerPage: number, cancelToken?) => {
      // função de loading com parametro para que no primeiro loading entre
      // o global e o spinner e nos próximos somente o global
      try {
        const { rows, count } = await profileService.list(
          page,
          rowsPerPage,
          cancelToken
        );

        return {
          rows,
          count,
        };
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar perfis.');
      }

      return null;
    },
    []
  );

  const deleteProfile = useCallback(async (profile: IProfile) => {
    try {
      await profileService.delete(profile.id);

      toast.info('Perfil deletado.');
    } catch (error) {
      handleRequestError(error, 'Erro ao deletar perfil.');
    }
  }, []);

  return {
    listProfiles,
    deleteProfile,
  };
}
