import React, { useCallback, useEffect, useState } from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';
import TableButtons from '@components/TableButtons';

import { getConfigField } from '@helpers/getConfigField';
import { masks } from '@helpers/mask';
import { useUsers } from '@hooks/useUsers';
import { IConfiguration } from '@models/domain/IConfiguration';
import { IUser } from '@models/domain/IUser';

interface Props {
  rowsAmount: number;
  rowsPerPage: number;
  configurations: IConfiguration[];
  configurationsLoading?: boolean;
  setSelected(state: IConfiguration): void;
  setOpenEdit(state: boolean): void;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
}

const ConfigurationsList: React.FC<Props> = ({
  rowsAmount,
  rowsPerPage,
  setSelected,
  setOpenEdit,
  setTablePage,
  setRowsPerPage,
  configurations,
  configurationsLoading,
}) => {
  const { listAllUsers, getUserById } = useUsers();
  const [users, setUsers] = useState<IUser[]>();

  const listUsersCb = useCallback(async () => {
    try {
      const response = await listAllUsers();

      setUsers(response);
    } catch {
      setUsers([]);
    }
  }, [listAllUsers]);

  useEffect(() => {
    listUsersCb();
  }, [listUsersCb]);

  function handleEdit(item) {
    setSelected(item);
    setOpenEdit(true);
  }

  function handleParamValue(name: string, value: string) {
    return getConfigField(name) === 'Senha' ? masks.password(value) : value;
  }

  return (
    <Table
      setTablePage={setTablePage}
      setRowsPerPage={setRowsPerPage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      isLoading={configurationsLoading && !configurations}
      columns={[
        {
          name: 'Ações',
          selector: 'acoes',
          width: '55px',
        },
        {
          name: 'Nome do Parâmetro',
          selector: 'nome',
          cell: (row) => <SpanTable id={row.id} text={row.nome} />,
        },
        {
          name: 'Valor do Parâmetro',
          selector: 'valor',
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={handleParamValue(row.nome, row.valor)}
            />
          ),
        },
        {
          name: 'Horário da Última Atualização',
          selector: 'dataHora',
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.date.formatWithHours(row.dataHora)}
            />
          ),
        },
        {
          name: 'Usuário da Última Atualização',
          selector: 'usuario',
          cell: (row) => <SpanTable id={row.id} text={row.usuario} />,
        },
      ]}
      content={configurations?.map((item) => ({
        id: item.parametroId,
        acoes: (
          <TableButtons
            id={item.parametroId}
            acoes={{ alterar: true, consultar: false }}
            handleEdit={() => handleEdit(item)}
          />
        ),
        nome: item.nome,
        valor: item.valor,
        dataHora: item.dataHora,
        usuario: getUserById(users, item.usuarioId)?.nome,
      }))}
    />
  );
};

export default ConfigurationsList;
