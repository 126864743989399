import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Card from '@components/Card';
import SpinnerLoading from '@components/Loading/SpinnerLoading';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { IConciliation } from '@models/domain/IConciliation';
import { IRouteWithId } from '@models/util/IRouteParams';
import { conciliationService } from '@services/index';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import Steps from './Steps';
import { Wizard } from './styles';
import ThirdStep from './ThirdStep';

interface Props {
  step: number;
}

const ConciliationWizard: React.FC<Props> = ({ step }) => {
  const [currentStep, setCurrentStep] = useState(step);
  const [prevStep] = useState(step - 1);
  const [conciliation, setConciliation] = useState<IConciliation>();
  const { id } = useParams<IRouteWithId>();

  const getConciliation = useCallback(async (conciliationId, cancelToken?) => {
    try {
      const response = await conciliationService.get(
        conciliationId,
        cancelToken
      );
      setConciliation({
        ...response.data,
        empresaId: response.data.empresa.id,
        empresaCedenteId: response.data.empresaCedente?.id,
      });
    } catch (error) {
      handleRequestError(
        error,
        'Erro ao carregar conciliação',
        'Conciliação não encontrada'
      );
    }
  }, []);

  useEffect(() => {
    const source = axiosCancelRequestSource();
    if (id) {
      getConciliation(id, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [getConciliation, id]);

  function checkIsEdit() {
    return !!id;
  }

  if (id && conciliation === undefined) {
    return <SpinnerLoading />;
  }

  return (
    <Card>
      <header>
        <h1>{`${checkIsEdit() ? 'Editar' : 'Nova'}`} Conciliação</h1>
      </header>
      <Wizard>
        <Steps currentStep={currentStep} prevStep={prevStep} />
        <div className="step-wizard">
          {currentStep === 1 && (
            <FirstStep
              setCurrentStep={setCurrentStep}
              setConciliation={setConciliation}
            />
          )}
          {currentStep === 2 && conciliation.empresa !== undefined && (
            <SecondStep
              setCurrentStep={setCurrentStep}
              conciliation={conciliation}
              setConciliation={setConciliation}
            />
          )}
          {currentStep === 3 && conciliation.empresa !== undefined && (
            <ThirdStep
              setCurrentStep={setCurrentStep}
              conciliation={conciliation}
              setConciliation={setConciliation}
            />
          )}
        </div>
      </Wizard>
    </Card>
  );
};

export default ConciliationWizard;
