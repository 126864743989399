import React from 'react';

import { FormikErrors } from 'formik';

import { Container } from './styles';

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorText?: boolean | string | FormikErrors<unknown>;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  onBlur?(e: React.FocusEvent<HTMLDivElement>): void;
}

const Checkbox: React.FC<Props> = ({
  name,
  label,
  disabled,
  hasError,
  errorText,
  checked,
  onChange,
  required,
  onBlur,
}) => {
  return (
    <Container hasError={hasError} onBlur={onBlur}>
      <div>
        <label htmlFor={name}>{label}</label>
        <input
          type="checkbox"
          name={name}
          id={name}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          required={required}
        />
      </div>
      {errorText && <p className="input-error">{errorText}</p>}
    </Container>
  );
};

export default Checkbox;
