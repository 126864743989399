import styled from 'styled-components';

export const Global = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;
