import React from 'react';

import { useMenu } from '@hooks/useMenu';

import { Icons } from '../../Dictionaries/Icons';
import Tooltip from '../../Tooltip';
import { Logo } from './styles';

interface Props {
  retract: boolean;
}

const RetractArrow: React.FC<Props> = ({ retract }) => {
  const menuContext = useMenu();

  function handleRetract(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    menuContext.handleRetract();
  }

  return (
    <>
      <Logo
        retract={menuContext.retract}
        onClick={handleRetract}
        data-tip={`${retract ? 'Expandir' : 'Recolher'} Menu`}
        data-for="retract"
      >
        <Icons.DoubleArrowRight />
      </Logo>
      <Tooltip id="retract" />
    </>
  );
};

export default RetractArrow;
