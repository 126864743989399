import { AxiosInstance, CancelToken } from 'axios';

import { IProfile } from '@models/domain/IProfile';
import IList from '@models/util/IList';
import { IResponse } from '@models/util/IResponse';

import { IProfileService } from './interfaces/IProfileService';

const ENDPOINT = '/api/v1/perfis';

export class ProfileService implements IProfileService {
  constructor(private api: AxiosInstance) {}

  async list(page: number, amount = 10, cancelToken: CancelToken) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IResponse<IProfile[]>>(
      ENDPOINT,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<IProfile[]>;
  }

  async get(id: string, cancelToken?) {
    const { data } = await this.api.get<IResponse<IProfile>>(
      `${ENDPOINT}/${id}`,
      {
        cancelToken,
      }
    );

    return data;
  }

  async register(nome: string, permissoes: number) {
    await this.api.post(`${ENDPOINT}`, { nome, permissoes });
  }

  async edit(id: string, nome: string, permissoes: number) {
    await this.api.put(`${ENDPOINT}/`, {
      id,
      nome,
      permissoes,
    });
  }

  async delete(id: string) {
    await this.api.delete(`${ENDPOINT}/${id}`);
  }
}
