import React from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { masks } from '@helpers/mask';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { IResponse } from '@models/util/IResponse';

interface Props {
  assignorCompanies: IResponse<IAssignorCompany[]>;
  rowsAmount: number;
  rowsPerPage: number;
  assignorLoading: boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
}

const AssignorCompaniesList: React.FC<Props> = ({
  setPage,
  rowsAmount,
  setRowsPerPage,
  rowsPerPage,
  assignorCompanies,
  assignorLoading,
}) => {
  return (
    <Table
      setTablePage={setPage}
      rowsAmount={rowsAmount}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      isLoading={assignorLoading}
      columns={[
        {
          name: 'Razão Social',
          selector: 'razaoSocial',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.razaoSocial} />,
        },
        {
          name: 'CNPJ',
          selector: 'cnpj',
          sortable: true,
          minWidth: '200px',
          cell: (row) => <SpanTable id={row.id} text={row.cnpj} />,
        },
        {
          name: 'Gerente',
          selector: 'gerente',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.gerente} />,
        },
        {
          name: 'Grupo Empresarial',
          selector: 'grupoEmpresarial',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.grupoEmpresarial} />,
        },
        {
          name: 'ID Externo',
          selector: 'idExterno',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.idExterno} />,
        },
      ]}
      content={assignorCompanies?.data.map((company) => {
        return {
          razaoSocial: company.razaoSocial,
          cnpj: masks.cnpj(company.cnpj),
          gerente: company.gerente,
          grupoEmpresarial: company.grupoEmpresarial,
          idExterno: company.idExterno,
        };
      })}
    />
  );
};

export default AssignorCompaniesList;
