import React from 'react';
import DataTable, {
  ConditionalStyles,
  TableColumn,
} from 'react-data-table-component';

import SpinnerLoading from '../Loading/SpinnerLoading';
import { TableContainer, NoContent } from './styles';

interface Props {
  columns: TableColumn[];
  content: unknown[];
  rowsAmount?: number;
  rowsPerPage?: number;
  selectableRows?: boolean;
  hasSubheader?: boolean;
  pagination?: boolean;
  paginationServer?: boolean;
  subHeaderComponent?: unknown;
  conditionalRowStyles?: ConditionalStyles[];
  isLoading?: boolean;
  clearSelectedRows?: boolean;
  setTablePage?(value: number): void;
  setRowsPerPage?(value: number): void;
  onSelectedRowsChange?(state): void;
  selectableRowDisabled?(row: unknown): boolean;
  selectableRowSelected?(row: unknown): boolean;
}

const Table: React.FC<Props> = ({
  columns,
  content,
  rowsAmount,
  rowsPerPage,
  selectableRows,
  selectableRowDisabled,
  pagination = true,
  paginationServer = true,
  setTablePage,
  setRowsPerPage,
  clearSelectedRows,
  onSelectedRowsChange,
  subHeaderComponent,
  selectableRowSelected,
  conditionalRowStyles,
  hasSubheader = false,
  isLoading = false,
}) => {
  return (
    <TableContainer>
      <DataTable
        noHeader
        persistTableHead
        subHeader={hasSubheader && !isLoading}
        subHeaderComponent={subHeaderComponent}
        defaultSortAsc={false}
        noDataComponent={<NoContent>Nenhum dado encontrado</NoContent>}
        columns={columns}
        data={content}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowSelected={(row) =>
          selectableRowSelected && selectableRowSelected(row)
        }
        selectableRowDisabled={selectableRowDisabled}
        pagination={pagination}
        paginationComponentOptions={{
          rowsPerPageText: 'Linhas por página:',
          rangeSeparatorText: 'de',
        }}
        paginationServer={paginationServer}
        paginationServerOptions={{ persistSelectedOnPageChange: true }}
        paginationTotalRows={rowsAmount}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
        onChangePage={(page) => setTablePage(page)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setRowsPerPage(currentRowsPerPage)
        }
        conditionalRowStyles={conditionalRowStyles}
        progressPending={isLoading}
        progressComponent={<SpinnerLoading />}
        clearSelectedRows={clearSelectedRows}
        fixedHeader
        fixedHeaderScrollHeight="500px"
      />
    </TableContainer>
  );
};

export default Table;
