import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import MainLayout from '@components/MainLayout';
import AssignorCompanies from '@pages/AssignorCompanies';
import Audit from '@pages/Audit';
import Companies from '@pages/Companies';
import Conciliations from '@pages/Conciliations';
import ConciliationApprove from '@pages/Conciliations/Approve';
import ConciliationWizard from '@pages/Conciliations/Wizard';
import Configurations from '@pages/Configurations';
import Dashboard from '@pages/Dashboard';
import IdentifyValues from '@pages/IdentifyValues';
import Profiles from '@pages/Profiles';
import Users from '@pages/Users';

import { useAuth } from '@hooks/useAuth';

import PermissionRoute from './PermissionRoute';

const PrivateRoutes = () => {
  const { permissions } = useAuth();

  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact component={Dashboard} />

        <Route path="/vi" exact component={IdentifyValues} />

        <Route path="/conciliacoes" exact component={Conciliations} />

        <Route path="/conciliacoes/criar" exact>
          <ConciliationWizard step={1} />
        </Route>

        <Route path="/conciliacoes/editar/:id" exact>
          <ConciliationWizard step={2} />
        </Route>

        {permissions?.conciliation?.approve && (
          <Route
            path="/conciliacoes/aprovar/:id"
            exact
            component={ConciliationApprove}
          />
        )}

        {permissions?.conciliation?.effectuate && (
          <Route path="/conciliacoes/efetivar/:id" exact>
            <ConciliationApprove isEfetivar />
          </Route>
        )}

        <Route exact path="/configuracoes" component={Configurations} />

        <PermissionRoute
          path="/configuracoes/empresas"
          exact
          component={Companies}
          permission={{
            group: 'Configurações',
            subgroup: 'Empresa',
            functionality: 'Listar Empresas',
          }}
        />

        <PermissionRoute
          path="/configuracoes/empresas-cedentes"
          exact
          component={AssignorCompanies}
          permission={{
            group: 'Configurações',
            subgroup: 'Empresa Cedente',
            functionality: 'Listar Empresas Cedentes',
          }}
        />

        <PermissionRoute
          path="/configuracoes/usuarios"
          exact
          component={Users}
          permission={{
            group: 'Configurações',
            subgroup: 'Usuários',
            functionality: 'Listar Usuários',
          }}
        />

        <PermissionRoute
          path="/configuracoes/perfis"
          exact
          component={Profiles}
          permission={{
            group: 'Configurações',
            subgroup: 'Perfis',
            functionality: 'Listar Perfis',
          }}
        />

        <PermissionRoute
          path="/configuracoes/auditoria"
          exact
          component={Audit}
          permission={{
            group: 'Configurações',
            subgroup: 'Auditoria',
            functionality: 'Auditoria',
          }}
        />

        <PermissionRoute
          path="/configuracoes/auditoria/:metadataId"
          exact
          component={Audit}
          permission={{
            group: 'Configurações',
            subgroup: 'Auditoria',
            functionality: 'Auditoria',
          }}
        />

        <Redirect to="/" />
      </Switch>
    </MainLayout>
  );
};

export default PrivateRoutes;
