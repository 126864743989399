import React from 'react';

import { ButtonSolid, ButtonOutline, ButtonLink } from './styles';

interface ButtonProps {
  type?: 'button' | 'submit';
  size?: 'large';
  disabled?: boolean;
  color?: 'primary' | 'warning' | 'light';
  outline?: boolean;
  flex?: boolean;
  link?: boolean;
  linkTo?: string;
  linkIsDownload?: boolean;
  title?: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>
  ) => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  outline,
  type = 'button',
  color = 'primary',
  disabled,
  size,
  flex,
  link,
  linkTo,
  linkIsDownload,
  onClick,
  title,
}) => {
  if (link) {
    return (
      <ButtonLink
        href={linkTo}
        color={color}
        target={linkIsDownload && '_blank'}
        download={linkIsDownload}
        data-tip={title}
      >
        {children}
      </ButtonLink>
    );
  }

  if (outline) {
    return (
      <ButtonOutline
        onClick={onClick}
        type={type}
        color={color}
        disabled={disabled}
        size={size}
        flex={flex}
        data-tip={title}
      >
        {children}
      </ButtonOutline>
    );
  }

  return (
    <ButtonSolid
      onClick={onClick}
      type={type}
      color={color}
      disabled={disabled}
      size={size}
      flex={flex}
      data-tip={title}
    >
      {children}
    </ButtonSolid>
  );
};

export default Button;
