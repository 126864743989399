import { toast } from 'react-toastify';

function handleErrorMessage(error: string) {
  const splitedErrorDashes = error?.split('--');
  const splitedDoubleDots = splitedErrorDashes[1]?.split(':');

  if (!splitedDoubleDots) {
    return error;
  }

  return splitedDoubleDots[1];
}

export const handleRequestError = (
  error,
  message?: string,
  messageNotFound?: string
) => {
  if (error?.response?.status === 404 && !messageNotFound) {
    return;
  }

  if (error?.response?.status === 404) {
    return toast.error(
      messageNotFound ?? message ?? 'Nenhum resultado encontrado.'
    );
  }

  if (error?.message === undefined) {
    return null;
  }

  if (Array.isArray(error?.response?.data)) {
    return error?.response?.data?.forEach((err) =>
      toast.error(handleErrorMessage(err))
    );
  }

  if (error?.response?.data && typeof error?.response?.data === 'string') {
    return toast.error(handleErrorMessage(error.response?.data));
  }

  return toast.error(
    message ?? 'Alguma coisa deu errado! Tente novamente mais tarde...'
  );
};
