import styled from 'styled-components';

interface Props {
  currentStep: number;
  prevStep: number;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--gap-big);
  overflow: hidden;
  width: 100%;

  .steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 var(--gap-medium);

    .step {
      height: 50px;
      width: 50px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.colors.grey};
      position: relative;
      transition: all 200ms;

      span {
        font-size: 1.5rem;
        color: white;
        font-weight: bold;
        user-select: none;
      }
    }

    p {
      margin-top: var(--gap-medium);
      color: ${(props) => props.theme.colors.grey};
    }
  }

  .done {
    .step,
    .bar {
      background-color: ${(props) => props.theme.colors.secundary};
    }

    p {
      color: ${(props) => props.theme.colors.secundary};
    }
  }

  .active {
    .step,
    .bar {
      background-color: ${(props) => props.theme.colors.primary};
    }

    p {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .bar {
    background-color: ${(props) => props.theme.colors.grey};
    height: 5px;
    width: calc(100% + var(--gap-big));
    position: absolute;
  }

  .right {
    right: 0;
    transform: translateX(100%);
  }

  .left {
    left: 0;
    transform: translateX(-100%);
  }
`;
