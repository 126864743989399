import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { Icons } from '@components/Dictionaries/Icons';

import { Container } from './styles';

const ConciliationErrorText: React.FC = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Icons.Alert
        size={22}
        className="input-alert"
        color={themeContext.colors.red}
      />
      <p>{children}</p>
    </Container>
  );
};

export default ConciliationErrorText;
