import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@components/Button';
import Input from '@components/Input';

import { handleLogo } from '@helpers/handleLogo';
import { handleRequestError } from '@helpers/handleRequestError';
import { useAuth } from '@hooks/useAuth';
import { userService } from '@services/index';

import { Container, Box, Gradient, Logo } from './styles';

const Login = () => {
  const { handleLogin } = useAuth();
  const [formdata, setFormData] = useState({ username: '', password: '' });
  const [submiting, setSubmiting] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (submiting) return;

    const { username, password } = formdata;

    if (!username || !password) {
      toast.error('Os campos Usuário e/ou Senha não podem ser vazios');
      return;
    }

    try {
      setSubmiting(true);

      await handleLogin(username, password);
    } catch (error) {
      toast.error('Usuário e/ou Senha inválidos');

      setSubmiting(false);
    }
  };

  const handleForgotPassword = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (submiting) return;

    if (!formdata.username || !formdata.username.trim()) {
      toast.error('O campo usuário não pode ser vazio');
      return;
    }

    try {
      setSubmiting(true);

      await userService.forgotPassword(formdata.username);

      toast.success('Email de recuperação enviado');
    } catch (error) {
      handleRequestError(
        'Erro ao tentar recuperar senha',
        'Usuário não encontrado'
      );
    } finally {
      setSubmiting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formdata, [name]: value });
  };

  return (
    <Container>
      <Box>
        <Gradient />
        <form onSubmit={handleSubmit}>
          <Logo src={handleLogo()?.full} />

          <Input
            onChange={handleChange}
            value={formdata.username}
            name="username"
            label="Usuário"
            size="large"
          />
          <Input
            onChange={handleChange}
            value={formdata.password}
            name="password"
            type="password"
            label="Senha"
            size="large"
          />

          <Button type="submit" size="large" disabled={submiting}>
            Entrar
          </Button>

          <button
            type="button"
            className="forgot-password"
            onClick={handleForgotPassword}
          >
            Esqueci minha senha
          </button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
