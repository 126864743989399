import { AxiosInstance, CancelToken } from 'axios';

import {
  IConfiguration,
  IConfigurationEdit,
} from '@models/domain/IConfiguration';
import IPaginate from '@models/util/IPaginate';

import { IConfigurationsService } from './interfaces/IConfigurationsService';

const ENDPOINT = '/api/v1/configuracao';

export class ConfigurationsService implements IConfigurationsService {
  constructor(private api: AxiosInstance) {}

  async list(page: number, amount: number, cancelToken?: CancelToken) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IConfiguration[]>(ENDPOINT, {
      params,
      cancelToken,
    });

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IPaginate<IConfiguration[]>;
  }

  async edit(configuration: IConfigurationEdit) {
    await this.api.put(
      // eslint-disable-next-line max-len
      `${ENDPOINT}?parametroId=${configuration.parametroId}&valor=${configuration.valor}&usuarioId=${configuration.usuarioId}`,
      {}
    );
  }
}
