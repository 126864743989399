import React, { useCallback, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { FormikErrors } from 'formik/dist/types';

import { IDomainType } from '@models/domain/IDomainType';

import { Icons } from '../Dictionaries/Icons';
import { Container, List } from './styles';

interface Props {
  label?: string;
  name: string;
  value?: number | string;
  hasError?: boolean;
  errorText?: boolean | string | FormikErrors<unknown>;
  list?: IDomainType[];
  placeholder?: string;
  disabled?: boolean;
  onChange?(e: string | number): void;
  onBlur?(e: React.FocusEvent<HTMLDivElement>): void;
}

const Select: React.FC<Props> = ({
  name,
  label,
  value,
  list,
  placeholder,
  errorText,
  hasError,
  onChange,
  onBlur,
  disabled,
}) => {
  const [openContent, setOpenContent] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const timerToClear = useRef(null);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    document.querySelector('.main-content').addEventListener('scroll', () => {
      if (openContent) setOpenContent(false);
    });
    document.querySelector('.form-wrapper')?.addEventListener('scroll', () => {
      if (openContent) setOpenContent(false);
    });
  }, [openContent]);

  function handleOpenClick() {
    setClientY(containerRef?.current?.getBoundingClientRect().top);
    setClientX(containerRef?.current?.getBoundingClientRect().left);
    setContainerWidth(containerRef?.current?.getBoundingClientRect().width);

    setOpenContent((prevState) => !prevState);
  }

  const getValue = () => {
    const submitValue = list?.find((item) => item.id === value)?.text;

    return submitValue ?? '';
  };

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      e.persist();
      setHasFocus(false);
      timerToClear.current = setTimeout(() => onBlur(e), 100);
    },
    [onBlur]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timerToClear.current);
    };
  }, []);

  const handleOnFocus = () => {
    setHasFocus((prevState) => !prevState);
    handleOpenClick();
  };

  const handleSelect = (item: IDomainType) => {
    setOpenContent(false);
    onChange(item.id);
  };

  const handleCloseContent = () => {
    setOpenContent(false);
  };

  return (
    <Container
      hasError={hasError}
      hasFocus={hasFocus}
      className="select-container"
    >
      {label !== undefined && <label htmlFor={name}>{label}</label>}
      <OutsideClickHandler onOutsideClick={handleCloseContent}>
        <div
          className="select"
          onClick={handleOnFocus}
          onBlur={handleOnBlur}
          id={name}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          ref={containerRef}
        >
          <input
            name={name}
            type="text"
            value={getValue()}
            placeholder={placeholder || 'Selecione uma opção'}
            onChange={() => {}}
            readOnly
            disabled={disabled}
          />
          <Icons.ArrowDownMenu />
        </div>
        {errorText && !openContent && (
          <p className="select-error">{errorText}</p>
        )}
        {openContent && (
          <List
            clientX={clientX}
            clientY={clientY}
            containerWidth={containerWidth}
            className="select-list"
          >
            <ul>
              {list.map((item) => (
                <li key={item.id}>
                  <button type="button" onClick={() => handleSelect(item)}>
                    {item.text}
                  </button>
                </li>
              ))}
            </ul>
          </List>
        )}
      </OutsideClickHandler>
    </Container>
  );
};

export default Select;
