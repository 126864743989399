import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Filter from '@components/Filter';
import Grid from '@components/Grid';
import Input from '@components/Input';
import SpinnerLoading from '@components/Loading/SpinnerLoading';
import Radio from '@components/Radio';
import Select from '@components/Select';
import Textarea from '@components/Textarea';

import { masks } from '@helpers/mask';
import { useCompanies } from '@hooks/useCompanies';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { ICompany } from '@models/domain/ICompany';
import { IDomainType } from '@models/domain/IDomainType';
import { IVIFilter } from '@models/domain/IVI';

interface Props {
  transactions: IDomainType[];
  senders: IDomainType[];
  filters: IVIFilter;
  setSelectFilter(values: string): void;
  setFilters(values: IVIFilter): void;
  setOpenModal(state: boolean): void;
}

const VIFilter: React.FC<Props> = ({
  transactions,
  senders,
  filters,
  setSelectFilter,
  setFilters,
  setOpenModal,
}) => {
  const {
    listAllCompanies,
    listAllAssignorCompanies,
    getCompanyId,
    getCompanyName,
  } = useCompanies();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [assignorCompanies, setAssignorCompanies] = useState<
    IAssignorCompany[]
  >();

  const listAllCompaniesCb = useCallback(async () => {
    const response = await listAllCompanies();

    setCompanies(response);
  }, [listAllCompanies]);

  const listAllAssignorCompaniesCb = useCallback(async () => {
    const response = await listAllAssignorCompanies();

    setAssignorCompanies(response);
  }, [listAllAssignorCompanies]);

  useEffect(() => {
    listAllCompaniesCb();
    listAllAssignorCompaniesCb();
  }, [listAllAssignorCompaniesCb, listAllCompaniesCb]);

  function handleSubmit(data: IVIFilter) {
    const submit = {
      ...data,
      valorInicial: masks.filterNumber(data.valorInicial),
      valorFinal: masks.filterNumber(data.valorFinal),
      valorConciliadoInicial: masks.filterNumber(data.valorConciliadoInicial),
      valorConciliadoFinal: masks.filterNumber(data.valorConciliadoFinal),
      saldoInicial: masks.filterNumber(data.saldoInicial),
      saldoFinal: masks.filterNumber(data.saldoFinal),
    };

    delete submit.tipoDocumento;

    setFilters(submit);
    setSelectFilter('todos');
    setOpenModal(false);
  }

  const { setValues, ...formik } = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      dataInicio: '',
      dataFim: '',
      valorInicial: '',
      valorFinal: '',
      empresa: {
        razaoSocial: '',
        id: '',
      },
      empresaCedente: {
        razaoSocial: '',
        id: '',
      },
      tipoTransacao: '',
      contaBancaria: '',
      complemento: '',
      tipoRemetente: '',
      razaoSocialRemetente: '',
      tipoDocumento: '',
      cnpjRemetente: '',
      valorConciliadoInicial: '',
      valorConciliadoFinal: '',
      saldoInicial: '',
      saldoFinal: '',
      observacao: '',
      contaEscrow: '',
    },
  });

  useEffect(() => {
    if (filters ?? false) {
      setValues({
        ...filters,
        tipoDocumento: masks.handleDocumentType(filters.cnpjRemetente),
      });
    }
  }, [filters, setValues]);

  function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = masks.decimal(e.target.value);

    formik.handleChange(e);
  }

  function handleDocumentTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    formik.setFieldValue('tipoDocumento', e.target.id);
    formik.setFieldValue('cnpjRemetente', '');
  }

  function handleDocumentChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;
    const regex = /([a-zA-Z!@#$%^& *()_+=\\[\]{};':"\\|,<>\\?~])/;

    if (formik.values.tipoDocumento === 'cpf') {
      value = masks.cpf(value);

      if (value.length === 15 || regex.test(value)) return;

      formik.setFieldValue('cnpjRemetente', masks.cpf(e.target.value));
    } else if (formik.values.tipoDocumento === 'cnpj') {
      value = masks.cpf(value);

      if (value.length === 19 || regex.test(value)) return;

      formik.setFieldValue('cnpjRemetente', masks.cnpj(e.target.value));
    }
  }

  if (companies === undefined || assignorCompanies === undefined) {
    return <SpinnerLoading />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Grid>
          <Input
            type="date"
            label="Data Inicial"
            name="dataInicio"
            value={masks.date.format(formik.values.dataInicio)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            type="date"
            label="Data Final"
            name="dataFim"
            value={masks.date.format(formik.values.dataFim)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid>
          <Input
            type="text"
            label="Valor Inicial"
            name="valorInicial"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.valorInicial)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
          <Input
            type="text"
            label="Valor Final"
            name="valorFinal"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.valorFinal)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
        </Grid>
        <Grid>
          <Input
            type="text"
            label="Valor Conciliado Inicial"
            name="valorConciliadoInicial"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.valorConciliadoInicial)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
          <Input
            type="text"
            label="Valor Conciliado Final"
            name="valorConciliadoFinal"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.valorConciliadoFinal)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
        </Grid>
        <Grid>
          <Input
            type="text"
            label="Saldo Inicial"
            name="saldoInicial"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.saldoInicial)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
          <Input
            type="text"
            label="Saldo Final"
            name="saldoFinal"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.saldoFinal)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
        </Grid>
        <Select
          label="Tipo de Transação"
          name="tipoTransacao"
          value={formik.values.tipoTransacao}
          onChange={(e) => formik.setFieldValue('tipoTransacao', e)}
          onBlur={formik.handleBlur}
          list={[
            {
              id: '',
              text: 'Todos',
            },
            ...transactions,
          ]}
        />
        <Select
          label="Tipo do Remetente"
          name="tipoRemetente"
          value={formik.values.tipoRemetente}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('tipoRemetente', e)}
          list={[
            {
              id: '',
              text: 'Todos',
            },
            ...senders,
          ]}
        />
        <Input
          label="Razão Social do Remetente"
          name="razaoSocialRemetente"
          placeholder="ex: Remetente 1"
          value={formik.values.razaoSocialRemetente}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={Number(formik.values.tipoRemetente) === 3}
        />
        <Radio
          title="Selecione o tipo de documento *"
          hasError={
            !!(formik.touched.tipoDocumento && formik.errors.tipoDocumento)
          }
          errorText={
            formik.touched.tipoDocumento && formik.errors.tipoDocumento
          }
        >
          <label htmlFor="cpf">
            <input
              type="radio"
              name="radio-benefited"
              id="cpf"
              checked={formik.values.tipoDocumento === 'cpf'}
              disabled={Number(formik.values.tipoRemetente) === 3}
              onChange={handleDocumentTypeChange}
            />
            <span>CPF</span>
          </label>
          <label htmlFor="cnpj">
            <input
              type="radio"
              name="radio-benefited"
              id="cnpj"
              checked={formik.values.tipoDocumento === 'cnpj'}
              disabled={Number(formik.values.tipoRemetente) === 3}
              onChange={handleDocumentTypeChange}
            />
            <span>CNPJ</span>
          </label>
        </Radio>
        <Input
          label="Documento *"
          placeholder={`ex: ${
            formik.values.tipoDocumento === 'cnpj'
              ? '00.000.000/0000-00'
              : '000.000.000-00'
          }`}
          value={formik.values.cnpjRemetente}
          onChange={handleDocumentChange}
          onBlur={formik.handleBlur}
          name="cnpjRemetente"
          disabled={
            Number(formik.values.tipoRemetente) === 3 ||
            !formik.values.tipoDocumento
          }
        />
        <Input
          type="text"
          label="Conta bancária"
          name="contaBancaria"
          placeholder="ex: Conta Teste"
          value={formik.values.contaBancaria}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Select
          label="Conta ESCROW"
          name="contaEscrow"
          value={formik.values.contaEscrow}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('contaEscrow', e)}
          list={[
            { id: '', text: 'Ambos' },
            { id: 'false', text: 'Não' },
            { id: 'true', text: 'Sim' },
          ]}
        />
        <Input
          label="Complemento"
          name="complemento"
          placeholder="ex: Exemplo de Complemento"
          value={formik.values.complemento}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Filter
          label="Nome da Empresa"
          name="empresa"
          placeholder="ex: Empresa 1"
          onChange={(e: ICompany) => formik.setFieldValue('empresa', e)}
          onBlur={formik.handleBlur}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresa}
          list={companies}
        />

        <Filter
          label="Nome da Empresa Cedente"
          name="empresaCedente"
          placeholder="ex: Empresa Cedente 1"
          onChange={(e) => formik.setFieldValue('empresaCedente', e)}
          onBlur={formik.handleBlur}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresaCedente}
          list={assignorCompanies}
        />

        <Textarea
          label="Observações"
          name="observacao"
          value={formik.values.observacao}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="buttons">
        <Button color="warning" onClick={() => formik.resetForm()}>
          Limpar
        </Button>
        <Button type="submit">Filtrar</Button>
      </div>
    </form>
  );
};

export default VIFilter;
