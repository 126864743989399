import { api } from './api';
import { AssignorCompanyService } from './AssignorCompanyService';
import { AuditService } from './AuditService';
import { CompanyService } from './CompanyService';
import { ConciliationService } from './ConciliationService';
import { ConfigurationsService } from './ConfigurationsService';
import { DomainsService } from './DomainsService';
import { GerService } from './GerService';
import { IAuditService } from './interfaces/IAuditService';
import { ICompanyService } from './interfaces/ICompanyService';
import { IConciliationService } from './interfaces/IConciliationService';
import { IConfigurationsService } from './interfaces/IConfigurationsService';
import { IDomainsService } from './interfaces/IDomainsService';
import { IGerService } from './interfaces/IGerService';
import { IProfileService } from './interfaces/IProfileService';
import { IUserService } from './interfaces/IUserService';
import { IVIService } from './interfaces/IVIService';
import { ProfileService } from './ProfileService';
import { UserService } from './UserService';
import { VIService } from './VIService';

export const userService: IUserService = new UserService(api);

export const profileService: IProfileService = new ProfileService(api);

export const companyService: ICompanyService = new CompanyService(api);

export const assignorCompanyService: ICompanyService = new AssignorCompanyService(
  api
);

export const viService: IVIService = new VIService(api);

export const conciliationService: IConciliationService = new ConciliationService(
  api
);

export const domainsService: IDomainsService = new DomainsService(api);

export const gerService: IGerService = new GerService(api);

export const configurationsService: IConfigurationsService = new ConfigurationsService(
  api
);

export const auditService: IAuditService = new AuditService(api);
