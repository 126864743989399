import React, { createContext, useState, useEffect, useContext } from 'react';

interface Context {
  retract: boolean;
  handleRetract: () => void;
}

export const MenuContext = createContext<Context>({} as Context);

export const MenuProvider: React.FC = ({ children }) => {
  const localRetract = localStorage.getItem('menuRetract');
  const [retract, setRetract] = useState(!!localRetract);

  useEffect(() => {
    const menuRetract = window.localStorage.getItem('menuRetract');
    setRetract(!!menuRetract);
  }, []);

  useEffect(() => {
    if (retract) {
      window.localStorage.setItem('menuRetract', retract.toString());
    } else {
      window.localStorage.removeItem('menuRetract');
    }
  }, [retract]);

  function handleRetract() {
    setRetract((state) => !state);
  }

  return (
    <MenuContext.Provider value={{ retract, handleRetract }}>
      {children}
    </MenuContext.Provider>
  );
};

export function useMenu() {
  return useContext(MenuContext);
}
