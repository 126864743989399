import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { MENU } from '@consts';
import { handleLogo } from '@helpers/handleLogo';
import { useAuth } from '@hooks/useAuth';
import { useMenu } from '@hooks/useMenu';

import { Icons } from '../../Dictionaries/Icons';
import Tooltip from '../../Tooltip';
import RetractArrow from '../RetractArrow';
import NavIcon from './NavIcon';
import NavLink from './NavLink';
import { Content } from './styles';

const Sidebar: React.FC = () => {
  const { retract } = useMenu();
  const { hasPermission } = useAuth();
  const [baseMenu] = useState(
    MENU.filter((item) => !item.group).filter(
      (item) => !item.permission || hasPermission(item.permission)
    )
  );
  const [configMenu] = useState(
    MENU.filter((item) => item.group === 'Configurações').filter(
      (item) => !item.permission || hasPermission(item.permission)
    )
  );

  return (
    <Content retract={retract} logo={process.env.REACT_APP_CURRENT_LOGO}>
      <Link className="logo" to="/">
        <img className="full" src={handleLogo()?.full} alt="logo-koperar" />
        <img
          className="retract"
          src={handleLogo()?.retract}
          alt="logo-koperar"
        />
      </Link>
      <div className="retract-arrow">
        <RetractArrow retract={retract} />
      </div>
      <nav>
        <ul>
          {baseMenu.map((item) => (
            <NavLink
              key={item.path}
              title={item.text}
              to={item.path}
              icon={<NavIcon path={item.path} />}
            />
          ))}
          <NavLink
            title="Configurações"
            to="/configuracoes"
            icon={<Icons.Settings />}
            className="settings"
          >
            {configMenu.length > 0 &&
              configMenu.map((item) => (
                <NavLink
                  key={item.path}
                  title={item.text}
                  to={item.path}
                  icon={<NavIcon path={item.path} />}
                />
              ))}
          </NavLink>
        </ul>
      </nav>
      <Tooltip id="configuracoes" />
    </Content>
  );
};

export default Sidebar;
