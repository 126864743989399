/* eslint-disable max-len */
import * as yup from 'yup';

const schema = yup.object().shape({
  currentPassword: yup.string().required('Senha atual é um campo obrigatório.'),
  newPassword: yup
    .string()
    .required('Nova Senha é um campo obrigatório.')
    .matches(
      RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/),
      'Sua senha deve conter pelo menos uma letra minúscula, uma letra maiúscula e um número, com no mínimo 8 caracteres.'
    ),
  confirmPassword: yup
    .string()
    .min(8, 'Confirmar Nova Senha deve ter pelo menos 8 caracteres.')
    .oneOf(
      [yup.ref('newPassword'), null],
      'Confirmar Nova Senha deve ser igual ao campo Nova Senha.'
    )
    .required('Confirmar Nova Senha é um campo obrigatório.'),
});

export default schema;
