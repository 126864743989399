import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--gap-small) 0;
  width: 100%;
  max-width: 600px;

  label {
    font-size: var(--fsz-small);
    margin-bottom: var(--gap-small);
    font-weight: bold;
  }

  textarea {
    background: ${(props) => props.theme.colors.white};
    border-radius: 2px;
    padding: var(--gap-small);
    border: 1px solid ${(props) => props.theme.colors.grey};
    outline: 0;
    background: transparent;
    font-size: var(--fsz-small);
    font-weight: 500;
    line-height: 18px;
    padding: var(--gap-small);
    resize: none;
  }
`;
