import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Filter from '@components/Filter';
import Grid from '@components/Grid';
import Input from '@components/Input';
import SpinnerLoading from '@components/Loading/SpinnerLoading';
import Select from '@components/Select';

import { masks } from '@helpers/mask';
import { useCompanies } from '@hooks/useCompanies';
import { ICompany } from '@models/domain/ICompany';
import { IConciliationFilter } from '@models/domain/IConciliation';

interface Props {
  filters: IConciliationFilter;
  setOpenModal(state: boolean): void;
  setFilter(value: string): void;
  setFilters(values: IConciliationFilter): void;
}

const ConciliationFilter: React.FC<Props> = ({
  filters,
  setFilters,
  setFilter,
  setOpenModal,
}) => {
  const { listAllCompanies, getCompanyName, getCompanyId } = useCompanies();
  const [companies, setCompanies] = useState<ICompany[]>();

  const listAllCompaniesCb = useCallback(async () => {
    const response = await listAllCompanies();

    setCompanies(response);
  }, [listAllCompanies]);

  useEffect(() => {
    listAllCompaniesCb();
  }, [listAllCompaniesCb]);

  async function handleSubmit(data: IConciliationFilter) {
    setFilter('todos');
    setFilters({
      ...data,
      valorInicial: masks.filterNumber(data.valorInicial),
      valorFinal: masks.filterNumber(data.valorFinal),
      devolucaoValorInicial: masks.filterNumber(data.devolucaoValorInicial),
      devolucaoValorFinal: masks.filterNumber(data.devolucaoValorFinal),
      somaTitulosInicial: masks.filterNumber(data.somaTitulosInicial),
      somaTitulosFinal: masks.filterNumber(data.somaTitulosFinal),
    });

    setOpenModal(false);
  }

  const { setValues, ...formik } = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      dataInicio: '',
      dataFim: '',
      valorInicial: '',
      valorFinal: '',
      empresa: {
        razaoSocial: '',
        id: '',
      },
      status: '',
      somaTitulosInicial: '',
      somaTitulosFinal: '',
      liquidacao: '',
      razaoSocialPagadorLiquidacao: '',
      devolucaoValorInicial: '',
      devolucaoValorFinal: '',
    },
  });

  useEffect(() => {
    if (filters ?? false) {
      setValues(filters);
    }
  }, [filters, setValues]);

  function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = masks.decimal(e.target.value);

    formik.handleChange(e);
  }

  if (companies === undefined) {
    return <SpinnerLoading />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Grid>
          <Select
            label="Status"
            name="status"
            value={formik.values.status}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue('status', e)}
            list={[
              {
                id: '',
                text: 'Todos',
              },
              {
                id: 1,
                text: 'Em Análise',
              },
              {
                id: 2,
                text: 'Aguardando Aprovação',
              },
              {
                id: 3,
                text: 'Autorizado',
              },
              {
                id: 4,
                text: 'Não Autorizado',
              },
              {
                id: 5,
                text: 'Efetivado',
              },
            ]}
          />
          <Select
            label="Liquidação"
            name="liquidacao"
            value={formik.values.liquidacao}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue('liquidacao', e)}
            list={[
              {
                id: '',
                text: 'Todas',
              },
              {
                id: 1,
                text: 'Sacado',
              },
              {
                id: 2,
                text: 'Cedente',
              },
            ]}
          />
        </Grid>
        <Grid>
          <Input
            type="date"
            label="Data Inicial"
            name="dataInicio"
            value={masks.date.format(formik.values.dataInicio)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            type="date"
            label="Data Final"
            name="dataFim"
            value={masks.date.format(formik.values.dataFim)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid>
          <Input
            label="Valor Inicial"
            name="valorInicial"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.valorInicial)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
          <Input
            label="Valor Final"
            name="valorFinal"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.valorFinal)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
        </Grid>
        <Grid>
          <Input
            label="Soma Títulos Inicial"
            name="somaTitulosInicial"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.somaTitulosInicial)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
          <Input
            label="Soma Títulos Final"
            name="somaTitulosFinal"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.somaTitulosFinal)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
        </Grid>
        <Grid>
          <Input
            label="Devolução Inicial"
            name="devolucaoValorInicial"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.devolucaoValorInicial)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
          <Input
            label="Devolução Final"
            name="devolucaoValorFinal"
            placeholder="ex: 1.000,00"
            value={masks.decimal(formik.values.devolucaoValorFinal)}
            onChange={handleDecimalChange}
            onBlur={formik.handleBlur}
            prepend="R$"
          />
        </Grid>

        <Filter
          label="Nome da Empresa"
          name="empresa"
          placeholder="ex: Empresa 1"
          onChange={(e) => formik.setFieldValue('empresa', e)}
          onBlur={formik.handleBlur}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresa}
          list={companies}
        />
        <Input
          label="Razão Social do Pagador"
          name="razaoSocialPagadorLiquidacao"
          placeholder="ex: Pagador 1"
          value={formik.values.razaoSocialPagadorLiquidacao}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="buttons">
        <Button color="warning" onClick={() => formik.resetForm()}>
          Limpar
        </Button>
        <Button type="submit">Filtrar</Button>
      </div>
    </form>
  );
};

export default ConciliationFilter;
