import React, { useState } from 'react';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Input from '@components/Input';
import { PasswordInput } from '@components/PasswordInput';

import { getConfigField } from '@helpers/getConfigField';
import { masks } from '@helpers/mask';
import { useConfigurations } from '@hooks/useConfigurations';
import {
  IConfiguration,
  IConfigurationEdit,
} from '@models/domain/IConfiguration';

import schema from './schema';

interface Props {
  configurations: IConfiguration;
  reloadConfigurations: () => void;
  setOpenEdit: (state: boolean) => void;
}

const ConfigurationsForm: React.FC<Props> = ({
  configurations,
  setOpenEdit,
  reloadConfigurations,
}) => {
  const { edit } = useConfigurations();
  const [fieldType] = useState(getConfigField(configurations?.nome));

  async function handleSubmit(data: IConfigurationEdit) {
    const submit = data;

    delete submit.fieldType;

    try {
      await edit(submit);

      setOpenEdit(false);
      reloadConfigurations();
    } catch {
      return null;
    }

    return null;
  }

  const { setFieldValue, ...formik } = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      fieldType,
      parametroId: configurations?.parametroId ?? '',
      usuarioId: configurations?.usuarioId ?? '',
      valor: configurations?.valor ?? '',
    },
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    if (fieldType === 'Porta') {
      setFieldValue(name, masks.integer(value));
    } else if (fieldType === 'Hora') {
      if (value.length > 5) return;

      setFieldValue(name, masks.hour(value));
    } else {
      setFieldValue(name, value);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Input
          label="Nome do Parâmetro"
          defaultValue={configurations.nome}
          readOnly
          disabled
        />
        <PasswordInput
          label="Valor do Parâmetro"
          name="valor"
          placeholder="ex: Exemplo de Valor"
          params={fieldType === 'Senha'}
          value={formik.values.valor}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.valor && formik.errors.valor)}
          errorText={formik.touched.valor && formik.errors.valor}
        />
      </div>
      <div className="buttons">
        <Button color="warning" outline onClick={() => setOpenEdit(false)}>
          Cancelar
        </Button>
        <Button type="submit" disabled={formik.isSubmitting || !formik.dirty}>
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default ConfigurationsForm;
