import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Filter from '@components/Filter';
import Grid from '@components/Grid';
import Input from '@components/Input';
import SpinnerLoading from '@components/Loading/SpinnerLoading';

import { masks } from '@helpers/mask';
import { useCompanies } from '@hooks/useCompanies';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { IAuditFilter } from '@models/domain/IAudit';
import { ICompany } from '@models/domain/ICompany';

interface Props {
  filters: IAuditFilter;
  setOpenModal(state: boolean): void;
  setFilters(values: IAuditFilter): void;
}

const AuditFilter: React.FC<Props> = ({
  filters,
  setFilters,
  setOpenModal,
}) => {
  const {
    listAllCompanies,
    listAllAssignorCompanies,
    getCompanyName,
    getCompanyId,
  } = useCompanies();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [assignorCompanies, setAssignorCompanies] = useState<
    IAssignorCompany[]
  >();

  const listAllCompaniesCb = useCallback(async () => {
    const response = await listAllCompanies();

    setCompanies(response);
  }, [listAllCompanies]);

  const listAllAssignorCompaniesCb = useCallback(async () => {
    const response = await listAllAssignorCompanies();

    setAssignorCompanies(response);
  }, [listAllAssignorCompanies]);

  useEffect(() => {
    listAllCompaniesCb();
    listAllAssignorCompaniesCb();
  }, [listAllCompaniesCb, listAllAssignorCompaniesCb]);

  async function handleSubmit(data: IAuditFilter) {
    setFilters(data);
    setOpenModal(false);
  }

  const { setValues, ...formik } = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      dataHoraInicio: '',
      dataHoraFim: '',
      empresa: {
        razaoSocial: '',
        id: '',
      },
      empresaCedente: {
        razaoSocial: '',
        id: '',
      },
      usuario: '',
      operacao: '',
      valor: '',
    },
  });

  useEffect(() => {
    if (filters) {
      setValues(filters);
    }
  }, [filters, setValues]);

  function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = masks.decimal(e.target.value);

    formik.handleChange(e);
  }

  if (companies === undefined || assignorCompanies === undefined) {
    return <SpinnerLoading />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Grid>
          <Input
            type="date"
            label="Data Inicial"
            name="dataHoraInicio"
            value={masks.date.format(formik.values.dataHoraInicio)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            type="date"
            label="Data Final"
            name="dataHoraFim"
            value={masks.date.format(formik.values.dataHoraFim)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Filter
          label="Nome da Empresa"
          name="empresa"
          placeholder="ex: Empresa 1"
          onChange={(e) => formik.setFieldValue('empresa', e)}
          onBlur={formik.handleBlur}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresa}
          list={companies}
        />
        <Filter
          label="Nome da Empresa Cedente"
          name="empresaCedente"
          placeholder="ex: Empresa Cedente 1"
          onChange={(e) => formik.setFieldValue('empresaCedente', e)}
          onBlur={formik.handleBlur}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresaCedente}
          list={assignorCompanies}
        />
        <Input
          label="Usuário"
          name="usuario"
          placeholder="ex: Usuário 1"
          value={formik.values.usuario}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          label="Operação"
          name="operacao"
          placeholder="ex: Operação 1"
          value={formik.values.operacao}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          label="Valor"
          name="valor"
          placeholder="ex: 1.000,00"
          value={masks.decimal(formik.values.valor)}
          onChange={handleDecimalChange}
          onBlur={formik.handleBlur}
          prepend="R$"
        />
      </div>
      <div className="buttons">
        <Button color="warning" onClick={() => formik.resetForm()}>
          Limpar
        </Button>
        <Button type="submit">Filtrar</Button>
      </div>
    </form>
  );
};

export default AuditFilter;
