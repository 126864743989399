import styled, { css, DefaultTheme } from 'styled-components';

type Color = 'primary' | 'warning' | 'light';
interface Props {
  size?: 'large';
  color: Color;
  flex?: boolean;
}

function getColor(color: Color, theme: DefaultTheme) {
  const COLORS = {
    primary: theme.colors.primary,
    light: theme.colors.grey,
    warning: theme.colors.red,
  };

  return COLORS[color];
}

const buttonCss = css<Props>`
  border: 0;
  outline: 0;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  padding: 0 16px;
  filter: drop-shadow(0, 1px, 3px ${(props) => props.theme.colors.dropShadow});
  height: ${({ size }) => (size === 'large' ? '52px' : '34px')};
  font-size: ${({ size }) => (size === 'large' ? '20px' : '12px')};
  ${(props) =>
    props.flex &&
    'display: flex; align-items: center; justify-content: center;'}
  transition: all 0.3s;

  a {
    text-decoration: none;
    padding: 5px 0;
  }

  svg {
    margin-right: 5px;
    font-size: 1.1rem;
  }
`;

export const ButtonSolid = styled.button`
  ${buttonCss}
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => getColor(props.color, props.theme)};

  &:disabled {
    background: ${(props) => props.theme.colors.grey};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    filter: brightness(0.8);
  }

  a {
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ButtonOutline = styled.button<Props>`
  ${buttonCss}

  background: transparent;
  color: ${(props) => getColor(props.color, props.theme)};
  border: 1px solid ${(props) => getColor(props.color, props.theme)};

  &:disabled {
    color: ${(props) => props.theme.colors.grey};
    border-color: ${(props) => props.theme.colors.grey};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => getColor(props.color, props.theme)};
  }

  a {
    color: ${(props) => getColor(props.color, props.theme)};
  }
`;

export const ButtonLink = styled.a`
  ${buttonCss}

  color: ${(props) => props.theme.colors.white};
  background: ${(props) => getColor(props.color, props.theme)};
  text-decoration: none;
  display: flex;
  align-items: center;

  &:disabled {
    background: ${(props) => props.theme.colors.grey};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    filter: brightness(0.8);
  }
`;
