import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;

  header {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    width: 50%;
    max-width: 450px;
    min-width: 300px;

    button:nth-child(1) {
      margin-right: 5px;
    }
  }

  div:first-child {
    word-break: keep-all;
  }

  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.blueLight};
    }
  }

  .rdt_TableHeadRow {
    padding: var(--gap-medium) 0;
  }

  .rdt_TableBody {
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 25px;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    padding: 0 5px;
  }
`;

export const NoContent = styled.p`
  margin: 24px 16px;
  color: #b8b8b8;
`;
