import { AxiosInstance, CancelToken } from 'axios';

import { IRepurchase } from '@models/domain/IRepurchase';
import { ITitle } from '@models/domain/ITitle';
import IList from '@models/util/IList';
import IPaginate from '@models/util/IPaginate';
import { IResponse } from '@models/util/IResponse';

import { IGerService } from './interfaces/IGerService';

const ENDPOINT = '/api/v1';

export class GerService implements IGerService {
  constructor(private api: AxiosInstance) {}

  async listTitles(
    page: number,
    amount = 10,
    cnpjSacado: string,
    dataCalculo: string,
    cancelToken?: CancelToken
  ) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IResponse<ITitle[]>>(
      `${ENDPOINT}/titulos/titulos-ger?cnpjSacado=${cnpjSacado}&dataCalculo=${dataCalculo}`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<ITitle[]>;
  }

  async listRepurchases(
    page: number,
    amount = 10,
    empresa: string,
    cedente: string,
    cancelToken?: CancelToken
  ) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IRepurchase[]>(
      `${ENDPOINT}/recompraSimulada/recompra-simulada?empresa=${empresa}&cedenteExterno=${cedente}`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IPaginate<IRepurchase[]>;
  }

  async listRepurchaseTitles(
    page: number,
    amount = 10,
    repurchaseId: string,
    date: string,
    cancelToken?: CancelToken
  ) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IResponse<ITitle[]>>(
      `${ENDPOINT}/titulos/titulos-recompra?recompraSimuladaId=${repurchaseId}&dataCalculo=${date}`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<ITitle[]>;
  }
}
