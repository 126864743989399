import React, { useCallback, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { masks } from '@helpers/mask';

import { Container, InputWrapper, List, ListButton } from './styles';

interface Props {
  label: string;
  name: string;
  hasError?: boolean;
  errorText?: string;
  placeholder: string;
  value?: unknown | unknown[];
  disabled?: boolean;
  list: unknown[];
  getDisplayName(item: unknown): string;
  getSubmitValue(item: unknown): string;
  onChange?(e: unknown): void;
  onBlur?(e: React.FocusEvent<HTMLDivElement>): void;
}

const Filter: React.FC<Props> = ({
  label,
  name,
  onChange,
  onBlur,
  hasError,
  errorText,
  placeholder,
  getDisplayName,
  getSubmitValue,
  value,
  disabled,
  list,
}) => {
  const [openList, setOpenList] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [query, setQuery] = useState(getDisplayName(value));
  const [selected, setSelected] = useState(getDisplayName(value));
  const [initialSubmitValue] = useState(getSubmitValue(value));
  const [filteredList, setFilteredList] = useState(list);
  const timerToClear = useRef(null);

  const checkInitialValueChanged = useCallback(() => {
    return getSubmitValue(value) === initialSubmitValue;
  }, [getSubmitValue, initialSubmitValue, value]);

  const setItemSelected = useCallback((newValue: string) => {
    setSelected(newValue);
    setQuery(newValue);
  }, []);

  useEffect(() => {
    if (
      (selected && !getSubmitValue(value)) ||
      (checkInitialValueChanged() &&
        selected &&
        selected !== getDisplayName(value))
    ) {
      setItemSelected(getDisplayName(value));
    }
  }, [
    checkInitialValueChanged,
    getDisplayName,
    getSubmitValue,
    selected,
    setItemSelected,
    value,
  ]);

  useEffect(() => {
    if (disabled) {
      setQuery('');
    }
  }, [disabled]);

  useEffect(() => {
    // limpa o valor do pai quando o usuário limpa o campo
    if (!query && selected) {
      setSelected('');
      onChange('');
    }
  }, [onChange, query, selected]);

  useEffect(() => {
    if (!query) {
      setFilteredList(list);
    }
  }, [getDisplayName, list, onChange, query]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value: inputValue } = e.target;

    setQuery(inputValue);
    setFilteredList(
      list.filter((item) =>
        getDisplayName(item).toLowerCase().includes(inputValue.toLowerCase())
      )
    );

    if (!openList) {
      setOpenList(true);
    }

    if (inputValue && inputValue !== selected) {
      setSelected('');
      onChange(initialSubmitValue);
    }
  }

  function handleItemClick(item) {
    setOpenList(false);
    onChange(item);
    setItemSelected(getDisplayName(item));
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = e;
    if (
      (key === 'ArrowDown' || key === 'ArrowUp' || key === 'Enter') &&
      filteredList !== undefined
    ) {
      masks.keyControl(
        key,
        filteredList,
        query,
        getDisplayName,
        setOpenList,
        onChange,
        setItemSelected
      );
    }
  }

  function handleBlur(e: React.FocusEvent<HTMLDivElement>) {
    e.persist();
    setHasFocus(false);
    timerToClear.current = setTimeout(() => onBlur(e), 150);
  }

  function handleInputClick() {
    setOpenList(true);
  }

  return (
    <Container hasError={hasError} hasFocus={hasFocus}>
      <label htmlFor={name}>{label}</label>

      <OutsideClickHandler onOutsideClick={() => setOpenList(false)}>
        <InputWrapper
          hasError={hasError ?? false}
          disabled={disabled ?? false}
          hasFocus={hasFocus}
        >
          <input
            type="text"
            value={query}
            placeholder={placeholder}
            onChange={handleChange}
            name={name}
            autoComplete="off"
            onKeyDown={handleKeyDown}
            onFocus={() => setHasFocus(true)}
            onBlur={handleBlur}
            onClick={handleInputClick}
            disabled={disabled}
          />
        </InputWrapper>
        {errorText && <p className="autocomplete-error">{errorText}</p>}
        {openList && filteredList?.length > 0 && (
          <List>
            <ul>
              {filteredList?.length > 0 &&
                filteredList?.map((item) => (
                  <li key={getSubmitValue(item)}>
                    <ListButton
                      type="button"
                      onClick={() => handleItemClick(item)}
                      isKeyHover={query === getDisplayName(item)}
                    >
                      {getDisplayName(item)}
                    </ListButton>
                  </li>
                ))}
            </ul>
          </List>
        )}
      </OutsideClickHandler>
    </Container>
  );
};

export default Filter;
