export const theme = {
  colors: {
    gradientPink: '#E6217D',
    gradientBlue: '#0936EE',
    primary: '#2495DA',
    secundary: '#62D2B7',
    blueMedium: '#9EE4E1',
    blueLight: '#E9F4FB',
    grey: '#C4C4C4',
    background: '#E6E9F1',
    text: '#464E5F',
    red: '#EB5757',
    redDark: '#6F2020',
    red70: 'rgba(235, 87, 87, 0.7)',
    red30: 'rgba(235, 87, 87, 0.3)',
    white: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.25)',
    dropShadow: 'rgba(0, 0, 0, 0.05)',
  },
};
