import styled from 'styled-components';

export const FormStep1 = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gap-medium) var(--gap-big);
  height: 100%;
  width: 100%;
`;
