import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import Button from '@components/Button';
import { Icons } from '@components/Dictionaries/Icons';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { parseDate } from '@helpers/date';
import { handleRequestError } from '@helpers/handleRequestError';
import { useConciliations } from '@hooks/useConciliations';
import { IConciliation } from '@models/domain/IConciliation';
import { ITitle } from '@models/domain/ITitle';
import { IRouteWithId } from '@models/util/IRouteParams';
import { conciliationService, gerService } from '@services/index';

import ConciliationErrorText from '../../ErrorText';
import History from '../../History';
import { ModalEffectuate } from '../../ModalEffectuate';
import BenefitedForm from '../BenefitedForm';
import { ConciliationForm } from '../Form';
import schema from '../SecondStep/schema';
import BlastForm from './BlastForm';
import { Container } from './styles';
import TitlesList from './TitlesList';

interface Props {
  conciliation: IConciliation;
  setCurrentStep?(step: number): void;
  setConciliation(obj: unknown): void;
}

const ThirdStep: React.FC<Props> = ({
  conciliation,
  setConciliation,
  setCurrentStep,
}) => {
  const history = useHistory();
  const {
    saveConciliation,
    effectuateConciliation,
    clearConciliationToSubmit,
  } = useConciliations();
  const { id } = useParams<IRouteWithId>();
  const [titles, setTitles] = useState<ITitle[]>();
  const [titlesTablePage, setTitlesTablePage] = useState(1);
  const [titlesTableRowsPerPage, setTitlesTableRowsPerPage] = useState(10);
  const [titlesTableRowsAmount, setTitlesTableRowsAmount] = useState(1);
  const [openBenefited, setOpenBenefited] = useState(false);
  const [openBlast, setOpenBlast] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openEffectuate, setOpenEffectuate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isEdit = useCallback(() => {
    return !!id;
  }, [id]);

  const listTitles = useCallback(
    async (cnpjSacado, data, cancelToken?) => {
      try {
        const { rows, count } = await gerService.listTitles(
          titlesTablePage,
          titlesTableRowsPerPage,
          cnpjSacado,
          data,
          cancelToken
        );

        setTitles(rows.data);
        setTitlesTableRowsAmount(count);
      } catch (error) {
        setTitles([]);
        handleRequestError(
          error,
          'Erro na criação da conciliação',
          'Títulos não encontrados para o pagador selecionado'
        );
      }
    },
    [titlesTablePage, titlesTableRowsPerPage]
  );

  const listRepurchasedTitles = useCallback(
    async (repurchaseId, data, cancelToken?) => {
      try {
        const { rows, count } = await gerService.listRepurchaseTitles(
          titlesTablePage,
          titlesTableRowsPerPage,
          repurchaseId,
          data,
          cancelToken
        );

        setTitles(rows.data);
        setTitlesTableRowsAmount(count);
      } catch (error) {
        setTitles([]);
        handleRequestError(
          error,
          'Erro na criação da conciliação',
          'Títulos não encontrados para o pagador selecionado'
        );
      }
    },
    [titlesTablePage, titlesTableRowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (conciliation.recompraSimuladaId) {
      listRepurchasedTitles(
        conciliation.recompraSimuladaId,
        conciliation.data,
        source.token
      );
    } else if (conciliation.liquidacao?.pagadorCpfCnpjLiquidacao !== undefined) {
      listTitles(
        conciliation.liquidacao?.pagadorCpfCnpjLiquidacao,
        conciliation?.data,
        source.token
      );
    }

    return () => {
      source.cancel();
    };
  }, [conciliation, listRepurchasedTitles, listTitles]);

  async function handleSubmit(data) {
    if (data?.valor === data?.somaTitulos) {
      if (conciliation?.contaEscrow) {
        setOpenEffectuate(true);
      } else {
        await effectuateConciliation(data, null, history.push);
      }
    } else {
      toast.info(
        // eslint-disable-next-line max-len
        'Para prosseguir com a efetivação, o Valor Total dos Títulos deve ser igual ao Valor Total das VIs'
      );
    }
  }

  const { setFieldValue, ...formik } = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      id: conciliation.id,
      data: conciliation.data,
      valor: conciliation.valor,
      empresa: conciliation.empresa,
      empresaCedente: conciliation.empresaCedente,
      status: conciliation?.status,
      somaTitulos: conciliation.somaTitulos,
      beneficiado: conciliation.beneficiado,
      liquidacao: conciliation.liquidacao,
      contaEscrow: conciliation.contaEscrow,
      contaPropria: conciliation.contaPropria,
      criadoPor: conciliation.criadoPor,
      contaTransferencia: conciliation.contaTransferencia,
      devolucao: conciliation.devolucao,
      recompraSimuladaId: conciliation.recompraSimuladaId,
      valoresAIdentificarConciliacao:
        conciliation.valoresAIdentificarConciliacao,
      titulos: conciliation.titulos,
    },
  });

  const handleTitlesAmount = useCallback(() => {
    if (formik.values.titulos.length <= 0) {
      setFieldValue('somaTitulos', 0);
    } else {
      setFieldValue(
        'somaTitulos',
        formik.values.titulos
          .map((item) => item.valorQuitado)
          .reduce((acc: number, cur: number) => (acc += cur))
      );
    }
  }, [formik.values.titulos, setFieldValue]);

  useEffect(() => {
    handleTitlesAmount();
  }, [handleTitlesAmount]);

  function stepBack() {
    setConciliation(formik.values);
    setCurrentStep(2);
  }

  async function handleApproveConciliation() {
    const approve = {
      ...formik.values,
      status: 2,
    };
    try {
      if (isEdit()) {
        await conciliationService.edit(clearConciliationToSubmit(approve));
      } else {
        await conciliationService.register(clearConciliationToSubmit(approve));
      }

      toast.success('Conciliação solicitada para aprovação');
      history.push('/conciliacoes');
    } catch (error) {
      handleRequestError(
        error,
        'Erro ao solicitar aprovação desta conciliação'
      );
    }
  }

  function checkTitlesLengthAndSubmitting() {
    return (
      formik.isSubmitting ||
      formik.values.titulos.length <= 0 ||
      titles?.length <= 0
    );
  }

  function givebackBiggerThanZero() {
    return formik.values.devolucao.valor > 0;
  }

  function hasTitleBiggerThanVisTotal() {
    return formik.values.somaTitulos > formik.values.valor;
  }

  function isFormDateDifferentFromCurrentDate() {
    return (
      parseDate(formik.values.data).toLocaleDateString() !==
      new Date().toLocaleDateString()
    );
  }

  function hasDifferenceTitleIsBiggerThanField() {
    return !!formik.values.titulos.find(
      (item) => item.diferencaInput < item.diferenca
    );
  }

  function checkVisTotalLessThanDevolutionAndTitles() {
    return (
      formik.values.valor <
      formik.values.devolucao?.valor + formik.values.somaTitulos
    );
  }

  function checkSubmitIsDisabled() {
    return (
      checkTitlesLengthAndSubmitting() ||
      givebackBiggerThanZero() ||
      hasTitleBiggerThanVisTotal() ||
      isFormDateDifferentFromCurrentDate() ||
      hasDifferenceTitleIsBiggerThanField() ||
      checkVisTotalLessThanDevolutionAndTitles()
    );
  }

  function checkApproveIsDisabled() {
    return (
      checkTitlesLengthAndSubmitting() ||
      hasTitleBiggerThanVisTotal() ||
      checkVisTotalLessThanDevolutionAndTitles() ||
      (!hasDifferenceTitleIsBiggerThanField() &&
        !givebackBiggerThanZero() &&
        !isFormDateDifferentFromCurrentDate())
    );
  }

  function isBlastDisabled() {
    return (
      formik.isSubmitting ||
      !!formik.values.recompraSimuladaId ||
      hasTitleBiggerThanVisTotal() ||
      titles?.length <= 0 ||
      checkVisTotalLessThanDevolutionAndTitles()
    );
  }

  async function handleSave() {
    if (isSaving) return;

    setIsSaving(true);

    try {
      const response = await saveConciliation(
        formik.values,
        isEdit() || !!formik.values.id
      );

      toast.success('Conciliação salva com sucesso');
      if (response) {
        setFieldValue('id', response?.id);
      }
    } catch (error) {
      handleRequestError(error, 'Erro na criação da conciliação');
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <ConciliationForm
          formik={formik}
          setOpenBenefited={setOpenBenefited}
          liquidationType={formik.values.liquidacao?.tipo}
          disableDate
        />
        <div className="vi">
          <TitlesList
            titles={titles}
            rowsAmount={titlesTableRowsAmount}
            rowsPerPage={titlesTableRowsPerPage}
            setTablePage={setTitlesTablePage}
            setRowsPerPage={setTitlesTableRowsPerPage}
            setFieldValue={setFieldValue}
            formikTitles={formik.values.titulos}
            company={formik.values.empresa}
            liquidation={formik.values.liquidacao}
            recompraSimuladaId={formik.values.recompraSimuladaId}
            referenceDate={formik.values.data}
            conciliacaoId={formik.values.id}
            titlesTotal={formik.values.somaTitulos}
            visTotal={formik.values.valor - formik.values.devolucao.valor}
          />
        </div>

        {givebackBiggerThanZero() &&
          !checkVisTotalLessThanDevolutionAndTitles() && (
            <ConciliationErrorText>
              Valor da devolução TED é maior que R$ 0,00. Favor solicitar
              aprovação antes de efetivar
            </ConciliationErrorText>
          )}
        {hasDifferenceTitleIsBiggerThanField() &&
          !checkVisTotalLessThanDevolutionAndTitles() && (
            <ConciliationErrorText>
              Valor do campo de diferença é menor que a diferença do título.
              Favor solicitar aprovação antes de efetivar
            </ConciliationErrorText>
          )}
        {isFormDateDifferentFromCurrentDate() &&
          !checkVisTotalLessThanDevolutionAndTitles() && (
            <ConciliationErrorText>
              A Data de Referência desta conciliação é diferente da data atual.
              Favor solicitar aprovação antes de efetivar
            </ConciliationErrorText>
          )}
        {hasTitleBiggerThanVisTotal() && (
          <ConciliationErrorText>
            Não é possível prosseguir com esta conciliação, pois o valor dos
            títulos é maior que o valor total dos VIs
          </ConciliationErrorText>
        )}
        {checkVisTotalLessThanDevolutionAndTitles() && (
          <ConciliationErrorText>
            Não é possível prosseguir com esta conciliação, pois o Valor Total
            dos VIS é menor do que a some entre a devolução e os títulos
          </ConciliationErrorText>
        )}

        <div className="buttons">
          <Button onClick={stepBack}>Anterior</Button>
          <div className="buttons-wrapper">
            <Button
              flex
              onClick={() => setOpenBlast(true)}
              disabled={isBlastDisabled()}
            >
              <Icons.Lightning /> Alteração em Rajada
            </Button>
            <Button
              onClick={handleSave}
              disabled={
                formik.isSubmitting ||
                isSaving ||
                hasTitleBiggerThanVisTotal() ||
                titles?.length <= 0
              }
            >
              Salvar
            </Button>
            {isEdit() && (
              <Button onClick={() => setOpenHistory(true)}>Histórico</Button>
            )}
            <Button
              onClick={handleApproveConciliation}
              disabled={checkApproveIsDisabled()}
            >
              Solicitar aprovação
            </Button>
            <Button type="submit" disabled={checkSubmitIsDisabled()}>
              Efetivar
            </Button>
          </div>
        </div>
      </form>
      {openBenefited && (
        <Modal title="Beneficiado" setOpenModal={setOpenBenefited}>
          <BenefitedForm
            benefited={formik.values.devolucao}
            setFieldValue={setFieldValue}
            setOpenModal={setOpenBenefited}
          />
        </Modal>
      )}
      {openBlast && (
        <Modal title="Alteração em Rajada" setOpenModal={setOpenBlast}>
          <BlastForm
            setOpenBlast={setOpenBlast}
            formikTitles={formik.values.titulos}
            setTitlesValues={(e) => setFieldValue('titulos', e)}
          />
        </Modal>
      )}
      {openHistory && (
        <History conciliation={id} setOpenHistory={setOpenHistory} />
      )}
      {openEffectuate && (
        <ModalEffectuate
          conciliation={formik.values}
          setOpenModal={setOpenEffectuate}
        />
      )}
    </Container>
  );
};

export default ThirdStep;
