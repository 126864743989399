import React, { useState } from 'react';

import { FormikErrors } from 'formik';
import { useTheme } from 'styled-components';

import { Icons } from '../Dictionaries/Icons';
import { Container, InputWrapper } from './styles';

interface InputProps {
  name?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  prepend?: React.ReactNode;
  propend?: React.ReactNode;
  errorText?: boolean | string | FormikErrors<unknown>;
  size?: 'large';
  value?: string | number;
  defaultValue?: string | number;
  readOnly?: boolean;
  required?: boolean;
  list?: string;
  ref?: string;
  className?: string;
  id?: string;
  currency?: boolean;
  errorTitle?: string;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onClick?(a: unknown): unknown;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  hasError,
  name,
  label,
  disabled,
  size,
  prepend,
  type,
  placeholder,
  errorText,
  value,
  defaultValue,
  onChange,
  readOnly,
  required,
  list,
  ref,
  className,
  id,
  onBlur,
  propend,
  onClick,
  currency,
  errorTitle,
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const theme = useTheme();

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (onBlur) onBlur(e);

    setHasFocus(false);
  }

  function handleAlertErrorTitle() {
    if (typeof errorTitle === 'string') {
      return errorTitle;
    }

    if (typeof errorText === 'string') {
      return errorText;
    }

    return undefined;
  }

  function handleShowAlert(isCurrency: boolean) {
    return (errorTitle || errorText) && isCurrency;
  }

  return (
    <Container hasError={hasError ?? false}>
      {label && <label htmlFor={name}>{label}</label>}
      <InputWrapper
        hasError={hasError ?? false}
        disabled={disabled ?? false}
        hasFocus={hasFocus}
        size={size}
        clickable={!!onClick}
        currency={currency}
      >
        {prepend}
        {handleShowAlert(currency) && (
          <Icons.Alert
            className="input-alert"
            color={theme.colors.red}
            size={22}
            title={handleAlertErrorTitle()}
          />
        )}
        <input
          type={type ?? 'text'}
          id={id ?? name}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setHasFocus(true)}
          onBlur={handleBlur}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly ?? false}
          required={required}
          list={list}
          ref={ref}
          onClick={onClick}
          className={className}
        />
        {handleShowAlert(!currency) && (
          <Icons.Alert
            className="input-alert"
            color={theme.colors.red}
            size={22}
            title={handleAlertErrorTitle()}
          />
        )}
        {propend}
      </InputWrapper>
      {errorText && <p className="input-error">{errorText}</p>}
    </Container>
  );
};

export default React.memo(Input);
