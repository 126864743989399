import { IMenu } from './models/domain/IMenu';

export const AUTH_TOKEN_KEY = btoa('@auth');
export const AUTH_TEMP_PWD_KEY = btoa('@auth-temp-pwd');
export const AUTH_PROFILE_KEY = btoa('@auth-profile');

export const MENU: IMenu[] = [
  {
    text: 'Dashboard',
    path: '/',
  },
  {
    text: 'Valores a Identificar',
    path: '/vi',
  },
  {
    text: 'Conciliações',
    path: '/conciliacoes',
  },
  {
    group: 'Configurações',
    text: 'Empresas',
    path: '/configuracoes/empresas',
    permission: {
      group: 'Configurações',
      subgroup: 'Empresa',
      functionality: 'Listar Empresas',
    },
  },
  {
    group: 'Configurações',
    text: 'Empresas Cedentes',
    path: '/configuracoes/empresas-cedentes',
    permission: {
      group: 'Configurações',
      subgroup: 'Empresa Cedente',
      functionality: 'Listar Empresas Cedentes',
    },
  },
  {
    group: 'Configurações',
    text: 'Usuários',
    path: '/configuracoes/usuarios',
    permission: {
      group: 'Configurações',
      subgroup: 'Usuários',
      functionality: 'Listar Usuários',
    },
  },
  {
    group: 'Configurações',
    text: 'Perfis',
    path: '/configuracoes/perfis',
    permission: {
      group: 'Configurações',
      subgroup: 'Perfis',
      functionality: 'Listar Perfis',
    },
  },
  {
    group: 'Configurações',
    text: 'Auditoria',
    path: '/configuracoes/auditoria',
    permission: {
      group: 'Configurações',
      subgroup: 'Auditoria',
      functionality: 'Auditoria',
    },
  },
];

export const PERMISSIONS_ORDER = [
  'Valores a Identificar',
  'Conciliação VI',
  'Exportações',
  'Configurações',
];
