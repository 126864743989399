import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ id }) => {
  return (
    <ReactTooltip
      id={id}
      place="top"
      type="dark"
      effect="solid"
      multiline
      delayShow={300}
    />
  );
};

export default Tooltip;
