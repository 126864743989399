import { AxiosInstance, CancelToken } from 'axios';

import { ICompany } from '@models/domain/ICompany';
import IList from '@models/util/IList';
import { IResponse } from '@models/util/IResponse';

import { ICompanyService } from './interfaces/ICompanyService';

const ENDPOINT = '/api/v1/empresas-cedentes';

export class AssignorCompanyService implements ICompanyService {
  constructor(private api: AxiosInstance) {}

  async list(page: number, amount = 10, cancelToken?: CancelToken) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IResponse<ICompany[]>>(
      ENDPOINT,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<ICompany[]>;
  }

  async listByQuery(query: string, cancelToken) {
    const { data } = await this.api.get<IResponse<ICompany[]>>(
      `${ENDPOINT}?criterio=${query}`,
      {
        cancelToken,
      }
    );

    return data;
  }

  async get(id: string) {
    const { data } = await this.api.get<IResponse<ICompany>>(
      `${ENDPOINT}/${id}`
    );

    return data;
  }
}
