import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';

import { useAuth } from '@hooks/useAuth';

import ChangePassword from '../../ChangePassword';
import { Icons } from '../../Dictionaries/Icons';
import Dropdown from '../../Dropdown';
import Modal from '../../Modal';
import { Content, User, Box } from './styles';

const Navbar: React.FC = () => {
  const { user, handleLogout, isTemporaryPassword } = useAuth();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [settings, setSettings] = useState(isTemporaryPassword());

  function handleDropdown() {
    setOpenDropdown((state: boolean) => !state);
  }

  function handleSettings() {
    setSettings((state: boolean) => !state);
    setOpenDropdown(false);
  }

  return (
    <Content>
      <User>
        <OutsideClickHandler onOutsideClick={() => setOpenDropdown(false)}>
          <div
            className="user-wrapper"
            tabIndex={0}
            role="button"
            onClick={handleDropdown}
            onKeyDown={handleDropdown}
          >
            <Box>
              Olá, <strong>{user?.nome}</strong>
            </Box>
            <Icons.ArrowDownMenu />
          </div>

          {openDropdown && (
            <Dropdown>
              <li>
                <span
                  onClick={handleSettings}
                  onKeyDown={handleSettings}
                  role="button"
                  tabIndex={0}
                >
                  Configurações
                </span>
              </li>
              <li>
                <Link to="/login" className="logout" onClick={handleLogout}>
                  Sair
                </Link>
              </li>
            </Dropdown>
          )}
        </OutsideClickHandler>
      </User>
      {settings && (
        <Modal setOpenModal={setSettings} title="Configurações">
          <ChangePassword
            temporaryPassword={isTemporaryPassword()}
            setOpenModal={setSettings}
          />
        </Modal>
      )}
    </Content>
  );
};

export default Navbar;
