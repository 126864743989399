import React from 'react';
import { Link } from 'react-router-dom';

import Card from '@components/Card';
import { Icons } from '@components/Dictionaries/Icons';
import SpinnerLoading from '@components/Loading/SpinnerLoading';

import { masks } from '@helpers/mask';

import { LinkDetails, Resume } from './styles';

interface Props {
  number?: number;
  title: string;
  value: number;
  to: string;
  loading?: boolean;
}

const Total: React.FC<Props> = ({ number, title, value, to, loading }) => {
  return (
    <Card>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <>
          <Resume>
            <span className="amount">{number}</span>
            <div>
              <h2 className="title">{title}</h2>
              <p className="currency">{masks.currency.string(value)}</p>
            </div>
          </Resume>
          <LinkDetails>
            <Link className="link-detail" to={to}>
              Ir para página de detalhes <Icons.ArrowRight />
            </Link>
          </LinkDetails>
        </>
      )}
    </Card>
  );
};

export default Total;
