import styled from 'styled-components';

interface Props {
  hasError: boolean;
  hasFocus?: boolean;
}

interface InputProps extends Props {
  disabled: boolean;
  hasFocus: boolean;
  size?: 'large';
}

interface ButtonProps {
  isKeyHover?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: var(--gap-medium) 0;
  width: 100%;
  max-width: 600px;

  label {
    font-size: var(--fsz-small);
    margin-bottom: var(--gap-small);
    font-weight: bold;
  }

  .autocomplete-error {
    font-size: var(--fsz-small);
    color: ${(props) => props.theme.colors.red};
    margin-top: 5px;
    max-width: 500px;
    word-wrap: break-word;
    white-space: normal;
  }
`;

export const InputWrapper = styled.div<InputProps>`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 var(--gap-smaller);
  height: ${(props) => (props.size === 'large' ? 49 : 34)}px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey};

  ${(props) =>
    props.hasFocus && `border-color:  ${props.theme.colors.primary}`};
  ${(props) => props.hasError && `border-color:  ${props.theme.colors.red};`}
  ${(props) =>
    props.disabled &&
    `
      border-color:  ${props.theme.colors.grey};
      background:  ${props.theme.colors.background};
  `}

  input {
    flex: 1;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: var(--fsz-small);
    font-weight: 500;
    line-height: 18px;
    height: 100%;
    padding: 0 var(--gap-small);
  }

  div {
    margin-right: 5px;
  }
`;

export const List = styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  top: 60px;
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
`;

export const ListButton = styled.button<ButtonProps>`
  padding: 10px 5px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  background-color: ${(props) =>
    props.isKeyHover ? 'rgba(0, 0, 0, .1)' : 'transparent'};
  text-align: left;
  font-size: var(--fsz-small);
  font-weight: 500;
  line-height: 18px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;
