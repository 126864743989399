import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import GlobalLoading from '@components/Loading/GlobalLoading';

import { useAuth } from '@hooks/useAuth';
import { useLoading } from '@hooks/useLoading';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const Routes = () => {
  const { authenticated } = useAuth();
  const { globalLoading } = useLoading();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
      {globalLoading && <GlobalLoading />}
      {authenticated ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
};

export default Routes;
