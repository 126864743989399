import styled from 'styled-components';

interface Props {
  hasError?: boolean;
}

export const Container = styled.div<Props>`
  h3 {
    font-size: var(--fsz-small);
    margin-bottom: var(--gap-small);
    font-weight: bold;
    ${(props) => props.hasError && `color: ${props.theme.colors.red}`};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
    width: 25%;

    label {
      display: flex;
      align-items: center;
      ${(props) => props.hasError && `color: ${props.theme.colors.red}`};
    }

    input {
      margin-right: 5px;
    }
  }

  p {
    ${(props) => props.hasError && `color: ${props.theme.colors.red}`};
    font-size: var(--fsz-small);
    margin-top: var(--gap-small);
    max-width: 500px;
    word-wrap: break-word;
    white-space: normal;
  }
`;
