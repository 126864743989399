import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Input from '@components/Input';
import SpinnerLoading from '@components/Loading/SpinnerLoading';

import { PERMISSIONS_ORDER } from '@consts';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { sortByPosition } from '@helpers/sortByPosition';
import { IProfile, IProfileGroup } from '@models/domain/IProfile';
import { IResponse } from '@models/util/IResponse';
import { domainsService, profileService } from '@services/index';

import schema from './schema';
import { Form } from './styles';

interface Props {
  selectedProfile?: IProfile;
  closeModal(): void;
  refreshProfiles: () => void;
}

interface Permissao {
  nome: string;
  temPermissao: boolean;
  id: number;
}

const ProfileForm: React.FC<Props> = ({
  selectedProfile,
  closeModal,
  refreshProfiles,
}) => {
  const [profile, setProfile] = useState<IResponse<IProfile>>();
  const [permissoes, setPermissoes] = useState(0);
  const formRef = useRef<HTMLFormElement>();

  const getProfile = useCallback(
    async (cancelToken?) => {
      try {
        if (selectedProfile) {
          const data = await profileService.get(
            selectedProfile.id,
            cancelToken
          );

          setProfile(data);
        } else {
          const permissions = await domainsService.permissions(cancelToken);

          setProfile((prevState) => ({
            ...prevState,
            data: {
              ...prevState?.data,
              grupos: permissions,
            },
          }));
        }
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar perfil.');
      }
    },
    [selectedProfile]
  );

  const getPermissions = useCallback(() => {
    // as permissoes são passados para a api como a soma dos id
    // das permissões checadas como verdadeira, essa função
    // percorre as permissoes que são true e faz a soma

    if (profile) {
      profile?.data.grupos.map((grupo) => {
        grupo.permissoes.map((permissao) => {
          if (permissao.temPermissao)
            setPermissoes((state) => state + permissao.id);
          return null;
        });
        grupo.subGrupos.map((subgrupo) => {
          subgrupo.permissoes.map((permissao) => {
            if (permissao.temPermissao)
              setPermissoes((state) => state + permissao.id);
            return null;
          });
          return null;
        });
        return null;
      });
    }
  }, [profile]);

  useEffect(() => {
    const source = axiosCancelRequestSource();

    getProfile(source.token);

    return () => {
      source.cancel();
    };
  }, [getProfile]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  function handleCheckbox(
    e: React.ChangeEvent<HTMLInputElement>,
    permissao: Permissao
  ) {
    const { checked } = e.target;

    setPermissoes((prevState) =>
      checked ? prevState + permissao.id : prevState - permissao.id
    );
  }

  async function handleSubmit(data) {
    try {
      if (selectedProfile) {
        await profileService.edit(profile.data.id, data.nome, permissoes);
      } else {
        await profileService.register(data.nome, permissoes);
      }

      toast.success(`Perfil ${selectedProfile ? 'editado.' : 'criado.'}`);
      refreshProfiles();
      closeModal();
    } catch (error) {
      handleRequestError(error);
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      nome: selectedProfile?.nome || '',
    },
  });

  function formReset() {
    formik.resetForm();
    formRef.current.reset();
  }

  const getPermissionOrder = (item: IProfileGroup) =>
    PERMISSIONS_ORDER.indexOf(item.nome);

  if (profile === undefined) return <SpinnerLoading />;

  return (
    <Form onSubmit={formik.handleSubmit} ref={formRef}>
      <div className="form-wrapper">
        <Input
          label="Nome *"
          name="nome"
          value={formik.values.nome}
          onChange={formik.handleChange}
          placeholder="Nome do Perfil"
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.nome && formik.errors.nome)}
          errorText={formik.touched.nome && formik.errors.nome}
        />
        {sortByPosition<IProfileGroup>(
          profile?.data?.grupos,
          getPermissionOrder
        )?.map((grupo) => {
          return (
            <div key={grupo.nome}>
              <h2>{grupo.nome}</h2>
              {grupo.subGrupos.map((subgrupo) => {
                return (
                  <div key={subgrupo.nome}>
                    <h3>{subgrupo.nome}</h3>
                    <ul className="subgroups">
                      {subgrupo.permissoes.map((permissao) => {
                        return (
                          <li key={permissao.id}>
                            <input
                              onChange={(e) => handleCheckbox(e, permissao)}
                              defaultChecked={permissao.temPermissao}
                              type="checkbox"
                              name={permissao.nome}
                              id={permissao.nome}
                            />
                            <label htmlFor={permissao.nome}>
                              {permissao.nome}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
              <ul className="permissions">
                {grupo.permissoes.map((permissao) => {
                  return (
                    <li key={permissao.id}>
                      <br />
                      <input
                        onChange={(e) => handleCheckbox(e, permissao)}
                        defaultChecked={permissao.temPermissao}
                        type="checkbox"
                        name={permissao.nome}
                        id={permissao.nome}
                      />
                      <label htmlFor={permissao.nome}>{permissao.nome}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className="buttons">
        <Button
          color="warning"
          onClick={formReset}
          disabled={formik.isSubmitting}
        >
          Limpar
        </Button>

        <Button type="submit" disabled={formik.isSubmitting}>
          Salvar
        </Button>
      </div>
    </Form>
  );
};

export default ProfileForm;
