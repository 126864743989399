import React from 'react';

import { Container } from './styles';

interface Props {
  currentStep: number;
  prevStep: number;
}

const Steps: React.FC<Props> = ({ currentStep, prevStep }) => {
  function checkStep(step: number) {
    if (currentStep > step) {
      return 'done';
    }
    if (currentStep === step) {
      return 'active';
    }

    return '';
  }

  return (
    <Container currentStep={currentStep} prevStep={prevStep}>
      <div className={`steps ${checkStep(1)}`} id="step1">
        <div className="step">
          <span>1</span>
          <div className="bar right" />
        </div>
        <p>Seleção de Empresa</p>
      </div>
      <div className={`steps ${checkStep(2)}`} id="step2">
        <div className="step">
          <div className="bar left" />
          <span>2</span>
          <div className="bar right" />
        </div>
        <p>Seleção de Valores</p>
      </div>
      <div className={`steps ${checkStep(3)}`} id="step3">
        <div className="step">
          <div className="bar left" />
          <span>3</span>
        </div>
        <p>Vinculação de Títulos</p>
      </div>
    </Container>
  );
};

export default Steps;
