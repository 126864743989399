import React from 'react';

import { handleLogo } from '@helpers/handleLogo';

import { Container, Content, Button, ErrorContainer } from './styles';

export const ErrorBoundaryFallback = () => {
  function backPage() {
    window.history.back();
  }

  function refreshPage() {
    window.location.reload();
  }

  return (
    <Container>
      <div>
        <img src={handleLogo()?.full} alt="logo-modec" className="logo-full" />
        <Content>
          <h1>Ops! Algo de errado ocorreu</h1>
          <ErrorContainer>
            <p>
              Desculpe, algo de errado aconteceu nesta página. Já estamos
              tentando corrigir o problema.
            </p>
          </ErrorContainer>
          <div className="buttons">
            <Button type="button" onClick={backPage}>
              Voltar página
            </Button>
            <Button type="button" onClick={refreshPage}>
              Recarregar página
            </Button>
          </div>
        </Content>
      </div>
    </Container>
  );
};
