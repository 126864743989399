import styled from 'styled-components';

type Props = {
  retract?: boolean;
  logo?: string;
};

export const Content = styled.aside<Props>`
  grid-area: aside;
  padding-top: 10px;
  box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: ${(props) => (props.retract ? '70px' : '250px')};
  transition: all 250ms;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .retract-arrow {
    position: fixed;
    left: ${(props) => (props.retract ? '70px' : '250px')};
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    transition: all 250ms;
    font-size: 1.5rem;
  }

  .logo {
    height: 75px;
    margin: 15px 2px 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .full {
      display: ${(props) => (props.retract ? 'none' : 'block')};
      animation: menuFull 500ms;
    }

    .retract {
      display: ${(props) => (props.retract ? 'block' : 'none')};
      animation: menuRetract 500ms;
    }

    img {
      width: ${(props) =>
        props.retract || props.logo === 'koperar' ? '100%' : '75%'};
    }
  }

  nav {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    ul {
      height: 100%;
    }
  }

  @keyframes menuFull {
    0% {
      opacity: 0;
      transform: translateX(-120px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes menuRetract {
    0% {
      opacity: 0;
      transform: translateX(180px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (max-width: 1280px) {
    width: ${(props) => (props.retract ? '60px' : '225px')};

    .retract-arrow {
      left: ${(props) => (props.retract ? '60px' : '225px')};
    }
  }
`;
