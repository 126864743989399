import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;

  button {
    padding: 2px;
    margin: 0 4px;
    background-color: transparent;
    border-radius: var(--gap-smaller);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    svg {
      background-color: transparent;
      font-size: 18px;
    }
  }
`;
