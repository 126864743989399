import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 10px;
  margin-bottom: 15px;

  h2 {
    font-weight: bold;
  }
`;
