import React from 'react';
import { NavLink as RouteNav } from 'react-router-dom';

import { useMenu } from '@hooks/useMenu';

import { List, Children } from './styles';

interface NavLinkProps {
  icon?: React.ReactNode;
  title: string;
  to: string;
  className?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  icon: Icon,
  title,
  to,
  children,
  className,
}) => {
  const { retract } = useMenu();

  return (
    <>
      <List retract={retract}>
        <RouteNav exact to={to} className={className}>
          {Icon}

          <span>{title}</span>
        </RouteNav>
      </List>
      <Children retract={retract}>{children}</Children>
    </>
  );
};

export default NavLink;
