import { AxiosInstance, CancelToken } from 'axios';

import { clearObject } from '@helpers/clearObject';
import { IAudit, IAuditFilter } from '@models/domain/IAudit';
import IPaginate from '@models/util/IPaginate';

import { IAuditService } from './interfaces/IAuditService';

export class AuditService implements IAuditService {
  constructor(private readonly api: AxiosInstance) {}

  ENDPOINT = '/api/v1/auditoria';

  async list(
    page: number,
    amount: number,
    filters?: IAuditFilter,
    cancelToken?: CancelToken
  ) {
    const cleanFilters = clearObject(filters);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let params: any = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    if (cleanFilters) {
      params = {
        ...params,
        ...cleanFilters,
        empresa: cleanFilters.empresa?.razaoSocial,
        empresaCedente: cleanFilters.empresaCedente?.razaoSocial,
      };
    }

    if (!params?.empresa) delete params.empresa;
    if (!params?.empresaCedente) delete params.empresaCedente;

    const { data, headers } = await this.api.get<IAudit[]>(
      `${this.ENDPOINT}/auditoria`,
      {
        params,
        cancelToken,
      }
    );

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IPaginate<IAudit[]>;
  }

  async export(ids: string) {
    const { data, headers } = await this.api.get(
      `${this.ENDPOINT}/exportar-auditoria?ids=${ids}`,
      {
        responseType: 'blob',
      }
    );

    const filename = decodeURIComponent(
      headers['content-disposition'].split("''")[1]
    );

    return {
      data,
      filename,
    };
  }
}
