import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { api } from '@services/api';

interface Context {
  globalLoading: boolean;
}

export const LoadingContext = createContext<Context>({} as Context);

export const LoadingProvider: React.FC = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(false);

  const handleGlobalLoading = useCallback(() => {
    api.interceptors.request.use(
      (req) => {
        setGlobalLoading(true);
        return req;
      },
      (error) => {
        setGlobalLoading(false);
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (res) => {
        setTimeout(() => {
          setGlobalLoading(false);
        }, 200);
        return res;
      },
      (error) => {
        setGlobalLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    handleGlobalLoading();
  }, [handleGlobalLoading]);

  return (
    <LoadingContext.Provider
      value={{
        globalLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  return useContext(LoadingContext);
}
