import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@components/Button';
import Card from '@components/Card';
import { Icons } from '@components/Dictionaries/Icons';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { useAudit } from '@hooks/useAudit';
import { IAudit, IAuditFilter } from '@models/domain/IAudit';

import Filter from './Filter';
import AuditList from './List';

interface AuditUrlParam {
  metadataId?: string;
}

const Audit = () => {
  const { metadataId } = useParams<AuditUrlParam>();
  const { listAudit, exportAudit } = useAudit();
  const [selected, setSelected] = useState<string[]>();
  const [audits, setAudits] = useState<IAudit[]>();
  const [auditsLoading, setAuditsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cancelExport, setCancelExport] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState<IAuditFilter>({
    metadataId: metadataId ?? '',
    dataHoraInicio: '',
    dataHoraFim: '',
    empresa: {
      razaoSocial: '',
      id: '',
    },
    empresaCedente: {
      razaoSocial: '',
      id: '',
    },
    usuario: '',
    operacao: '',
    valor: '',
  });

  useEffect(() => {
    if (cancelExport && selected?.length <= 0) {
      setCancelExport(false);
    }
  }, [cancelExport, selected]);

  const fetchAudits = useCallback(
    async (cancelToken?) => {
      setAuditsLoading(true);

      try {
        const { rows, count } = await listAudit(
          page,
          rowsPerPage,
          filters,
          cancelToken
        );

        setAudits(rows);
        setRowsAmount(count);
      } catch (error) {
        setAudits(null);
      } finally {
        setAuditsLoading(false);
      }
    },
    [filters, listAudit, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    fetchAudits(source.token);

    return () => {
      source.cancel();
    };
  }, [fetchAudits]);

  async function handleClickExport() {
    try {
      const selectedAudits = selected.join('&ids=');
      const { data, filename } = await exportAudit(selectedAudits);

      saveAs(new Blob([data], { type: 'application/octet-stream' }), filename);
    } catch {
      return null;
    }
    return null;
  }

  function handleCancelExport() {
    setCancelExport(true);
    setSelected([]);
  }

  return (
    <Card>
      <header>
        <h1>Auditoria</h1>
        <div className="menu">
          <Button
            onClick={() => setOpenFilter(true)}
            flex
            disabled={auditsLoading}
          >
            <Icons.Filter />
            Filtrar
          </Button>
          {selected?.length > 0 && (
            <Button color="warning" onClick={handleCancelExport}>
              Cancelar
            </Button>
          )}
          <Button
            disabled={selected === undefined || selected.length <= 0}
            onClick={handleClickExport}
          >
            Exportar
          </Button>
        </div>
      </header>
      <AuditList
        audits={audits}
        auditsLoading={auditsLoading}
        selected={selected}
        setSelected={setSelected}
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
        setTablePage={setPage}
        setRowsPerPage={setRowsPerPage}
        cancelExport={cancelExport}
      />
      {openFilter && (
        <Modal title="Filtrar Auditoria" setOpenModal={setOpenFilter}>
          <Filter
            filters={filters}
            setFilters={setFilters}
            setOpenModal={setOpenFilter}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Audit;
