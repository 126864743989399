import axios from 'axios';

import { AUTH_TOKEN_KEY } from '@consts';
import { isDev } from '@helpers/detectEnvironment';

export const api = axios.create({
  baseURL: isDev()
    ? process.env.REACT_APP_API_URL
    : 'https://apifluxovi.azurewebsites.net',
});

api.interceptors.request.use((req) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  if (token) {
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return req;
});
