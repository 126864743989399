import React, { useRef, useState } from 'react';

import Button from '@components/Button';
import { Icons } from '@components/Dictionaries/Icons';
import Input from '@components/Input';
import Tooltip from '@components/Tooltip';

import { ITitle } from '@models/domain/ITitle';

interface Props {
  formikTitles: ITitle[];
  setTitlesValues(value): void;
  setOpenBlast(value: boolean): void;
}

const BlastForm: React.FC<Props> = ({
  formikTitles,
  setTitlesValues,
  setOpenBlast,
}) => {
  const [blastValue, setBlastValue] = useState('');
  const formRef = useRef<HTMLFormElement>();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    if (
      !/^(\s*|[0-9-])+$/.test(value) ||
      (value.length > 1 && Number.isNaN(+value))
    )
      return;

    setBlastValue(value);
  }

  function handleDiferencaByValorQuitado(title: ITitle, diferenca: number) {
    const { valorQuitado, quitacaoParcial } = title;

    if (diferenca > valorQuitado && quitacaoParcial) {
      return Number(valorQuitado);
    }

    if (diferenca > valorQuitado && !quitacaoParcial) {
      return +valorQuitado;
    }

    return diferenca;
  }

  function handleValorQuitado(title: ITitle, diferenca: number) {
    if (title.quitacaoParcial) {
      return title.valorQuitado;
    }

    return +title.valorResidual + diferenca;
  }

  function handleSubmit() {
    setOpenBlast(false);

    setTitlesValues(
      formikTitles.map((title) => {
        const diferenca = +title.diferencaInput;
        const percent = +blastValue;
        let diferencaInput = diferenca + diferenca * (percent / 100);

        if (diferencaInput < 0) {
          diferencaInput = 0;
        }

        diferencaInput = handleDiferencaByValorQuitado(title, diferencaInput);
        const valorQuitado = handleValorQuitado(title, diferencaInput);

        return {
          ...title,
          diferencaInput,
          valorQuitado,
        };
      })
    );
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="form-wrapper">
        <p style={{ width: 300 }}>
          Alteração em Rajada é um valor percentual que ajusta todos os <br />
          campos de Diferença da tabela (para mais ou para menos)
        </p>
        <Input
          type="text"
          label="Valor (em porcentagem)"
          placeholder="ex: 100"
          value={blastValue}
          onChange={handleChange}
          propend={
            <>
              <Icons.Question
                // eslint-disable-next-line max-len
                title="Alteração em Rajada é um valor percentual que ajusta todos os campos de Diferença da tabela (para mais ou para menos)"
                titleFor="question"
              />
              <Tooltip id="question" />
            </>
          }
        />
      </div>
      <div className="buttons">
        <Button color="warning" onClick={() => setOpenBlast(false)}>
          Cancelar
        </Button>
        <Button onClick={handleSubmit} disabled={!blastValue}>
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default BlastForm;
