import React from 'react';

import { Icons } from '../../../Dictionaries/Icons';
import Tooltip from '../../../Tooltip';

interface Props {
  path: string;
}

const NavIcon: React.FC<Props> = ({ path }) => {
  // Este componente é apenas uma condicional para os itens da api do menu

  switch (path) {
    case '/':
      return (
        <>
          <Icons.Dashboard />
          <Tooltip id="Dashboard" />
        </>
      );
    case '/vi':
      return (
        <>
          <Icons.VIs />
          <Tooltip id="Valores a Identificar" />
        </>
      );
    case '/conciliacoes':
      return (
        <>
          <Icons.Conciliations />
          <Tooltip id="Conciliações" />
        </>
      );
    case '/configuracoes/empresas':
      return (
        <>
          <Icons.Companies />
          <Tooltip id="Empresas" />
        </>
      );
    case '/configuracoes/empresas-cedentes':
      return (
        <>
          <Icons.AssignorCompanies />
          <Tooltip id="cedentes" />
        </>
      );
    case '/configuracoes/usuarios':
      return (
        <>
          <Icons.Users />
          <Tooltip id="Usuários" />
        </>
      );
    case '/configuracoes/perfis':
      return (
        <>
          <Icons.Profiles />
          <Tooltip id="perfis" />
        </>
      );
    case '/configuracoes/auditoria':
      return (
        <>
          <Icons.Audit />
          <Tooltip id="auditoria" />
        </>
      );
    default:
      return (
        <>
          <Icons.Settings />
          <Tooltip id="configuracoes" />
        </>
      );
  }
};

export default NavIcon;
