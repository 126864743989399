import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import { handleRequestError } from '@helpers/handleRequestError';
import { userService } from '@services/index';

import Button from '../Button';
import { PasswordInput } from '../PasswordInput';
import schema from './schema';
import { Container } from './styles';

interface Props {
  temporaryPassword: boolean;
  setOpenModal(state: boolean): void;
}

interface IPassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC<Props> = ({
  temporaryPassword,
  setOpenModal,
}) => {
  useEffect(() => {
    if (temporaryPassword) {
      toast.warning(
        'Login com Senha Temporária detectado. Favor trocar a senha.'
      );
    }
  }, [temporaryPassword]);

  const handleSubmit = async (data: IPassword) => {
    if (data.currentPassword === data.newPassword) {
      toast.error('Nova Senha deve ser diferente da Senha Atual.');

      return;
    }

    try {
      await userService.changePassword(data.currentPassword, data.newPassword);

      setOpenModal(false);
      toast.success('Senha trocada com sucesso.');

      if (temporaryPassword) userService.deleteTemporaryPassword();
    } catch (error) {
      handleRequestError(error, 'Confira as informações inseridas.');
    }
  };

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-wrapper">
          <h2>Trocar senha</h2>
          <PasswordInput
            onChange={formik.handleChange}
            value={formik.values.currentPassword}
            name="currentPassword"
            label="Senha atual*:"
            onBlur={formik.handleBlur}
            hasError={
              !!(
                formik.touched.currentPassword && formik.errors.currentPassword
              )
            }
            errorText={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
          />
          <PasswordInput
            onChange={formik.handleChange}
            value={formik.values.newPassword}
            name="newPassword"
            label="Nova Senha*:"
            onBlur={formik.handleBlur}
            hasError={
              !!(formik.touched.newPassword && formik.errors.newPassword)
            }
            errorText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <PasswordInput
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            name="confirmPassword"
            label="Confirme a Nova Senha*:"
            onBlur={formik.handleBlur}
            hasError={
              !!(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              )
            }
            errorText={
              formik.touched.confirmPassword &&
              formik.errors.confirmPassword &&
              formik.errors.confirmPassword
            }
          />
        </div>
        <div className="buttons">
          <Button
            color="warning"
            onClick={() => formik.resetForm()}
            disabled={formik.isSubmitting || !formik.dirty}
          >
            Limpar
          </Button>
          <Button type="submit" disabled={formik.isSubmitting || !formik.dirty}>
            Trocar senha
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default ChangePassword;
