import React from 'react';

import { Container } from './styles';

interface Props {
  title?: string;
  hasError?: boolean;
  errorText?: string;
}

const Radio: React.FC<Props> = ({ title, hasError, errorText, children }) => {
  return (
    <Container hasError={hasError}>
      <h3>{title}</h3>
      <div>{children}</div>
      <p>{errorText}</p>
    </Container>
  );
};

export default Radio;
