import React from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';
import TableButtons from '@components/TableButtons';

import { masks } from '@helpers/mask';
import { IUser } from '@models/domain/IUser';
import { IResponse } from '@models/util/IResponse';

interface Props {
  users: IResponse<IUser[]>;
  rowsAmount: number;
  rowsPerPage: number;
  usersLoading: boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  handleDeleteUser(user: IUser): void;
  handleEditUser(user: IUser): void;
}

const UsersList: React.FC<Props> = ({
  users,
  setPage,
  setRowsPerPage,
  rowsAmount,
  rowsPerPage,
  handleDeleteUser,
  handleEditUser,
  usersLoading,
}) => {
  return (
    <Table
      setTablePage={setPage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      isLoading={usersLoading && !users}
      columns={[
        {
          name: 'Ação',
          selector: 'acao',
          width: '85px',
          omit: !(users?.acoes.excluir || users?.acoes.alterar),
        },
        {
          name: 'Nome',
          selector: 'nome',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.nome} />,
        },
        {
          name: 'Perfil',
          selector: 'perfil',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.perfil} />,
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.email} />,
        },
        {
          name: 'CPF',
          selector: 'cpf',
          sortable: true,
          minWidth: '130px',
          cell: (row) => <SpanTable id={row.id} text={row.cpf} />,
        },
        {
          name: 'Nascimento',
          selector: 'nascimento',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.nascimento} />,
        },
        {
          name: 'Telefone',
          selector: 'telefone',
          sortable: true,
          minWidth: '150px',
          cell: (row) => <SpanTable id={row.id} text={row.telefone} />,
        },
        {
          name: 'Ramal',
          selector: 'ramal',
          sortable: true,
          minWidth: '50px',
          cell: (row) => <SpanTable id={row.id} text={row.ramal} />,
        },
        {
          name: 'Celular',
          selector: 'celular',
          sortable: true,
          minWidth: '150px',
          cell: (row) => <SpanTable id={row.id} text={row.celular} />,
        },
      ]}
      content={users?.data.map((user) => {
        return {
          id: user.id,
          nome: user.nome,
          perfil: user.perfil.nome,
          email: user.email,
          cpf: masks.cpf(user.cpf),
          nascimento: masks.date.string(user.nascimento),
          telefone: masks.telephone(user.telefone) || '-',
          ramal: user.ramal || '-',
          celular: masks.cellphone(user.celular) || '-',
          acao: (
            <TableButtons
              id={user.id}
              acoes={users.acoes}
              tableName="Usuário"
              confirmMessage="Tem certeza que deseja deletar o Usuário?"
              submitting={users === undefined}
              handleDelete={() => handleDeleteUser(user)}
              handleEdit={() => handleEditUser(user)}
            />
          ),
        };
      })}
    />
  );
};

export default UsersList;
