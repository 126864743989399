import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { CancelToken } from 'axios';

import { handleRequestError } from '@helpers/handleRequestError';
import { IUser } from '@models/domain/IUser';
import { IListResponse } from '@models/util/IListResponse';
import { userService } from '@services/index';

interface Hook {
  listUsers(
    page: number,
    rowsPerPage: number,
    cancelToken?: CancelToken
  ): Promise<IListResponse<IUser[]>>;
  deleteUser(user: IUser): Promise<void>;
  listAllUsers(): Promise<IUser[]>;
  getUserById: (users: IUser[], id: string) => IUser;
}

export function useUsers(): Hook {
  const listUsers = useCallback(
    async (page: number, rowsPerPage: number, cancelToken?) => {
      try {
        const { rows, count } = await userService.list(
          page,
          rowsPerPage,
          cancelToken
        );

        return {
          rows,
          count,
        };
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar usuários.');
      }

      return null;
    },
    []
  );

  const listAllUsers = useCallback(async () => {
    try {
      const response = await userService.listAll();

      return response;
    } catch (error) {
      handleRequestError(error, 'Erro ao carregar usuários.');
    }

    return null;
  }, []);

  async function deleteUser(user: IUser) {
    try {
      await userService.delete(user.id);

      toast.info('Usuário deletado.');
    } catch (error) {
      handleRequestError(error, 'Erro ao deletar usuário.');
    }
  }

  const getUserById = (users: IUser[], id: string) => {
    return users?.find((user) => user.id === id);
  };

  return {
    listUsers,
    deleteUser,
    listAllUsers,
    getUserById,
  };
}
