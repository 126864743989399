import styled from 'styled-components';

export const Form = styled.form`
  overflow: auto;

  h2 {
    margin: var(--gap-medium) 0 var(--gap-small) 0;
    font-size: var(--fsz-medium);
    font-weight: bold;
  }

  h3 {
    margin-top: var(--gap-medium);
    font-size: var(--fsz-small);
    margin-bottom: var(--gap-small);
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      margin-bottom: var(--gap-medium);

      label {
        font-size: var(--fsz-medium);
        margin: 0 var(--gap-small);
      }
    }
  }
  .permissions {
    margin-top: var(--gap-medium);
  }
`;
