import styled from 'styled-components';

export const LinkDetails = styled.div`
  .link-detail {
    display: flex;
    align-items: center;
    padding-top: var(--gap-medium);
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    font-size: var(--fsz-medium);
  }
`;

export const Resume = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};

  .amount {
    font-size: 2.6rem;
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    margin-right: var(--gap-medium);
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 50px;
  }

  .title {
    white-space: normal;
    font-size: var(--fsz-big);
  }

  .currency {
    font-size: var(--fsz-bigger);
    margin: var(--gap-smaller) 0;
  }
`;
