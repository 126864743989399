import React, { useCallback, useEffect, useState } from 'react';

import Card from '@components/Card';
import Modal from '@components/Modal';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { useConfigurations } from '@hooks/useConfigurations';
import { IConfiguration } from '@models/domain/IConfiguration';

import ConfigurationsForm from './Form';
import ConfigurationsList from './List';

const Configurations = () => {
  const { list } = useConfigurations();
  const [configurations, setConfigurations] = useState<IConfiguration[]>();
  const [configurationsLoading, setConfigurationsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<IConfiguration>();
  const [openEdit, setOpenEdit] = useState(false);

  const listConfigurations = useCallback(
    async (cancelToken?) => {
      setConfigurationsLoading(true);

      try {
        const response = await list(page, rowsPerPage, cancelToken);

        setConfigurations(response?.rows);
        setRowsAmount(response?.count);
      } catch {
        setConfigurations([]);
      } finally {
        setConfigurationsLoading(false);
      }
    },
    [list, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    listConfigurations(source.token);

    return () => {
      source.cancel();
    };
  }, [listConfigurations]);

  return (
    <Card>
      <header>
        <h1>Configurações</h1>
      </header>
      <ConfigurationsList
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
        setTablePage={setPage}
        setRowsPerPage={setRowsPerPage}
        setOpenEdit={setOpenEdit}
        setSelected={setSelected}
        configurations={configurations}
        configurationsLoading={configurationsLoading}
      />
      {openEdit && (
        <Modal title="Editar Configuração" setOpenModal={setOpenEdit}>
          <ConfigurationsForm
            configurations={selected}
            setOpenEdit={setOpenEdit}
            reloadConfigurations={listConfigurations}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Configurations;
