import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Filter from '@components/Filter';
import SpinnerLoading from '@components/Loading/SpinnerLoading';

import { useCompanies } from '@hooks/useCompanies';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { ICompany } from '@models/domain/ICompany';

import schema from './schema';
import { FormStep1 } from './styles';

interface Props {
  setCurrentStep?(step: number): void;
  setConciliation(obj: unknown): void;
}

interface ISubmit {
  empresa: {
    id: string;
    razaoSocial: string;
  };
  empresaCedente: { id: string; razaoSocial: string };
}

const FirstStep: React.FC<Props> = ({ setCurrentStep, setConciliation }) => {
  const history = useHistory();
  const {
    listAllCompanies,
    listAllAssignorCompanies,
    getCompanyId,
    getCompanyName,
  } = useCompanies();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [assignorCompanies, setAssignorCompanies] = useState<
    IAssignorCompany[]
  >();

  const listAllCompaniesCb = useCallback(async () => {
    const response = await listAllCompanies();

    setCompanies(response);
  }, [listAllCompanies]);

  const listAllAssignorCompaniesCb = useCallback(async () => {
    const response = await listAllAssignorCompanies();

    setAssignorCompanies(response);
  }, [listAllAssignorCompanies]);

  useEffect(() => {
    listAllCompaniesCb();
    listAllAssignorCompaniesCb();
  }, [listAllAssignorCompaniesCb, listAllCompaniesCb]);

  function handleSubmit(data: ISubmit) {
    setCurrentStep(2);
    setConciliation(data);
  }

  useEffect(() => {
    setConciliation({});
  }, [setConciliation]);

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      empresa: {
        razaoSocial: '',
        id: '',
      },
      empresaCedente: {
        razaoSocial: '',
        id: '',
      },
    },
  });

  if (companies === undefined || assignorCompanies === undefined) {
    return <SpinnerLoading />;
  }

  return (
    <>
      <FormStep1 onSubmit={formik.handleSubmit}>
        <Filter
          label="Nome da Empresa *"
          name="empresa"
          placeholder="ex: Empresa 1"
          onChange={(e) => formik.setFieldValue('empresa', e)}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.empresa && formik.errors.empresa)}
          errorText={formik.touched.empresa && formik.errors.empresa?.id}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresa}
          list={companies}
        />

        <Filter
          label="Nome da Empresa Cedente"
          name="empresaCedente"
          placeholder="ex: Empresa Cedente 1"
          onChange={(e) => formik.setFieldValue('empresaCedente', e)}
          onBlur={formik.handleBlur}
          hasError={
            !!(formik.touched.empresaCedente && formik.errors.empresaCedente)
          }
          errorText={
            formik.touched.empresaCedente && formik.errors.empresaCedente?.id
          }
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresaCedente}
          list={assignorCompanies}
        />
      </FormStep1>
      <div className="buttons">
        <div className="buttons-wrapper">
          <Button onClick={() => history.goBack()} color="warning" outline>
            Cancelar
          </Button>
          <Button
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting || !formik.dirty}
          >
            Próximo
          </Button>
        </div>
      </div>
    </>
  );
};

export default FirstStep;
