import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: #fff;
  min-width: 620px;
  max-width: 90%;
  min-height: 128px;
  max-height: 90%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas: 'header' 'main';
  border-radius: 2px;

  & > header {
    grid-area: header;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
    padding: 0 var(--gap-big);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.text};
    font-size: var(--fsz-bigger);
    font-weight: bold;
    background-color: white;

    svg {
      cursor: pointer;
    }
  }

  & > main {
    color: ${(props) => props.theme.colors.text};
    height: 100%;
    width: 100%;
    grid-area: main;
    display: flex;
    flex-direction: column;
    overflow: auto;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .form-wrapper {
        padding: var(--gap-medium) var(--gap-big);
        width: 100%;
        overflow: auto;
        min-height: 80px;

        /* .select-list {
          position: absolute;
          top: 60px;
          left: 0;
        } */
      }

      .buttons {
        padding: var(--gap-medium) 0;
        margin-right: 25px;
        align-self: flex-end;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
`;
