import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Input from '@components/Input';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { SendersEnum } from '@enums/senders.enum';
import { TransactionsEnum } from '@enums/transactions.enum';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { IVIConciliation } from '@models/domain/IConciliation';
// import { IConciliationVis, IVI } from '@models/domain/IVI';
import { IVI } from '@models/domain/IVI';
import { IRouteWithId } from '@models/util/IRouteParams';
import { conciliationService } from '@services/index';

interface Props {
  vis: IVI[];
  rowsAmount: number;
  rowsPerPage: number;
  isEdit: boolean;
  formikVis: IVIConciliation[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
  setFieldValue(name: string, value: unknown): void;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
}

const VIConciliations: React.FC<Props> = ({
  vis,
  rowsAmount,
  rowsPerPage,
  isEdit,
  formikVis,
  formik,
  setTablePage,
  setRowsPerPage,
  setFieldValue,
}) => {
  const { id: conciliationId } = useParams<IRouteWithId>();
  const [selectedVIS, setSelectedVIS] = useState<IVI[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSelected = useCallback(
    ({ selectedRows }) => {
      setSelectedVIS(selectedRows);

      setFieldValue(
        'valoresAIdentificarConciliacao',
        selectedRows.map((vi: IVI) => {
          const formikVi = formikVis.find(
            (item) => item.valoresAIdentificarId === vi.id
          );

          return {
            valoresAIdentificarId: formikVi?.valoresAIdentificarId ?? vi.id,
            valor:
              formikVi?.valor ??
              vi.valorConciliar ??
              vi.saldo ??
              vi.valor - (vi.valorConciliado ?? 0),
            valorADevolver: formikVi?.valorADevolver ?? vi.valorADevolver ?? 0,
          };
        })
      );
    },
    [formikVis, setFieldValue]
  );

  const handleBalance = useCallback((vi) => {
    return vi.saldo;
    // return conciliationId ? vi.saldo + fieldsTotal(vi) : vi.saldo;
  }, []);

  const getConciliationVis = useCallback(
    async (id: string, cancelToken?) => {
      setLoading(true);

      try {
        const { rows } = await conciliationService.listVis(id, cancelToken);

        // setFetchedVis(rows);
        setFieldValue(
          'valoresAIdentificarConciliacao',
          rows.map((vi) => ({
            valoresAIdentificarId: vi.id,
            valor: vi.valorAConciliar,
            valorADevolver: vi.valorADevolver,
          }))
        );
      } catch (error) {
        handleRequestError(
          error,
          'Erro ao carregar VIs',
          'VIs não encontrados para esta conciliação'
        );
      } finally {
        setLoading(false);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (conciliationId) {
      getConciliationVis(conciliationId);
    }

    return () => {
      source.cancel();
    };
  }, [getConciliationVis, conciliationId]);

  const handleListInput = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      vi: IVI,
      formikVi: IVIConciliation
    ) => {
      const { value, name, classList } = e.target;
      const valueSanitized = masks.sanitize.number(masks.decimal(value));
      const balance = handleBalance(vi);

      if (
        (classList.contains('valorADevolver') &&
          formikVi?.valor + valueSanitized > balance) ||
        (classList.contains('valorConciliar') &&
          formikVi?.valorADevolver + valueSanitized > balance)
      ) {
        return;
      }

      setFieldValue(name, valueSanitized);
    },
    [handleBalance, setFieldValue]
  );

  const refreshLiquidation = useCallback(() => {
    const liquidation = {
      tipo: selectedVIS[0]?.remetente.tipo ?? '',
      pagadorRazaoSocialLiquidacao:
        selectedVIS[0]?.remetente?.razaoSocial ?? '',
      pagadorCpfCnpjLiquidacao: selectedVIS[0]?.remetente?.cnpj ?? '',
    };

    setFieldValue('liquidacao', liquidation);
  }, [selectedVIS, setFieldValue]);

  useEffect(() => {
    refreshLiquidation();
  }, [refreshLiquidation]);

  return (
    <Table
      selectableRows
      onSelectedRowsChange={handleSelected}
      setTablePage={setTablePage}
      isLoading={vis === undefined || loading}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      selectableRowSelected={(row: IVI) =>
        formikVis?.find((item) => item.valoresAIdentificarId === row.id)
          ?.valoresAIdentificarId === row.id && row.id !== undefined
      }
      selectableRowDisabled={(row: IVI) =>
        selectedVIS.length > 0 &&
        (row.remetente.cnpj !== selectedVIS[0].remetente.cnpj ||
          row.remetente.tipo !== selectedVIS[0].remetente.tipo ||
          row.remetente.razaoSocial !== selectedVIS[0].remetente.razaoSocial)
      }
      columns={[
        { name: 'Data', selector: 'data', sortable: true, minWidth: '85px' },
        {
          name: 'Tipo Remetente',
          selector: 'remetente.tipo',
          cell: (row) => <SpanTable id={row.id} text={row.remetente.tipo} />,
        },
        {
          name: 'Remetente',
          selector: 'remetente.razaoSocial',
          cell: (row) => (
            <SpanTable id={row.id} text={row.remetente.razaoSocial} />
          ),
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Empresa Cedente',
          selector: 'empresaCedente',
          sortable: true,
          minWidth: '150px',
          cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
        },
        {
          name: 'Tipo Transação',
          selector: 'tipoTransacao',
          cell: (row) => <SpanTable id={row.id} text={row.tipoTransacao} />,
        },
        {
          name: 'Conta Bancária',
          selector: 'contaBancaria',
          cell: (row) => <SpanTable id={row.id} text={row.contaBancaria} />,
        },
        {
          name: 'Complemento',
          selector: 'complemento',
          cell: (row) => <SpanTable id={row.id} text={row.complemento} />,
        },
        {
          name: 'Valor',
          selector: 'valor',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={masks.currency.string(row.valor)} />
          ),
        },
        {
          name: 'Valor Conciliado',
          selector: 'valorConciliado',
          right: true,
          width: '125px',
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorConciliado)}
            />
          ),
        },
        {
          name: 'Saldo',
          selector: 'saldo',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={masks.currency.string(row.saldo)} />
          ),
        },
        {
          name: 'Valor a Conciliar *',
          selector: 'valorConciliarInput',
          width: '150px',
          allowOverflow: true,
        },
        {
          name: 'Valor Devolução *',
          selector: 'valorADevolverInput',
          width: '150px',
        },
        {
          name: ' ',
          selector: 'empty',
          width: '1px',
        },
      ]}
      content={vis?.map((vi, index) => {
        const formikVi = formikVis.find(
          (item) => item.valoresAIdentificarId === vi.id
        );
        const formikViIndex = formikVis.findIndex(
          (item) =>
            item.valoresAIdentificarId === formikVi?.valoresAIdentificarId
        );

        return {
          id: vi.id,
          isEdit,
          data: masks.date.string(vi.data),
          remetente: {
            razaoSocial: vi.remetente.razaoSocial,
            tipo: SendersEnum[vi.remetente.tipo],
            cnpj: vi.remetente.cnpj,
          },
          empresa: vi.empresa.razaoSocial,
          tipoTransacao: TransactionsEnum[vi.tipoTransacao],
          contaBancaria: vi.contaBancaria,
          complemento: vi.complemento || '-',
          empresaCedente: vi.empresaCedente?.razaoSocial ?? '-',
          valor: vi.valor,
          saldo: handleBalance(vi),
          // valorConciliado: conciliationId
          //   ? vi.valorConciliado - fieldsTotal(vi)
          //   : vi.valorConciliado,
          valorConciliado: vi.valorConciliado,
          valoresAIdentificarId: formikVi?.valoresAIdentificarId,
          valorConciliar: formikVi?.valor ?? vi.saldo,
          devolucao: formikVi?.valorADevolver ?? 0,
          valorADevolver: vi.valorADevolver,
          valorConciliarInput: (
            <Input
              type="text"
              className="valorConciliar"
              id={`valorConciliar${index}`}
              name={`valoresAIdentificarConciliacao[${formikViIndex}].valor`}
              value={masks.currency.string(formikVi?.valor || 0)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleListInput(e, vi, formikVi)
              }
              disabled={!selectedVIS?.find((item) => item.id === vi.id)}
              currency
            />
          ),
          valorADevolverInput: (
            <Input
              className="valorADevolver"
              type="text"
              id={`valorADevolver${index}`}
              name={`valoresAIdentificarConciliacao[${formikViIndex}].valorADevolver`}
              value={masks.currency.string(formikVi?.valorADevolver || 0)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleListInput(e, vi, formikVi)
              }
              onBlur={formik.handleBlur}
              disabled={!selectedVIS?.find((item) => item.id === vi.id)}
              currency
            />
          ),
        };
      })}
    />
  );
};

export default VIConciliations;
