import styled from 'styled-components';

export const Container = styled.div`
  padding: 25px;
`;

export const LinkToLog = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    padding: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid transparent;
    transition: all 100ms;

    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    }
  }
`;
