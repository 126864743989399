import React from 'react';

import { FooterContainer } from './styles';

const Footer = () => {
  return (
    <FooterContainer>
      <p>{new Date().getFullYear()} &copy; Fluxo VI</p>
    </FooterContainer>
  );
};

export default Footer;
