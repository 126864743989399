import styled from 'styled-components';

export const Container = styled.li`
  h2 {
    font-size: var(--fsz-medium);
    font-weight: 500;
    margin-bottom: var(--gap-small);
  }

  p {
    color: #777;

    span {
      color: #ccc;
      margin-right: 5px;
    }
  }

  input {
    margin-left: var(--gap-big);
  }
`;
