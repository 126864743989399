import { createGlobalStyle } from 'styled-components';

import { Reset } from './Reset';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyles = createGlobalStyle`
  
  ${Reset}

  html, body, *{
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    outline: 0;
  }

  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.2);
  }

  .__react_component_tooltip {
    max-width: 350px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }


  :root{
    --fsz-smaller: 0.5rem;
    --fsz-small: 0.9rem;
    --fsz-medium: 1rem;
    --fsz-big: 1.2rem;
    --fsz-bigger: 1.6rem;

    --gap-smaller: 5px;
    --gap-small: 10px;
    --gap-medium: 15px;
    --gap-big: 25px;
    --gap-bigger: 35px;

    @media (max-width: 1280px){
      --fsz-smaller: 0.4rem;
      --fsz-small: 0.8rem;
      --fsz-medium: 0.9rem;
      --fsz-big: 1rem;
      --fsz-bigger: 1.4rem;
    }
  }
`;
export default GlobalStyles;
