import React from 'react';

import { generateTooltipId } from '@helpers/generateTooltipId';

import Tooltip from '../../Tooltip';

interface Props {
  disabled?: boolean;
  title?: string;
  id?: string | number;
  onClick?: () => void;
}

export const TableButton: React.FC<Props> = ({
  disabled,
  onClick,
  title,
  id,
  children,
}) => {
  const tooltipFor = () => generateTooltipId(id, title);

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      data-tip={title}
      data-for={tooltipFor()}
    >
      {children}
      <Tooltip id={tooltipFor()} />
    </button>
  );
};
