import React, { useCallback, useEffect, useState } from 'react';

import Card from '@components/Card';

import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { useCompanies } from '@hooks/useCompanies';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { IResponse } from '@models/util/IResponse';

import AssignorCompaniesList from './List';

const AssignorCompanies = () => {
  const { listAssignorCompanies } = useCompanies();
  const [assignorCompanies, setAssignorCompanies] = useState<
    IResponse<IAssignorCompany[]>
  >();
  const [assignorLoading, setAssignorLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsAmount, setRowsAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchAssignorCompanies = useCallback(
    async (cancelToken?) => {
      setAssignorLoading(true);

      try {
        const response = await listAssignorCompanies(
          page,
          rowsPerPage,
          cancelToken
        );

        setAssignorCompanies(response?.rows);
        setRowsAmount(response?.count);
      } catch {
        setAssignorCompanies((prevState) => ({ ...prevState, data: [] }));
        setRowsAmount(0);
      } finally {
        setAssignorLoading(false);
      }
    },
    [listAssignorCompanies, page, rowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    fetchAssignorCompanies(source.token);

    return () => {
      source.cancel();
    };
  }, [fetchAssignorCompanies]);

  return (
    <Card>
      <header>
        <h1>Lista de Empresas Cedentes</h1>
      </header>
      <AssignorCompaniesList
        assignorCompanies={assignorCompanies}
        assignorLoading={assignorLoading}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsAmount={rowsAmount}
        rowsPerPage={rowsPerPage}
      />
    </Card>
  );
};

export default AssignorCompanies;
