import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { IAction } from '@models/util/IAction';

import confirmHandler from '../ConfirmAlert/confirmHandler';
import { Icons } from '../Dictionaries/Icons';
import { TableButton } from './Button';
import { Container } from './styles';

interface Props {
  id: string | number;
  submitting?: boolean;
  tableName?: string;
  confirmMessage?: string;
  acoes: IAction;
  tooltipId?: string;
  handleDelete?(): void;
  handleEdit?(): void;
  handleShow?(): void;
}

const TableButtons: React.FC<Props> = ({
  id,
  submitting,
  handleDelete,
  handleEdit,
  handleShow,
  tableName,
  acoes,
  confirmMessage,
}) => {
  const themeContext = useContext(ThemeContext);

  function canEdit() {
    return acoes?.alterar;
  }

  function canView() {
    return handleShow && acoes?.consultar;
  }

  async function handleDeleteClick() {
    confirmHandler(
      `Deletar ${tableName}`,
      confirmMessage,
      'Deletar',
      await handleDelete
    );
  }

  return (
    <Container>
      {canView() && (
        <TableButton id={id} onClick={handleShow} title="Abrir Detalhes">
          <Icons.Consult />
        </TableButton>
      )}
      {canEdit() && (
        <TableButton id={id} onClick={handleEdit} title="Editar">
          <Icons.Edit />
        </TableButton>
      )}
      {acoes?.excluir && (
        <TableButton
          id={id}
          title="Deletar"
          disabled={submitting}
          onClick={() => handleDeleteClick()}
        >
          <Icons.Delete color={themeContext.colors.red} />
        </TableButton>
      )}
    </Container>
  );
};

export default TableButtons;
