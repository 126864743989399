import React, { useEffect } from 'react';

import { Icons } from '../Dictionaries/Icons';
import Tooltip from '../Tooltip';
import { Container, Content } from './styles';

interface Props {
  title: string;
  setOpenModal: (state: boolean) => void;
}

const Modal: React.FC<Props> = ({ title, children, setOpenModal }) => {
  function handleOpenModal() {
    setOpenModal(false);
  }

  function handleEscapeKey(e: React.KeyboardEvent<Element>) {
    if (e.key === 'Escape') setOpenModal(false);
  }

  useEffect(() => {
    const mainContent = document.querySelector('.main-content') as HTMLElement;

    document.body.style.overflow = 'hidden';
    mainContent.style.overflow = 'hidden';

    return () => {
      mainContent.style.overflow = 'auto';
      document.body.style.overflow = 'unset';
    };
  });

  return (
    <Container tabIndex={0} onKeyDown={handleEscapeKey}>
      <Content>
        <header>
          <h1>{title}</h1>
          <Icons.Close size={30} onClick={handleOpenModal} />
        </header>
        <main>{children}</main>
      </Content>
      <Tooltip id="modal-fechar" />
    </Container>
  );
};

export default Modal;
