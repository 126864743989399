import styled from 'styled-components';

export const Content = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-area: header;
  padding: 0 var(--gap-medium);
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.text};
  position: relative;
  cursor: pointer;

  .user-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background};
    padding: 10px;
    border-radius: 5px;

    span {
      font-weight: medium;
      margin-right: var(--gap-medium);
      color: #959cb6;
    }

    strong {
      font-weight: bold;
      color: #6c7293;
    }

    svg {
      font-size: var(--gap-big);
    }
  }
`;

export const Box = styled.span`
  max-width: 250px;
  min-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
