import React from 'react';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { IoMdAlert } from 'react-icons/io';
import {
  RiArchiveLine,
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiBuilding4Line,
  RiBuildingLine,
  RiCheckLine,
  RiCloseLine,
  RiDeleteBinLine,
  RiDownload2Line,
  RiEditBoxLine,
  RiEye2Line,
  RiEyeCloseLine,
  RiEyeLine,
  RiFilter2Line,
  RiFlashlightLine,
  RiGroupLine,
  RiHistoryLine,
  RiLayoutGridFill,
  RiQuestionFill,
  RiSearch2Line,
  RiSearchEyeLine,
  RiSettings3Fill,
  RiStackFill,
  RiTaskLine,
  RiTyphoonFill,
} from 'react-icons/ri';

export const Icons = {
  Consult: ({ color = null }) => <RiEye2Line color={color} />,
  Edit: ({ color = null }) => <RiEditBoxLine color={color} />,
  Delete: ({ color = null }) => <RiDeleteBinLine color={color} />,
  Effectuate: ({ color = null }) => <RiTaskLine color={color} />,
  Approve: ({ color = null }) => <RiCheckLine color={color} />,
  Historic: ({ color = null }) => <RiHistoryLine color={color} />,
  Alert: ({ color = null, size = null, className = '', title = '' }) => (
    <IoMdAlert color={color} size={size} className={className} title={title} />
  ),
  ArrowDownMenu: ({ color = null }) => <RiArrowDownSLine color={color} />,
  ArrowRight: ({ color = null }) => <RiArrowRightSLine color={color} />,
  DoubleArrowLeft: ({ color = null }) => <FiChevronsLeft color={color} />,
  DoubleArrowRight: ({ color = null }) => <FiChevronsRight color={color} />,
  Settings: ({ color = null }) => (
    <RiSettings3Fill
      color={color}
      data-tip="Configurações"
      data-for="configuracoes"
    />
  ),
  Dashboard: ({ color = null }) => (
    <RiStackFill color={color} data-tip="Dashboard" data-for="Dashboard" />
  ),
  VIs: ({ color = null }) => (
    <RiLayoutGridFill
      data-tip="Valores a Identificar"
      data-for="Valores a Identificar"
      color={color}
    />
  ),
  Conciliations: ({ color = null }) => (
    <RiTyphoonFill
      data-tip="Conciliações"
      data-for="Conciliações"
      color={color}
    />
  ),
  Companies: ({ color = null }) => (
    <RiBuilding4Line data-tip="Empresas" data-for="Empresas" color={color} />
  ),
  AssignorCompanies: ({ color = null }) => (
    <RiBuildingLine
      data-tip="Empresas Cedentes"
      data-for="cedentes"
      color={color}
    />
  ),
  Users: ({ color = null }) => (
    <RiGroupLine data-tip="Usuários" data-for="Usuários" color={color} />
  ),
  Profiles: ({ color = null }) => (
    <RiArchiveLine data-tip="Perfis" data-for="perfis" color={color} />
  ),
  Audit: ({ color = null }) => (
    <RiSearchEyeLine data-tip="Auditoria" data-for="auditoria" color={color} />
  ),
  Close: ({ color = null, size = null, onClick }) => (
    <RiCloseLine
      data-tip="Fechar"
      data-for="modal-fechar"
      size={size}
      onClick={onClick}
      color={color}
    />
  ),
  ClosedEye: ({ color = null, title = '', titleFor = '' }) => (
    <RiEyeCloseLine data-tip={title} data-for={titleFor} color={color} />
  ),
  OpenedEye: ({ color = null, title = '', titleFor = '' }) => (
    <RiEyeLine data-tip={title} data-for={titleFor} color={color} />
  ),
  Filter: ({ color = null }) => <RiFilter2Line color={color} />,
  Download: ({ color = null }) => <RiDownload2Line color={color} />,
  Lightning: ({ color = null }) => <RiFlashlightLine color={color} />,
  Question: ({ color = null, title = '', titleFor = '' }) => (
    <RiQuestionFill data-tip={title} data-for={titleFor} color={color} />
  ),
  Search: ({ color = null }) => <RiSearch2Line color={color} />,
};
