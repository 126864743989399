import styled from 'styled-components';

interface Props {
  hasError: boolean;
  hasFocus: boolean;
}

interface ListProps {
  clientX: number;
  clientY: number;
  containerWidth: number;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: var(--gap-small) 0;
  width: 100%;

  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.grey};

    ${(props) =>
      props.hasFocus && `border-color:  ${props.theme.colors.primary}`};
    ${(props) => props.hasError && `border-color:  ${props.theme.colors.red};`};
    border-radius: 2px;
    padding: 0 var(--fsz-small);
    position: relative;
    height: 34px;
    cursor: pointer;

    input {
      background: transparent;
      font-size: var(--fsz-small);
      font-weight: 500;
      line-height: 18px;
      height: 100%;
      border: none;
      outline: none;
      width: 95%;
      cursor: pointer;
    }

    svg {
      cursor: pointer;
      font-size: 24px;
      width: 5%;
      min-width: 20px;
    }
  }

  label {
    font-size: var(--fsz-small);
    margin-bottom: var(--gap-small);
    font-weight: bold;
  }

  .select-error {
    font-size: var(--fsz-small);
    color: ${(props) => props.theme.colors.red};
    margin-top: 5px;
    max-width: 500px;
    word-wrap: break-word;
    white-space: normal;
  }
`;

export const List = styled.div<ListProps>`
  position: fixed;
  background-color: #fff;
  top: ${(props) => `${props.clientY + 36}px`};
  left: ${(props) => props.clientX};
  z-index: 1000;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: ${(props) => `${props.containerWidth}px`};
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;

  button {
    padding: 10px 5px;
    width: 100%;
    border: none;
    outline: none;
    margin: 0;
    background-color: transparent;
    text-align: left;
    font-size: var(--fsz-small);
    font-weight: 500;
    line-height: 18px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
`;
