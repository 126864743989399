import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

import ConfirmAlert from '.';

const confirmHandler = (
  title: string,
  confirmText: string,
  confirmButton: string,
  handleDelete: () => void
) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmAlert
          title={title}
          confirmButton={confirmButton}
          confirmText={confirmText}
          close={onClose}
          action={handleDelete}
        />
      );
    },
  });
};

export default confirmHandler;
