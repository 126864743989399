import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { IProfileGroup } from '@models/domain/IProfile';
import { IUser } from '@models/domain/IUser';
import { ICheckPermission } from '@models/util/ICheckPermission';
import { userService } from '@services/index';

interface Permissions {
  conciliation: {
    create: boolean;
    effectuate: boolean;
    approve: boolean;
    delete: boolean;
  };
  vis: {
    import: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
  };
  exportations: {
    reports: boolean;
  };
  users: {
    list: boolean;
    createAndUpdate: boolean;
    delete: boolean;
    changePassword: boolean;
  };
  profiles: {
    list: boolean;
    createAndUpdate: boolean;
    delete: boolean;
  };
}

interface Context {
  authenticated: boolean;
  user: IUser;
  profile: IProfileGroup[];
  handleLogin: (username: string, password: string) => Promise<void>;
  handleLogout: () => void;
  hasPermission: (permission: ICheckPermission) => boolean;
  isTemporaryPassword: () => boolean;
  permissions: Permissions;
}

export const AuthContext = createContext<Context>({} as Context);

export const AuthProvider: React.FC = ({ children }) => {
  const userStoraged = userService.getUserAuthenticated();
  const profileStoraged = userService.getProfile();

  const [user, setUser] = useState<IUser>(userStoraged);
  const [profile, setProfile] = useState<IProfileGroup[]>(profileStoraged);

  async function handleLogin(username: string, password: string) {
    const userAuthenticated = await userService.login(username, password);

    setProfile(userService.getProfile());
    setUser(userAuthenticated);
  }

  async function handleLogout() {
    userService.logout();

    setUser(null);
    setProfile(null);
  }

  function hasPermission(permission: ICheckPermission) {
    const group = profile?.find((x) => x.nome === permission.group);
    if (permission.subgroup) {
      return group?.subGrupos
        .find((x) => x.nome === permission.subgroup)
        ?.permissoes.some(
          (x) => x.nome === permission.functionality && x.temPermissao
        );
    }

    return group?.permissoes.some(
      (x) => x.nome === permission.functionality && x.temPermissao
    );
  }

  function isTemporaryPassword() {
    return userService.getTemporaryPassword();
  }

  const findProfile = useCallback(
    (name: string) => {
      return profile?.find((item) => item.nome === name);
    },
    [profile]
  );

  const findPermission = useCallback((prof: IProfileGroup, name: string) => {
    return prof?.permissoes?.find((item) => item?.nome === name);
  }, []);

  const conciliationPermissions = useCallback(() => {
    const permission = findProfile('Conciliação VI');

    return {
      create: findPermission(permission, 'Criar Conciliação')?.temPermissao,
      effectuate: findPermission(permission, 'Efetivar Conciliação')
        ?.temPermissao,
      approve: findPermission(permission, 'Aprovar Conciliação')?.temPermissao,
      delete: findPermission(permission, 'Excluir Conciliação')?.temPermissao,
    };
  }, [findPermission, findProfile]);

  const visPermissions = useCallback(() => {
    const permission = findProfile('Valores a Identificar');

    return {
      import: findPermission(permission, 'Importar VIs')?.temPermissao,
      create: findPermission(permission, 'Cadastrar VIs')?.temPermissao,
      edit: findPermission(permission, 'Editar VIs')?.temPermissao,
      delete: findPermission(permission, 'Excluir VIs')?.temPermissao,
    };
  }, [findPermission, findProfile]);

  const exportationsPermissions = useCallback(() => {
    const permission = findProfile('Exportações');

    return {
      reports: findPermission(permission, 'Relatórios')?.temPermissao,
    };
  }, [findPermission, findProfile]);

  const usersPermissions = useCallback(() => {
    const permission = findProfile('Configurações')?.subGrupos?.find(
      (item) => item.nome === 'Usuários'
    );

    return {
      list: findPermission(permission, 'Listar Usuários')?.temPermissao,
      createAndUpdate: findPermission(permission, 'Incluir/Editar Usuários')
        ?.temPermissao,
      delete: findPermission(permission, 'Excluir Usuários')?.temPermissao,
      changePassword: findPermission(permission, 'Trocar Senha')?.temPermissao,
    };
  }, [findPermission, findProfile]);

  const profilesPermissions = useCallback(() => {
    const permission = findProfile('Configurações')?.subGrupos?.find(
      (item) => item.nome === 'Perfis'
    );

    return {
      list: findPermission(permission, 'Listar Perfis')?.temPermissao,
      createAndUpdate: findPermission(permission, 'Incluir/Editar Perfis')
        ?.temPermissao,
      delete: findPermission(permission, 'Excluir Perfis')?.temPermissao,
    };
  }, [findPermission, findProfile]);

  const permissions = useMemo(() => {
    return {
      conciliation: conciliationPermissions(),
      vis: visPermissions(),
      exportations: exportationsPermissions(),
      users: usersPermissions(),
      profiles: profilesPermissions(),
    };
  }, [
    conciliationPermissions,
    exportationsPermissions,
    profilesPermissions,
    usersPermissions,
    visPermissions,
  ]);

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        profile,
        handleLogin,
        handleLogout,
        hasPermission,
        isTemporaryPassword,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
