import React from 'react';

import Select from '@components/Select';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';
import TableButtons from '@components/TableButtons';

import { SendersEnum } from '@enums/senders.enum';
import { TransactionsEnum } from '@enums/transactions.enum';
import { masks } from '@helpers/mask';
import { IVI } from '@models/domain/IVI';
import { IResponse } from '@models/util/IResponse';

interface Props {
  rowsAmount: number;
  rowsPerPage: number;
  vis: IResponse<IVI[]>;
  submitting?: boolean;
  headerButtons?: boolean;
  filter: string;
  visLoading: boolean;
  handleFilter(value: string): void;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
  //handleDeleteVI?(vi: IVI): void;
  //handleEditVI?(vi: IVI): void;
  handleOpenDetail?(vi: IVI): void;
}

const VIList: React.FC<Props> = ({
  setTablePage,
  setRowsPerPage,
  rowsAmount,
  rowsPerPage,
  vis,
  submitting,
  //handleDeleteVI,
  //handleEditVI,
  handleOpenDetail,
  filter,
  handleFilter,
  headerButtons,
  visLoading,
}) => {
  if(vis?.acoes?.alterar)
    vis.acoes.alterar = null;

  if(vis?.acoes?.excluir)
    vis.acoes.excluir = null;

  return (
    <Table
      setTablePage={setTablePage}
      setRowsPerPage={setRowsPerPage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      isLoading={visLoading && !vis}
      hasSubheader={headerButtons}
      subHeaderComponent={
        <Select
          name="filter"
          value={filter}
          onChange={(e) => handleFilter(String(e))}
          list={[
            { id: 'saldoAConciliar', text: 'Saldo a Conciliar' },
            { id: 'totalmenteConciliados', text: 'Totalmente Conciliados' },
            { id: 'todos', text: 'Todos' },
          ]}
          placeholder="Filtrar Status"
          onBlur={() => {}}
        />
      }
      columns={[
        {
          name: 'Ação',
          selector: 'acao',
          width: '75px',
          omit: !(
            vis?.acoes?.excluir ||
            vis?.acoes?.alterar ||
            vis?.acoes?.consultar
          ),
        },
        {
          name: 'Data',
          selector: 'data',
          sortable: true,
          minWidth: '50px',
          cell: (row) => <SpanTable id={row.id} text={row.data} />,
        },
        {
          name: 'Remetente',
          selector: 'remetente',
          cell: (row) => <SpanTable id={row.id} text={row.remetente} />,
        },
        {
          name: 'Tipo Remetente',
          selector: 'tipoRemetente',
          cell: (row) => <SpanTable id={row.id} text={row.tipoRemetente} />,
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Tipo Transação',
          selector: 'tipoTransacao',
          cell: (row) => <SpanTable id={row.id} text={row.tipoTransacao} />,
        },
        {
          name: 'Conta Bancária',
          selector: 'contaBancaria',
          cell: (row) => <SpanTable id={row.id} text={row.contaBancaria} />,
        },
        {
          name: 'Complemento',
          selector: 'complemento',
          cell: (row) => <SpanTable id={row.id} text={row.complemento} />,
        },
        {
          name: 'ESCROW',
          selector: 'contaEscrow',
        },
        {
          name: 'Empresa Cedente',
          selector: 'empresaCedente',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
        },
        {
          name: 'Valor',
          selector: 'valor',
          right: true,
          cell: (row) => <SpanTable id={row.id} text={row.valor} />,
        },
        {
          name: 'Conciliado',
          selector: 'conciliado',
          right: true,
          cell: (row) => <SpanTable id={row.id} text={row.conciliado} />,
        },
        {
          name: 'Saldo a Conciliar',
          selector: 'saldo',
          right: true,
          cell: (row) => <SpanTable id={row.id} text={row.saldo} />,
        },
      ]}
      content={vis?.data?.map((vi) => {
        return {
          id: vi.id,
          data: new Date(vi.data).toLocaleDateString(),
          remetente: vi.remetente.razaoSocial || '-',
          tipoRemetente: SendersEnum[vi.remetente.tipo],
          empresa: vi.empresa.razaoSocial,
          tipoTransacao: TransactionsEnum[vi.tipoTransacao],
          contaBancaria: vi.contaBancaria,
          complemento: vi.complemento || '-',
          contaEscrow: (
            <input type="checkbox" checked={vi.contaEscrow} readOnly />
          ),
          empresaCedente: vi.empresaCedente?.razaoSocial ?? '-',
          valor: masks.currency.string(vi.valor),
          conciliado: masks.currency.string(vi.valorConciliado),
          saldo: masks.currency.string(vi.saldo),
          acao: (
            <TableButtons
              id={vi.id}
              acoes={vis.acoes}
              tableName="VI"
              confirmMessage="Tem certeza que deseja deletar o VI?"
              submitting={submitting}
              //handleDelete={() => handleDeleteVI(vi)}
              //handleEdit={() => handleEditVI(vi)}
              handleShow={() => handleOpenDetail(vi)}
            />
          ),
        };
      })}
    />
  );
};

export default VIList;
