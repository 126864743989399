import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@components/Button';
import Card from '@components/Card';
import Input from '@components/Input';

import { ConciliationStatusEnum } from '@enums/conciliationStatus.enum';
import { SendersEnum } from '@enums/senders.enum';
import { axiosCancelRequestSource } from '@helpers/axiosCancelRequestSource';
import { handleRequestError } from '@helpers/handleRequestError';
import { masks } from '@helpers/mask';
import { useConciliations } from '@hooks/useConciliations';
import { IConciliation } from '@models/domain/IConciliation';
import { ITitle } from '@models/domain/ITitle';
import { IVI } from '@models/domain/IVI';
import { IRouteWithId } from '@models/util/IRouteParams';
import { conciliationService, gerService, viService } from '@services/index';

import History from '../History';
import { ModalEffectuate } from '../ModalEffectuate';
import { Container as ContainerForm } from '../Wizard/Form/styles';
import ModalForm from './Form';
import { Container } from './styles';
import TitlesList from './TitlesList';
import VIConciliations from './VIConciliations';

const ConciliationApprove = ({ isEfetivar = false }) => {
  const history = useHistory();
  const { effectuateConciliation } = useConciliations();
  const { id } = useParams<IRouteWithId>();
  const [conciliation, setConciliation] = useState<IConciliation>();
  const [vis, setVIS] = useState<IVI[]>();
  const [visTablePage, setVisTablePage] = useState(1);
  const [visTableRowsPerPage, setVisTableRowsPerPage] = useState(10);
  const [visTableRowsAmount, setVisTableRowsAmount] = useState(1);
  const [titles, setTitles] = useState<ITitle[]>();
  const [titlesTablePage, setTitlesTablePage] = useState(1);
  const [titlesTableRowsPerPage, setTitlesTableRowsPerPage] = useState(10);
  const [titlesTableRowsAmount, setTitlesTableRowsAmount] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openEffectuate, setOpenEffectuate] = useState(false);

  const getConciliation = useCallback(
    async (cancelToken?) => {
      try {
        const response = await conciliationService.get(id, cancelToken);

        setConciliation({
          ...response.data,
          empresaId: response.data.empresa.id,
          empresaCedenteId: response.data.empresaCedente?.id,
        });
      } catch (error) {
        handleRequestError(
          error,
          'Erro ao carregar conciliação',
          'Conciliação não encontrada'
        );
      }
    },
    [id]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    getConciliation(source.token);

    return () => {
      source.cancel();
    };
  }, [getConciliation]);

  const listVIS = useCallback(
    async (empresaId: string, empresaCedenteId: string, cancelToken?) => {
      try {
        const { rows, count } = await viService.conciliationVIs(
          visTablePage,
          visTableRowsPerPage,
          empresaId,
          empresaCedenteId ?? '',
          id,
          cancelToken
        );

        setVIS(rows);
        setVisTableRowsAmount(count);
      } catch (error) {
        setVIS([]);
        handleRequestError(
          error,
          'Erro ao carregar VIs.',
          'VIs não encontrados para as empresas selecionadas'
        );
      }
    },
    [id, visTablePage, visTableRowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (conciliation?.empresa?.id !== undefined) {
      listVIS(
        conciliation.empresa.id,
        conciliation?.empresaCedente?.id,
        source.token
      );
    }

    return () => {
      source.cancel();
    };
  }, [conciliation, listVIS]);

  function historyGoBack() {
    history.goBack();
  }

  const listTitles = useCallback(
    async (cnpjSacado, data, cancelToken?) => {
      try {
        const { rows, count } = await gerService.listTitles(
          titlesTablePage,
          titlesTableRowsPerPage,
          cnpjSacado,
          data,
          cancelToken
        );

        setTitles(rows.data);
        setTitlesTableRowsAmount(count);
      } catch (error) {
        setTitles([]);
        handleRequestError(
          error,
          'Erro na criação da conciliação',
          'Títulos não encontrados para o pagador selecionado'
        );
      }
    },
    [titlesTablePage, titlesTableRowsPerPage]
  );

  const listRepurchasedTitles = useCallback(
    async (repurchaseId, data, cancelToken?) => {
      try {
        const { rows, count } = await gerService.listRepurchaseTitles(
          titlesTablePage,
          titlesTableRowsPerPage,
          repurchaseId,
          data,
          cancelToken
        );

        setTitles(rows.data);
        setTitlesTableRowsAmount(count);
      } catch (error) {
        setTitles([]);
        handleRequestError(
          error,
          'Erro na criação da conciliação',
          'Títulos não encontrados para o pagador selecionado'
        );
      }
    },
    [titlesTablePage, titlesTableRowsPerPage]
  );

  useEffect(() => {
    const source = axiosCancelRequestSource();

    if (conciliation?.recompraSimuladaId) {
      listRepurchasedTitles(
        conciliation.recompraSimuladaId,
        conciliation.data,
        source.token
      );
    } else if (conciliation?.liquidacao?.pagadorCpfCnpjLiquidacao !== undefined) {
      listTitles(
        conciliation.liquidacao?.pagadorCpfCnpjLiquidacao,
        conciliation?.data,
        source.token
      );
    }

    return () => {
      source.cancel();
    };
  }, [conciliation, listRepurchasedTitles, listTitles]);

  function handleConciliationValue(value) {
    return value ?? '-';
  }

  async function handleEffectuateClick() {
    if (conciliation?.empresaCedente?.id) {
      setOpenEffectuate(true);
    } else {
      await effectuateConciliation(conciliation, null, history.push);
    }
  }

  return (
    <Container>
      <Card>
        <header>
          <h1>{`${isEfetivar ? 'Efetivar' : 'Aprovar'}`} Conciliação</h1>
        </header>
        <main>
          <ContainerForm>
            <Input
              label="Tipo Liquidação:"
              value={handleConciliationValue(
                typeof conciliation?.liquidacao?.tipo === 'number'
                  ? SendersEnum[conciliation?.liquidacao?.tipo]
                  : conciliation?.liquidacao?.tipo
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Pagador:"
              value={handleConciliationValue(
                conciliation?.liquidacao?.pagadorRazaoSocialLiquidacao
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Valor total VIs:"
              value={handleConciliationValue(
                masks.currency.string(conciliation?.valor)
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Valor total dos Títulos:"
              value={handleConciliationValue(
                masks.currency.string(conciliation?.somaTitulos)
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Status:"
              value={handleConciliationValue(
                ConciliationStatusEnum[conciliation?.status]
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Devolução TED:"
              value={handleConciliationValue(
                masks.currency.string(conciliation?.devolucao?.valor)
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Beneficiado:"
              value={handleConciliationValue(
                conciliation?.devolucao?.bancoDestino &&
                  `BAN: ${conciliation?.devolucao?.bancoDestino ?? ''} - AG: ${
                    conciliation?.devolucao?.agenciaDestino ?? ''
                  } - C/C: ${masks.bankAccount(
                    conciliation?.devolucao?.contaDestino
                  )}`
              )}
              onChange={() => {}}
              disabled
            />
            <Input
              label="Data Referência:"
              value={handleConciliationValue(
                masks.date.format(conciliation?.data)
              )}
              onChange={() => {}}
              type="date"
              name="data"
              disabled
            />
          </ContainerForm>

          <div className="tables">
            <h2>Valores a Identificar</h2>
            <VIConciliations
              vis={vis}
              conciliation={conciliation}
              setRowsPerPage={setVisTableRowsPerPage}
              setTablePage={setVisTablePage}
              rowsAmount={visTableRowsAmount}
              rowsPerPage={visTableRowsPerPage}
            />
          </div>

          <div className="tables">
            <h2>Títulos</h2>
            <TitlesList
              titles={titles}
              conciliation={conciliation}
              setRowsPerPage={setTitlesTableRowsPerPage}
              setTablePage={setTitlesTablePage}
              rowsAmount={titlesTableRowsAmount}
              rowsPerPage={titlesTableRowsPerPage}
            />
          </div>
        </main>
        <footer>
          <div className="buttons">
            <Button onClick={historyGoBack}>Voltar</Button>
            <div>
              <Button onClick={() => setOpenHistory(true)}>Histórico</Button>
              {!isEfetivar && (
                <Button onClick={() => setOpenModal(true)}>Ações</Button>
              )}
              {isEfetivar && (
                <Button
                  onClick={handleEffectuateClick}
                  disabled={conciliation?.status !== 3}
                >
                  Efetivar
                </Button>
              )}
            </div>
          </div>
        </footer>
      </Card>
      {openModal && (
        <ModalForm conciliation={conciliation} setOpenModal={setOpenModal} />
      )}
      {openEffectuate && (
        <ModalEffectuate
          conciliation={conciliation}
          setOpenModal={setOpenEffectuate}
        />
      )}
      {openHistory && (
        <History conciliation={id} setOpenHistory={setOpenHistory} />
      )}
    </Container>
  );
};

export default ConciliationApprove;
