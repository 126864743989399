import { AxiosInstance, CancelToken } from 'axios';

import { AUTH_TOKEN_KEY, AUTH_TEMP_PWD_KEY, AUTH_PROFILE_KEY } from '@consts';
import { IProfileGroup } from '@models/domain/IProfile';
import { IUser } from '@models/domain/IUser';
import { IJWTPayload } from '@models/util/IJWTPayload';
import IList from '@models/util/IList';
import { IResponse } from '@models/util/IResponse';

import { ILoginResponse, IUserService } from './interfaces/IUserService';

const ENDPOINT = '/api/v1/usuarios';

export class UserService implements IUserService {
  private _jwt: IJWTPayload = null;

  constructor(private api: AxiosInstance) {}

  async list(page: number, amount = 10, cancelToken?: CancelToken) {
    const params = {
      pagina: page || undefined,
      quantidade: page ? amount : undefined,
    };

    const { data, headers } = await this.api.get<IResponse<IUser[]>>(ENDPOINT, {
      params,
      cancelToken,
    });

    return {
      count: Number(headers['x-total-count']),
      rows: data,
    } as IList<IUser[]>;
  }

  async listAll() {
    const { data } = await this.api.get<IResponse<IUser[]>>(ENDPOINT);

    return data.data;
  }

  async login(username: string, password: string) {
    const { data } = await this.api.post<ILoginResponse>(`${ENDPOINT}/login`, {
      login: username,
      senha: password,
    });

    this.setToken(data);
    this.setProfile(data);
    this.setTemporaryPassword(data);

    return this.jwt.udata;
  }

  logout() {
    this._jwt = null;
    localStorage.clear();
  }

  async register(user: IUser) {
    await this.api.post(`${ENDPOINT}`, user);
  }

  async edit(user: IUser) {
    await this.api.put(`${ENDPOINT}`, user);
  }

  async delete(id: string) {
    await this.api.delete(`${ENDPOINT}/${id}`);
  }

  getProfile(): IProfileGroup[] {
    const base64 = localStorage.getItem(AUTH_PROFILE_KEY);

    if (base64) {
      const json = atob(base64);
      const profile = JSON.parse(json);
      return profile as IProfileGroup[];
    }

    return [];
  }

  getTemporaryPassword() {
    const value = localStorage.getItem(AUTH_TEMP_PWD_KEY);

    return !!value;
  }

  deleteTemporaryPassword() {
    localStorage.removeItem(AUTH_TEMP_PWD_KEY);
  }

  async forgotPassword(login: string) {
    await this.api.put(`${ENDPOINT}/esqueci-senha`, {
      login,
    });
  }

  async changePassword(currentPassword: string, newPassword: string) {
    await this.api.put(`${ENDPOINT}/trocar-senha`, {
      atual: currentPassword,
      nova: newPassword,
    });
  }

  isAuthenticated(): boolean {
    return this.isExpired();
  }

  getUserAuthenticated() {
    if (this.isExpired()) return null;
    return this._jwt.udata;
  }

  private setToken(response: ILoginResponse) {
    localStorage.setItem(AUTH_TOKEN_KEY, response.token);
  }

  private setProfile(response: ILoginResponse) {
    const json = JSON.stringify(response.perfil);
    const jsonBase64 = btoa(json);
    localStorage.setItem(AUTH_PROFILE_KEY, jsonBase64);
  }

  private setTemporaryPassword(response: ILoginResponse) {
    if (response.senhaTemporaria)
      localStorage.setItem(AUTH_TEMP_PWD_KEY, 'true');
  }

  private isExpired() {
    if (!this.jwt) return true;
    const expireIn = new Date(this.jwt.exp * 1000);
    return new Date() > expireIn;
  }

  private get jwt() {
    if (!this._jwt) {
      const token = localStorage.getItem(AUTH_TOKEN_KEY);
      if (token) this._jwt = this.decodeToken(token);
    }

    return this._jwt;
  }

  private decodeToken(token: string) {
    const [, payload] = token.split('.');
    const json = atob(payload);

    const obj = JSON.parse(json);
    obj.udata = JSON.parse(obj.udata);

    return obj as IJWTPayload;
  }
}
