import * as yup from 'yup';

const schema = yup.object().shape({
  fieldType: yup.string(),
  parametroId: yup.string(),
  usuarioId: yup.string(),
  valor: yup.string().when('fieldType', {
    is: (val) => ['Email', 'Usuário'].includes(val),
    then: yup.string().email('valor deve ser um endereço de email válido'),
    otherwise: yup.string().when('fieldType', {
      is: 'Hora',
      then: yup
        .string()
        .matches(
          /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
          'valor deve ser uma hora válida (00:00 até 23:59)'
        ),
      otherwise: yup.string(),
    }),
  }),
});

export default schema;
