import React from 'react';

import SpanTable from '@components/SpanTable';
import Table from '@components/Table';

import { masks } from '@helpers/mask';
import { ICompany } from '@models/domain/ICompany';
import { IResponse } from '@models/util/IResponse';

interface Props {
  companies: IResponse<ICompany[]>;
  rowsAmount: number;
  rowsPerPage: number;
  companiesLoading: boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
}

const CompaniesList: React.FC<Props> = ({
  companies,
  setPage,
  rowsAmount,
  rowsPerPage,
  setRowsPerPage,
  companiesLoading,
}) => {
  return (
    <Table
      setTablePage={setPage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      isLoading={companiesLoading}
      columns={[
        {
          name: 'Razão Social',
          selector: 'razaoSocial',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.razaoSocial} />,
        },
        {
          name: 'CNPJ',
          selector: 'cnpj',
          sortable: true,
          minWidth: '165px',
          cell: (row) => <SpanTable id={row.id} text={row.cnpj} />,
        },
        {
          name: 'ID Externo',
          selector: 'idExterno',
          sortable: true,
          cell: (row) => <SpanTable id={row.id} text={row.idExterno} />,
        },
      ]}
      content={companies?.data.map((company) => {
        return {
          razaoSocial: company.razaoSocial,
          cnpj: masks.cnpj(company.cnpj),
          idExterno: company.idExterno,
        };
      })}
    />
  );
};

export default CompaniesList;
