import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  margin: var(--gap-big) 0;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.text};
  padding: var(--gap-big);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h1 {
      white-space: pre-wrap;
    }

    .menu {
      display: flex;
      align-items: flex-end;
      gap: 10px;
    }

    @media (max-width: 790px) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;

      .menu {
        flex-wrap: wrap;
        align-self: flex-end;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: var(--gap-big);
  }
`;
