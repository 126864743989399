import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';

import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Filter from '@components/Filter';
import Input from '@components/Input';
import SpinnerLoading from '@components/Loading/SpinnerLoading';
import Radio from '@components/Radio';
import Select from '@components/Select';
import Textarea from '@components/Textarea';

import { clearObject } from '@helpers/clearObject';
import { masks } from '@helpers/mask';
import { useCompanies } from '@hooks/useCompanies';
import { useVIs } from '@hooks/useVIs';
import { IAssignorCompany } from '@models/domain/IAssignorCompany';
import { ICompany } from '@models/domain/ICompany';
import { IDomainType } from '@models/domain/IDomainType';
import { IVI } from '@models/domain/IVI';

import schema from './schema';

interface Props {
  refreshVIS(): Promise<void>;
  transactions?: IDomainType[];
  senders?: IDomainType[];
  selectedVI?: IVI;
  setOpenModal(state: boolean): void;
}

interface VIPost {
  id: string;
  tipoTransacao: string | number;
  data: string;
  tipo: number;
  razaoSocial: string;
  cnpj: string;
  empresa: {
    razaoSocial: string;
    id: string;
  };
  empresaCedente: {
    razaoSocial: string;
    id: string;
  };
  contaBancaria: string;
  valor: string;
  observacao: string;
  complemento: string;
  contaEscrow: boolean;
  tipoDocumento?: string;
}

const VIForm: React.FC<Props> = ({
  refreshVIS,
  selectedVI,
  setOpenModal,
  transactions,
  senders,
}) => {
  const isFormAdd = selectedVI === undefined;
  const {
    listAllCompanies,
    listAllAssignorCompanies,
    getCompanyId,
    getCompanyName,
  } = useCompanies();
  const { registerVi, editVi } = useVIs();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [assignorCompanies, setAssignorCompanies] = useState<
    IAssignorCompany[]
  >();

  const listAllCompaniesCb = useCallback(async () => {
    const response = await listAllCompanies();

    setCompanies(response);
  }, [listAllCompanies]);

  const listAllAssignorCompaniesCb = useCallback(async () => {
    const response = await listAllAssignorCompanies();

    setAssignorCompanies(response);
  }, [listAllAssignorCompanies]);

  useEffect(() => {
    listAllCompaniesCb();
    listAllAssignorCompaniesCb();
  }, [listAllAssignorCompaniesCb, listAllCompaniesCb]);

  async function handleSubmit(data: VIPost) {
    let submit: IVI = {
      id: data.id,
      contaBancaria: data.contaBancaria,
      complemento: data.complemento,
      tipoTransacao: data.tipoTransacao,
      valor: masks.sanitize.number(data.valor),
      remetente: {
        cnpj: masks.sanitize.format(data.cnpj),
        tipo: data.tipo,
        razaoSocial: data.razaoSocial,
      },
      empresa: {
        id: data.empresa.id,
      },
      data: data.data,
      observacao: data.observacao,
      contaEscrow: data.contaEscrow,
    };

    if (data.empresaCedente.id) {
      submit = {
        ...submit,
        empresaCedente: {
          id: data.empresaCedente.id,
        },
      };
    }

    try {
      if (isFormAdd) {
        await registerVi(clearObject(submit));
      } else {
        await editVi(clearObject(submit));
      }

      refreshVIS();
      setOpenModal(false);
    } catch (error) {
      return null;
    }

    return null;
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: handleSubmit,
    initialValues: {
      id: selectedVI?.id || '',
      tipoTransacao: selectedVI?.tipoTransacao || 0,
      data: selectedVI?.data || '',
      tipo: selectedVI?.remetente.tipo || 0,
      razaoSocial: selectedVI?.remetente.razaoSocial || '',
      tipoDocumento: masks.handleDocumentType(selectedVI?.remetente.cnpj),
      cnpj: masks.handleDocument(selectedVI?.remetente.cnpj),
      empresa: {
        razaoSocial: selectedVI?.empresa.razaoSocial || '',
        id: selectedVI?.empresa.id || '',
      },
      empresaCedente: {
        razaoSocial: selectedVI?.empresaCedente.razaoSocial || '',
        id: selectedVI?.empresaCedente.id || '',
      },
      contaBancaria: selectedVI?.contaBancaria || 'NAO IDENTIF.',
      valor: !isFormAdd ? masks.currency.format(selectedVI?.valor) : '',
      observacao: selectedVI?.observacao || '',
      complemento: selectedVI?.complemento || '',
      contaEscrow: selectedVI?.contaEscrow || false,
    },
  });

  function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = masks.decimal(e.target.value);

    formik.handleChange(e);
  }

  function handleDocumentChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;
    const regex = /([a-zA-Z!@#$%^& *()_+=\\[\]{};':"\\|,<>\\?~])/;

    if (formik.values.tipoDocumento === 'cpf') {
      value = masks.cpf(value);

      if (value.length === 15 || regex.test(value)) return;

      formik.setFieldValue('cnpj', masks.cpf(e.target.value));
    } else if (formik.values.tipoDocumento === 'cnpj') {
      value = masks.cpf(value);

      if (value.length === 19 || regex.test(value)) return;

      formik.setFieldValue('cnpj', masks.cnpj(e.target.value));
    }
  }

  function handleEscrowChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;

    formik.setFieldValue('contaEscrow', checked);
    formik.setFieldValue('empresaCedente.id', '');
    formik.setFieldValue('empresaCedente.razaoSocial', '');
  }

  function handleDocumentTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    formik.setFieldValue('tipoDocumento', e.target.id);
    formik.setFieldValue('cnpj', '');
  }

  if (companies === undefined || assignorCompanies === undefined) {
    return <SpinnerLoading />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-wrapper">
        <Select
          label="Tipo de Transação *"
          name="tipoTransacao"
          value={formik.values.tipoTransacao}
          onChange={(e) => formik.setFieldValue('tipoTransacao', e)}
          onBlur={formik.handleBlur}
          hasError={
            !!(formik.touched.tipoTransacao && formik.errors.tipoTransacao)
          }
          errorText={
            formik.touched.tipoTransacao && formik.errors.tipoTransacao
          }
          list={transactions}
        />
        <Input
          type="date"
          label="Data *"
          name="data"
          value={masks.date.format(formik.values.data)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.data && formik.errors.data)}
          errorText={formik.touched.data && formik.errors.data}
        />
        <Select
          label="Tipo do Remetente *"
          name="tipo"
          value={formik.values.tipo}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('tipo', e)}
          hasError={!!(formik.touched.tipo && formik.errors.tipo)}
          errorText={formik.touched.tipo && formik.errors.tipo}
          list={senders}
        />
        <Input
          label="Razão Social do Remetente *"
          name="razaoSocial"
          placeholder="ex: Remetente 1"
          value={formik.values.razaoSocial}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.razaoSocial && formik.errors.razaoSocial)}
          disabled={formik.values.tipo === 3}
          errorText={formik.touched.razaoSocial && formik.errors.razaoSocial}
        />
        <Radio
          title="Selecione o tipo de documento *"
          hasError={
            !!(formik.touched.tipoDocumento && formik.errors.tipoDocumento)
          }
          errorText={
            formik.touched.tipoDocumento && formik.errors.tipoDocumento
          }
        >
          <label htmlFor="cpf">
            <input
              type="radio"
              name="radio-benefited"
              id="cpf"
              checked={formik.values.tipoDocumento === 'cpf'}
              disabled={formik.values.tipo === 3}
              onChange={handleDocumentTypeChange}
            />
            <span>CPF</span>
          </label>
          <label htmlFor="cnpj">
            <input
              type="radio"
              name="radio-benefited"
              id="cnpj"
              checked={formik.values.tipoDocumento === 'cnpj'}
              disabled={formik.values.tipo === 3}
              onChange={handleDocumentTypeChange}
            />
            <span>CNPJ</span>
          </label>
        </Radio>
        <Input
          label="Documento *"
          name="cnpj"
          disabled={formik.values.tipo === 3 || !formik.values.tipoDocumento}
          placeholder={`ex: ${
            formik.values.tipoDocumento === 'cnpj'
              ? '00.000.000/0000-00'
              : '000.000.000-00'
          }`}
          value={formik.values.cnpj}
          onChange={handleDocumentChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.cnpj && formik.errors.cnpj)}
          errorText={formik.touched.cnpj && formik.errors.cnpj}
        />
        <Input
          type="text"
          label="Conta bancária *"
          name="contaBancaria"
          placeholder="ex: Conta Teste"
          value={formik.values.contaBancaria}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={
            !!(formik.touched.contaBancaria && formik.errors.contaBancaria)
          }
          errorText={
            formik.touched.contaBancaria && formik.errors.contaBancaria
          }
        />
        <Checkbox
          name="contaEscrow"
          label="Conta ESCROW "
          checked={formik.values.contaEscrow}
          onChange={(e) => handleEscrowChange(e)}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.contaEscrow && formik.errors.contaEscrow)}
          errorText={formik.touched.contaEscrow && formik.errors.contaEscrow}
        />
        <Input
          label="Complemento"
          name="complemento"
          placeholder="ex: Exemplo de Complemento"
          value={formik.values.complemento}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.complemento && formik.errors.complemento)}
          errorText={formik.touched.complemento && formik.errors.complemento}
        />
        <Filter
          label="Nome da Empresa *"
          name="empresa"
          placeholder="ex: Empresa 1"
          onChange={(e) => formik.setFieldValue('empresa', e)}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.empresa && formik.errors.empresa)}
          errorText={formik.touched.empresa && formik.errors.empresa?.id}
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresa}
          list={companies}
        />

        <Filter
          label="Nome da Empresa Cedente"
          name="empresaCedente"
          placeholder="ex: Empresa Cedente 1"
          onChange={(e) => formik.setFieldValue('empresaCedente', e)}
          onBlur={formik.handleBlur}
          hasError={
            !!(formik.touched.empresaCedente && formik.errors.empresaCedente)
          }
          errorText={
            formik.touched.empresaCedente && formik.errors.empresaCedente?.id
          }
          getDisplayName={getCompanyName}
          getSubmitValue={getCompanyId}
          value={formik.values.empresaCedente}
          list={assignorCompanies}
          disabled={!formik.values.contaEscrow}
        />
        <Input
          type="text"
          label="Valor *"
          name="valor"
          placeholder="ex: 1.000,00"
          value={formik.values.valor}
          onChange={handleDecimalChange}
          onBlur={formik.handleBlur}
          hasError={!!(formik.touched.valor && formik.errors.valor)}
          errorText={formik.touched.valor && formik.errors.valor}
          prepend="R$"
        />
        <Textarea
          label="Observações"
          name="observacao"
          value={formik.values.observacao}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="buttons">
        <Button
          disabled={formik.isSubmitting || !formik.dirty}
          color="warning"
          onClick={() => formik.resetForm()}
        >
          Limpar
        </Button>
        <Button type="submit" disabled={formik.isSubmitting || !formik.dirty}>
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default VIForm;
