import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'axios';
import { useFormik } from 'formik';

import Button from '@components/Button';
import Modal from '@components/Modal';
import Select from '@components/Select';

import { handleRequestError } from '@helpers/handleRequestError';
import { useConciliations } from '@hooks/useConciliations';
import { IAccount, IConciliation } from '@models/domain/IConciliation';
import { conciliationService } from '@services/index';

import { Container } from './styles';

interface Props {
  conciliation: IConciliation;
  setOpenModal: (state: boolean) => void;
}

export const ModalEffectuate: React.FC<Props> = ({
  conciliation,
  setOpenModal,
}) => {
  const history = useHistory();
  const { effectuateConciliation } = useConciliations();
  const [accounts, setAccounts] = useState<IAccount[]>();

  const listAccounts = useCallback(
    async (cancelToken?) => {
      try {
        const response = await conciliationService.listAccounts(
          conciliation?.empresa?.idExterno,
          cancelToken
        );

        setAccounts(response);
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar contas');
      }
    },
    [conciliation]
  );

  useEffect(() => {
    const source = Axios.CancelToken.source();

    listAccounts(source.token);

    return () => {
      source.cancel();
    };
  }, [listAccounts]);

  async function handleSubmit(data) {
    await effectuateConciliation(conciliation, data.conta, history.push);
  }

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      conta: '',
    },
  });

  return (
    <Modal title="Efetivar" setOpenModal={setOpenModal}>
      <form onSubmit={formik.handleSubmit}>
        <Container className="form-wrapper">
          <Select
            label="Contas *"
            name="conta"
            onBlur={formik.handleBlur}
            value={formik.values.conta}
            onChange={(e) => formik.setFieldValue('conta', e)}
            hasError={!!(formik.touched.conta && formik.errors.conta)}
            errorText={formik.touched.conta && formik.errors.conta}
            disabled={!accounts}
            list={accounts?.map((account) => ({
              text: account?.conta,
              id: account?.conta,
            }))}
          />
        </Container>

        <div className="buttons">
          <Button type="submit">Efetivar</Button>
        </div>
      </form>
    </Modal>
  );
};
