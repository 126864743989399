import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ThemeContext } from 'styled-components';

import confirmHandler from '@components/ConfirmAlert/confirmHandler';
import { Icons } from '@components/Dictionaries/Icons';
import { TableButton } from '@components/TableButtons/Button';
import { Container } from '@components/TableButtons/styles';

import { useAuth } from '@hooks/useAuth';
import { IAction } from '@models/util/IAction';

interface Props {
  id: string;
  submitting?: boolean;
  tableName?: string;
  confirmMessage?: string;
  acoes: IAction;
  status?: string | number;
  handleDelete?(): void;
  handleEdit(): void;
  handleShow?(): void;
  handleHistory?(): void;
}

const ConciliationTableButtons: React.FC<Props> = ({
  id,
  submitting,
  handleDelete,
  handleEdit,
  handleShow,
  tableName,
  acoes,
  confirmMessage,
  handleHistory,
  status,
}) => {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { permissions } = useAuth();

  function canEdit() {
    return acoes?.alterar && status === 1;
  }

  function canDelete() {
    return acoes?.excluir && status !== 5;
  }

  function canEffectuate() {
    return (
      (status === 3 || status === 4) && permissions?.conciliation?.effectuate
    );
  }

  function canApprove() {
    return status === 2 && permissions?.conciliation?.approve;
  }

  function redirect(to: string) {
    history.push(`/conciliacoes/${to}/${id}`);
  }

  return (
    <Container>
      {handleShow && acoes?.consultar && (
        <TableButton id={id} onClick={handleShow} title="Abrir Detalhes">
          <Icons.Consult />
        </TableButton>
      )}
      {acoes?.consultar && (
        <TableButton id={id} onClick={handleHistory} title="Abrir Histórico">
          <Icons.Historic />
        </TableButton>
      )}

      {canEdit() && (
        <TableButton id={id} onClick={handleEdit} title="Editar">
          <Icons.Edit />
        </TableButton>
      )}
      {canEffectuate() && (
        <TableButton
          id={id}
          onClick={() => redirect('efetivar')}
          title="Efetivar"
        >
          <Icons.Effectuate />
        </TableButton>
      )}
      {canApprove() && (
        <TableButton
          id={id}
          onClick={() => redirect('aprovar')}
          title="Aprovar"
        >
          <Icons.Approve />
        </TableButton>
      )}
      {canDelete() && (
        <TableButton
          id={id}
          disabled={submitting}
          onClick={() =>
            confirmHandler(
              `Deletar ${tableName}`,
              confirmMessage,
              'Deletar',
              handleDelete
            )
          }
          title="Deletar"
        >
          <Icons.Delete color={themeContext.colors.red} />
        </TableButton>
      )}
    </Container>
  );
};

export default ConciliationTableButtons;
