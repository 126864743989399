import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Login from '@pages/Login';

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default PublicRoutes;
