import React from 'react';

import ListTitles from './ListTitles';
import ListVis from './ListVis';
import { Container } from './styles';

interface Props {
  conciliation: string;
}

const ConciliationDetails: React.FC<Props> = ({ conciliation }) => {
  return (
    <Container>
      <ListVis conciliationId={conciliation} />
      <ListTitles conciliationId={conciliation} />
    </Container>
  );
};

export default ConciliationDetails;
