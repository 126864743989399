import React, { useCallback, useContext, useState } from 'react';
import { RiCalculatorLine } from 'react-icons/ri';

import { ThemeContext } from 'styled-components';

import Input from '@components/Input';
import SpanTable from '@components/SpanTable';
import Table from '@components/Table';
import Tooltip from '@components/Tooltip';

import { TitleOperationTypeEnum } from '@enums/titleOperationType.enum';
import { TitleStatusEnum } from '@enums/titleStatus.enum';
import { parseDate } from '@helpers/date';
import { generateTooltipId } from '@helpers/generateTooltipId';
import { masks } from '@helpers/mask';
import { ICompany } from '@models/domain/ICompany';
import { ILiquidation } from '@models/domain/ILiquidation';
import { ITitle } from '@models/domain/ITitle';

interface Props {
  titles: ITitle[];
  rowsAmount: number;
  rowsPerPage: number;
  recompraSimuladaId: string | number;
  company: ICompany;
  liquidation: ILiquidation;
  formikTitles: ITitle[];
  conciliacaoId: string;
  titlesTotal: number;
  visTotal: number;
  referenceDate: string;
  setFieldValue(name: string, value: unknown): void;
  setTablePage(value: number): void;
  setRowsPerPage(value: number): void;
}

const TitlesList: React.FC<Props> = ({
  titles,
  setTablePage,
  rowsAmount,
  rowsPerPage,
  company,
  liquidation,
  conciliacaoId,
  recompraSimuladaId,
  setRowsPerPage,
  formikTitles,
  setFieldValue,
  referenceDate,
  visTotal,
  titlesTotal,
}) => {
  const themeContext = useContext(ThemeContext);
  const [selectedTitles, setSelectedTitles] = useState<ITitle[]>();

  const isTitlesTotalMoreOrEqualThanVisTotal = useCallback(() => {
    return titlesTotal >= visTotal;
  }, [visTotal, titlesTotal]);

  const visTitleDifferenceValue = useCallback(() => {
    return visTotal - titlesTotal;
  }, [titlesTotal, visTotal]);

  const findFormikTitle = useCallback(
    (title: ITitle) => {
      return formikTitles.find((item) =>
        conciliacaoId
          ? String(item.tituloId) === String(title.id)
          : String(item.id) === String(title.id)
      );
    },
    [conciliacaoId, formikTitles]
  );

  const findFormikTitleIndex = useCallback(
    (title: ITitle) => {
      return formikTitles.findIndex((item) => item.id === title?.id);
    },
    [formikTitles]
  );

  const checkQuitacaoParcial = useCallback((formikTitle: ITitle) => {
    return formikTitle?.quitacaoParcial ?? false;
  }, []);

  const checkQuitacaoParcialList = useCallback(
    (valorResidual: number, formikTitle: ITitle) => {
      if (recompraSimuladaId) return false;
      if (conciliacaoId && formikTitle)
        return checkQuitacaoParcial(formikTitle);

      const valorQuitado = Number(formikTitle?.valorQuitado) || 0;

      return (
        valorResidual > visTotal ||
        valorResidual > visTitleDifferenceValue() + valorQuitado ||
        checkQuitacaoParcial(formikTitle)
      );
    },
    [
      checkQuitacaoParcial,
      conciliacaoId,
      recompraSimuladaId,
      visTitleDifferenceValue,
      visTotal,
    ]
  );

  const calcValorQuitado = useCallback(
    (quitacaoParcial: boolean, formikTitle: ITitle, calc: number) => {
      return quitacaoParcial ? +formikTitle?.valorQuitado || 0 : calc;
    },
    []
  );

  const calcDeduzidoFace = useCallback(
    (
      quitacaoParcial: boolean,
      valorQuitado: number,
      diferenca: number,
      title: ITitle,
      formikTitle: ITitle
    ) => {
      if (formikTitle && !quitacaoParcial) {
        return formikTitle?.deduzidoFace;
      }

      if (quitacaoParcial) {
        return valorQuitado - diferenca;
      }

      return title.valorResidual;
    },
    []
  );

  const sanitizeInputValue = useCallback((value) => {
    return masks.sanitize.number(masks.decimal(value));
  }, []);

  const inputDisabled = useCallback(
    (title: ITitle) => {
      return (
        !selectedTitles?.find((item) => item.id === title.id) ||
        !!recompraSimuladaId
      );
    },
    [recompraSimuladaId, selectedTitles]
  );

  const handleDifferenceField = useCallback(
    (
      formikTitle: ITitle,
      title: ITitle,
      valorQuitado: number,
      quitacaoParcial: boolean
    ) => {
      const diferenca = formikTitle?.diferencaInput ?? +title.diferenca;

      if (quitacaoParcial && diferenca > valorQuitado) {
        return 0;
      }

      return diferenca;
    },
    []
  );
  
  const handleSelected = useCallback(
    ({ selectedRows }) => {
      setSelectedTitles(selectedRows);

      setFieldValue(
        'titulos',
        selectedRows.map((title: ITitle) => {
          const formikTitle = findFormikTitle(title);

          return {
            id: formikTitle?.id ?? title.id,
            conciliacaoId: formikTitle?.conciliacaoId ?? title.conciliacaoId,
            valor: formikTitle?.valor ?? title.valorFace,
            numeroTitulo: formikTitle?.numeroTitulo ?? title.numeroTitulo,
            tipo: formikTitle?.tipo ?? title.tipo,
            vencimento: formikTitle?.vencimento ?? title.vencimento,
            vencimentoUtil: formikTitle?.vencimentoUtil ?? title.vencimentoUtil,
            deducoes: formikTitle?.deducoes ?? title.deducoes,
            valorResidual: formikTitle?.valorResidual ?? title.valorResidual,
            diferenca: formikTitle?.diferenca ?? title.diferenca,
            valorFinal: formikTitle?.valorFinal ?? title.valorFinal,
            cedente: formikTitle?.cedente ?? title.cedente,
            sacado: formikTitle?.sacado ?? title.sacado,
            status: formikTitle?.status ?? title.status,
            tituloId: formikTitle?.tituloId ?? title.tituloId,
            diferencaInput: formikTitle?.diferencaInput ?? title.diferencaInput,
            valorQuitado: formikTitle?.valorQuitado ?? title.valorQuitado,
            deduzidoFace: formikTitle?.deduzidoFace ?? title.deduzidoFace,
            quitacaoParcial:
              formikTitle?.quitacaoParcial ?? title.quitacaoParcial,
            moraDescricao: formikTitle?.moraDescricao ?? title.moraDescricao,
            tipoOperacao: formikTitle?.tipoOperacao ?? title.tipoOperacao,
          };
        })
      );
    },
    [findFormikTitle, setFieldValue]
  );

  const handleValorQuitadoList = useCallback(
    (
      title: ITitle,
      diferenca: number,
      formikTitle: ITitle,
      quitacaoParcial: boolean
    ) => {
      if (recompraSimuladaId) return +title.valorResidual + diferenca;
      if (conciliacaoId && formikTitle) return formikTitle?.valorQuitado;

      const valorQuitado =
        formikTitle?.valorQuitado ??
        calcValorQuitado(
          quitacaoParcial,
          formikTitle,
          +title.valorResidual + diferenca
        );

      return valorQuitado;
    },
    [calcValorQuitado, conciliacaoId, recompraSimuladaId]
  );

  const handleDiferencaList = useCallback(
    (
      title: ITitle,
      valorQuitado: number,
      formikTitle: ITitle,
      quitacaoParcial: boolean
    ) => {
      if (recompraSimuladaId) return title.diferencaInput;
      if (conciliacaoId && formikTitle) return formikTitle?.diferencaInput ?? 0;

      return handleDifferenceField(
        formikTitle,
        title,
        valorQuitado,
        quitacaoParcial
      );
    },
    [conciliacaoId, handleDifferenceField, recompraSimuladaId]
  );

  const handleValorQuitadoInput = useCallback(
    (
      quitacaoParcial: boolean,
      deduzidoFace: number,
      valorQuitado: number,
      diferenca: number
    ) => {
      if (!quitacaoParcial) {
        return deduzidoFace + diferenca;
      }

      return valorQuitado;
    },
    []
  );

  const handleListInput = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number,
      quitado: number,
      diferenca: number,
      deduzidoFace: number,
      quitacaoParcial: boolean,
      formikTitle: ITitle
    ) => {
      const { value, name, classList } = e.target;
      const valueSanitized = sanitizeInputValue(value);
      const isDiferenca = classList.contains('diferencaInput');
      const fieldName = `titulos[${index}]`;
      const valorQuitado = !isDiferenca
        ? valueSanitized
        : handleValorQuitadoInput(
            quitacaoParcial,
            deduzidoFace,
            quitado,
            isDiferenca ? valueSanitized : diferenca
          );
      const titlesCurrentTotal =
        titlesTotal - Number(formikTitle?.valorQuitado) + valorQuitado;

      // !quitacaoParcial
      // valorQuitado = deduzidoFace + diferença
      // não pode ser maior que o valor total de vis

      // quitacaoParcial
      // deduzidoFace = valorQuitado - diferença

      // valor total dos títulos não pode ser maior que o valor total de vis
      if (titlesCurrentTotal > visTotal) {
        return;
      }

      // Valor Quitado não pode ser maior que o valor total de vis
      if (
        (isDiferenca && valorQuitado > visTotal) ||
        (!isDiferenca && valueSanitized > visTotal)
      ) {
        return;
      }

      // Se quitação parcial, valor quitado - diferença não podem ser menores que 0
      if (
        quitacaoParcial &&
        ((!isDiferenca && valueSanitized - diferenca < 0) ||
          (isDiferenca && valorQuitado - valueSanitized < 0))
      ) {
        return;
      }

      let newDeduzido = 0;

      if (quitacaoParcial && isDiferenca) {
        newDeduzido = valorQuitado - valueSanitized;
      }

      if (quitacaoParcial && !isDiferenca) {
        newDeduzido = valueSanitized - diferenca;
      }

      if (quitacaoParcial) {
        setFieldValue(`${fieldName}.deduzidoFace`, newDeduzido);
      }

      if (isDiferenca) {
        setFieldValue(`${fieldName}.valorQuitado`, valorQuitado);
      }

      setFieldValue(name, valueSanitized);
    },
    [
      handleValorQuitadoInput,
      sanitizeInputValue,
      setFieldValue,
      titlesTotal,
      visTotal,
    ]
  );

  const handleCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formikTitleIndex: number) => {
      const { checked } = e.target;
      const formikTitle = formikTitles[formikTitleIndex];
      const { valorResidual } = formikTitle;
      const fieldName = `titulos[${formikTitleIndex}]`;

      const fields = {
        ...formikTitle,
        quitacaoParcial: checked,
        diferencaInput: 0,
        valorQuitado: valorResidual,
        deduzidoFace: valorResidual,
      };

      setFieldValue(`${fieldName}`, fields);
    },

    [formikTitles, setFieldValue]
  );

  function isSelectableDisable(row: ITitle) {
    return (
      isTitlesTotalMoreOrEqualThanVisTotal() &&
      !findFormikTitle(row) &&
      row.cnpjSacado !== liquidation.pagadorCpfCnpjLiquidacao
    );
  }

  function checkDiferencaError(diferenca: number, title: ITitle) {
    return diferenca < title?.diferenca;
  }

  function checkValorQuitadoError(valorQuitado: number, formikTitle: ITitle) {
    return formikTitle && valorQuitado > visTotal;
  }

  function checkQuitacaoParcialDisabled(title: ITitle, formikTitle: ITitle) {
    if (formikTitle)
      return (
        Number(title?.valor) - Number(formikTitle?.valorQuitado) >
        visTitleDifferenceValue()
      );

    return inputDisabled(title) || title?.valor > visTitleDifferenceValue();
  }

  return (
    <Table
      selectableRows
      onSelectedRowsChange={handleSelected}
      setTablePage={setTablePage}
      rowsAmount={rowsAmount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      selectableRowDisabled={isSelectableDisable}
      isLoading={titles === undefined}
      selectableRowSelected={(row: ITitle) =>
        !!recompraSimuladaId ||
        formikTitles.find((item) => item.tituloId === String(row.tituloId))
          ?.tituloId === String(row.tituloId) ||
        formikTitles.find((item) => item.id === row.id)?.id === row.id
      }
      conditionalRowStyles={[
        {
          when: (row: ITitle) =>
            parseDate(row.vencimentoUtil) < parseDate(referenceDate),
          style: {
            color: themeContext.colors.red,
          },
        },
      ]}
      columns={[
        {
          name: 'Tipo',
          selector: 'tipo',
          width: '50px',
          cell: (row) => <SpanTable id={row.id} text={row.tipo} />,
        },
        {
          name: 'N° Título',
          selector: 'numeroTitulo',
          cell: (row) => <SpanTable id={row.id} text={row.numeroTitulo} />,
        },
        {
          name: 'Vencimento',
          selector: 'vencimento',
          cell: (row) => (
            <SpanTable id={row.id} text={masks.date.string(row.vencimento)} />
          ),
        },
        {
          name: 'Valor de Face',
          selector: 'valorFace',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorFace)}
            />
          ),
        },
        {
          name: 'Deduções',
          selector: 'deducoes',
          right: true,
          cell: (row) => (
            <SpanTable id={row.id} text={masks.currency.string(row.deducoes)} />
          ),
        },
        {
          name: 'Valor Residual',
          selector: 'valorResidual',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorResidual)}
            />
          ),
        },

        {
          name: 'Diferença',
          selector: 'diferenca',
          right: true,
          cell: (row) => {
            const splitedMoraDescricao = row.moraDescricao?.split(' Multa');
            const mora = `${splitedMoraDescricao[0]}\nMulta${splitedMoraDescricao[1]}`;

            return (
              <>
                <RiCalculatorLine
                  style={{ marginRight: '4px', fontSize: '1.2rem' }}
                  data-tip={mora}
                  data-for={generateTooltipId(row.id, mora)}
                />
                <Tooltip id={generateTooltipId(row.id, mora)} />
                <SpanTable
                  id={row.id}
                  text={masks.currency.string(row.diferenca)}
                />
              </>
            );
          },
        },
        {
          name: 'Valor Final',
          selector: 'valorFinal',
          right: true,
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={masks.currency.string(row.valorFinal)}
            />
          ),
        },
        {
          name: 'Cedente',
          selector: 'empresaCedente',
          cell: (row) => <SpanTable id={row.id} text={row.empresaCedente} />,
        },
        {
          name: 'Tipo Operação',
          selector: 'tipoOperacao',
          cell: (row) => (
            <SpanTable
              id={row.id}
              text={TitleOperationTypeEnum[row.tipoOperacao] ?? '-'}
            />
          ),
        },
        {
          name: 'Empresa',
          selector: 'empresa',
          cell: (row) => <SpanTable id={row.id} text={row.empresa} />,
        },
        {
          name: 'Sacado',
          selector: 'sacado',
          cell: (row) => <SpanTable id={row.id} text={row.sacado} />,
        },
        {
          name: 'Status',
          selector: 'status',
          cell: (row) => (
            <SpanTable id={row.id} text={TitleStatusEnum[row.status]} />
          ),
        },
        {
          name: 'ID Título',
          selector: 'tituloId',
          cell: (row) => <SpanTable id={row.id} text={row.tituloId} />,
        },
        {
          name: 'Diferença',
          selector: 'diferencaField',
          width: '150px',
          center: true,
        },
        {
          name: 'Valor Quitado',
          selector: 'valorQuitadoField',
          width: '150px',
          center: true,
        },
        {
          name: 'Valor Deduzido',
          selector: 'valorDeduzidoField',
          width: '150px',
          center: true,
        },
        {
          name: 'Parcial',
          selector: 'quitacaoParticialField',
          width: '75px',
          center: true,
        },
      ]}
      content={titles?.map((title, index: number) => {
        const formikTitle = findFormikTitle(title);
        const formikTitleIndex = findFormikTitleIndex(formikTitle);
        const fieldname = `titulos[${formikTitleIndex}]`;
        const quitacaoParcial = checkQuitacaoParcialList(
          Number(title.valorResidual),
          formikTitle
        );
        let diferenca = recompraSimuladaId
          ? title.diferencaInput
          : formikTitle?.diferencaInput ?? +title?.diferenca;
        const valorQuitado =
          formikTitle?.valorQuitado ||
          handleValorQuitadoList(
            title,
            diferenca,
            formikTitle,
            quitacaoParcial
          );
        diferenca = handleDiferencaList(
          title,
          Number(valorQuitado),
          formikTitle,
          quitacaoParcial
        );
        const deduzidoFace = calcDeduzidoFace(
          quitacaoParcial,
          Number(valorQuitado),
          diferenca,
          title,
          formikTitle
        );

        return {
          id: title.id,
          tipo: title.tipo ?? '-',
          numeroTitulo: title.numero,
          vencimento: title.vencimento,
          valorFace: title.valor,
          deducoes: title.deducoes,
          valorResidual: title.valorResidual,
          diferenca: title.diferenca,
          valorFinal: title.valorFinal,
          empresaCedente: title.apelidoCedente,
          tipoOperacao: title.tipoOperacao,
          empresa: company?.razaoSocial,
          sacado: liquidation?.pagadorRazaoSocialLiquidacao,
          status: title.status,
          tituloId: title.id || '-',
          cedente: title.apelidoCedente,
          cnpjSacado: title.cnpjSacado,
          vencimentoUtil: title.vencimentoUtil,
          moraDescricao: title.moraDescricao,
          conciliacaoId,
          quitacaoParcial,
          diferencaInput: diferenca,
          valorQuitado,
          deduzidoFace,
          diferencaField: (
            <Input
              type="text"
              className="diferencaInput"
              id={`diferencaInput${index}`}
              name={`${fieldname}.diferencaInput`}
              value={masks.currency.string(diferenca)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleListInput(
                  e,
                  formikTitleIndex,
                  Number(valorQuitado),
                  diferenca,
                  Number(deduzidoFace),
                  quitacaoParcial,
                  formikTitle
                )
              }
              disabled={inputDisabled(title)}
              hasError={checkDiferencaError(diferenca, title)}
              errorTitle={
                checkDiferencaError(diferenca, title) &&
                'Campo diferença é menor que a diferença do título'
              }
              currency
            />
          ),
          valorQuitadoField: (
            <Input
              type="text"
              className="valorQuitado"
              id={`valorQuitado${index}`}
              name={`${fieldname}.valorQuitado`}
              value={masks.currency.string(valorQuitado ?? 0)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleListInput(
                  e,
                  formikTitleIndex,
                  Number(valorQuitado),
                  diferenca,
                  Number(deduzidoFace),
                  quitacaoParcial,
                  formikTitle
                )
              }
              hasError={checkValorQuitadoError(
                Number(valorQuitado),
                formikTitle
              )}
              errorTitle={
                checkValorQuitadoError(Number(valorQuitado), formikTitle) &&
                'Valor Quitado é maior que o Valor total de VIS'
              }
              disabled={
                !quitacaoParcial || !!recompraSimuladaId || !formikTitle
              }
              currency
            />
          ),
          valorDeduzidoField: (
            <Input
              type="text"
              value={masks.currency.string(deduzidoFace)}
              onChange={() => {}}
              disabled
              currency
            />
          ),
          quitacaoParticialField: (
            <input
              type="checkbox"
              className="quitacaoParcial"
              id={`quitacaoParcial${index}`}
              name={`${fieldname}.quitacaoParcial`}
              checked={quitacaoParcial}
              onChange={(e) => handleCheckbox(e, formikTitleIndex)}
              disabled={checkQuitacaoParcialDisabled(title, formikTitle)}
            />
          ),
        };
      })}
    />
  );
};

export default TitlesList;
