import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { handleRequestError } from '@helpers/handleRequestError';
import { IVI } from '@models/domain/IVI';
import { IListResponse } from '@models/util/IListResponse';
import { viService } from '@services/index';

interface Hook {
  fetchVis: (
    page: number,
    rowsPerPage: number,
    filters,
    filter,
    cancelToken?
  ) => Promise<IListResponse<IVI[]>>;
  deleteVi: (vi: IVI) => Promise<void>;
  registerVi: (vi: IVI) => Promise<void>;
  editVi: (vi: IVI) => Promise<void>;
}

export function useVIs(): Hook {
  const fetchVis = useCallback(
    async (
      page: number,
      rowsPerPage: number,
      filters,
      filter,
      cancelToken?
    ) => {
      try {
        const { rows, count } = await viService.list(
          page,
          rowsPerPage,
          filters,
          filter,
          cancelToken
        );

        return {
          rows,
          count,
        };
      } catch (error) {
        handleRequestError(error, 'Erro ao carregar VIs.');
      }

      return null;
    },
    []
  );

  async function deleteVi(vi: IVI) {
    try {
      await viService.delete(vi.id);

      toast.info('VI deletado com sucesso');
    } catch (error) {
      handleRequestError(error, 'Erro ao deletar VIs.');
    }
  }

  async function registerVi(vi: IVI) {
    try {
      await viService.register(vi);

      toast.success(`VI registrado com sucesso.`);
    } catch (error) {
      handleRequestError(error, 'Erro ao salvar VI.');
    }
  }

  async function editVi(vi: IVI) {
    try {
      await viService.update(vi);

      toast.success(`VI editado com sucesso.`);
    } catch (error) {
      handleRequestError(error, 'Erro ao editar VI.');
    }
  }

  return {
    fetchVis,
    deleteVi,
    registerVi,
    editVi,
  };
}
