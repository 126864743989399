import styled from 'styled-components';

export const Container = styled.div`
  & > div {
    padding: 15px;

    h2 {
      font-weight: bold;
    }
  }
`;

export const List = styled.ul`
  padding: var(--gap-big);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 0.9rem;
  gap: 25px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
