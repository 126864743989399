import React, { useContext } from 'react';
import { ClipLoader } from 'react-spinners';

import { ThemeContext } from 'styled-components';

import { Spinner } from './styles';

const SpinnerLoading: React.FC = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <Spinner>
      <ClipLoader size={50} color={themeContext.colors.primary} />
    </Spinner>
  );
};

export default SpinnerLoading;
